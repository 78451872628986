/*
 * Main page messages
 *
 * This contains all the text for the Main components.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  appointments: {
    id: 'app.containers.Main.appointments',
    defaultMessage: 'Appointments',
  },
  chat: {
    id: 'app.containers.Main.chat',
    defaultMessage: 'Chat',
  },
  files: {
    id: 'app.containers.Main.files',
    defaultMessage: 'Files',
  },
});
