import { DeepPartial } from 'redux';
import { createAsyncAction } from 'typesafe-actions';

import { Resource } from '../resources/createResource';
import { EntityBase } from '../resources/types';
import api, { Error, RequestConfig, Response } from './api';

export interface SavePayload<E extends EntityBase> extends RequestConfig {
  data?: DeepPartial<E>;
}

interface Payload extends SavePayload<EntityBase> {
  resource: Resource;
}

const save = createAsyncAction('@lib/save/REQUEST', '@lib/save/SUCCESS', '@lib/save/FAILURE')<
  Payload,
  Response,
  Error
>();

// saga
export function* sagaSave(action: ReturnType<typeof save.request>) {
  const {
    payload: { resource, data = {}, meta, ...config },
  } = action;
  const { id } = data;

  return yield api({
    action: save,
    resource,
    config: {
      method: id ? 'put' : 'post',
      url: id ? `${resource.url}/${id}` : resource.url,
      data: resource.saveRequestInterceptor(data),
      schema: resource.schema,
      meta: {
        messageSuccess: resource.saveMessageSuccess,
        messageError: resource.saveMessageError,
        ...meta,
      },
      ...config,
    },
  });
}

export default save;
