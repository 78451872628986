import { map, mapValues, reduce, toPairs } from 'lodash';
import { Schema } from 'normalizr';
import { createSelector, Selector } from 'reselect';

import { EntitiesObject } from './createResourceSelectors';

export interface Lookups {
  [keys: string]: {
    selectAll: Selector<any, EntitiesObject<any>>;
    schema: Schema;
  };
}

export interface CreateResourceLookupsArgs {
  lookups?: Lookups;
}

const createResourceLookups = (args: CreateResourceLookupsArgs) => {
  const { lookups = {} } = args;

  /**
   * NOTE: Using `_.pairs` because with `_.keys` and `_.values` the iteration order is not guaranteed,
   * Otherwise we could end up with something like:
   *
   * const lookupsSelector = createSelector(
   * [selectEntities1, selectEntities2],
   *  (entities1, entities2) => ({
   *    key1: entities2,
   *    key2: entities1,
   *  }),
   * );
   */
  const pairs = toPairs(lookups);
  const fields = map(pairs, (pair) => pair[0]);

  const schemaDefinition = mapValues(lookups, (resource) => resource.schema);
  const selectLookups = createSelector(
    map(pairs, (pair) => pair[1].selectAll),
    (...lookupsEntities) =>
      reduce(
        lookupsEntities,
        (result: any, lookupEntities, index) => {
          result[fields[index]] = lookupEntities;
          return result;
        },
        {},
      ),
  );
  return { schemaDefinition, selectLookups };
};

export default createResourceLookups;
