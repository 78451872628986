import { concat, defaults, get, isEmpty, isNumber } from 'lodash';
import { RootAction } from 'types';

import { EntityID } from '../resources/types';

const initialState: any = {};

export type ResultArray = EntityID[];

export interface ResultPaginated {
  loading: boolean;
  hasMore: boolean;
  page: number;
  size: number;
  totalSize: number;
  response: EntityID[];
  responseAgregated: EntityID[];
}

export type Result = ResultArray | ResultPaginated;

export type ResultStore = { [key: string]: Result };

export default function createResultReducer() {
  return (state = initialState, action: RootAction) => {
    const resultKey = get(action, 'payload.meta.resultKey');
    if (resultKey) {
      const result = get(action, 'payload.normalized.result') || {};
      const config = get(action, 'payload.config');
      const loading = isEmpty(result);
      let data = { ...result, config, loading };

      // handle result paginated
      if (!loading && isNumber(result.page)) {
        const prevPage = get(state, `${resultKey}.page`, 1);
        const prevResponseAgregated = [...get(state, `${resultKey}.responseAgregated`, [])];

        // handle response agregated for infinite scroll
        let responseAgregated;
        if (result.page === prevPage + 1) {
          responseAgregated = concat(prevResponseAgregated, data.response);
        } else if (result.page > 1 && result.page === prevPage) {
          responseAgregated = prevResponseAgregated;
        } else {
          responseAgregated = data.response;
        }
        data = {
          ...data,
          responseAgregated,
          hasMore: !loading && result.totalSize > responseAgregated.length,
        };
      }
      return {
        ...state,
        [resultKey]: defaults(data, state[resultKey]),
      };
    }
    return state;
  };
}
