import { createSelector } from 'reselect';

import selectEntities from './selectEntities';

const selectSettings = createSelector(
  selectEntities,
  (entities) => entities.settings,
);

export default selectSettings;
