import classNames from 'classnames';
import { get } from 'lodash';
import React from 'react';

import MuiButton, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import { LumaTheme } from '../styles/getTheme';

const styles = (theme: LumaTheme) =>
  createStyles({
    withIcon: {
      '& svg': {
        marginRight: theme.spacing.unit * 1,
      },
    },
    sizeExtraSmall: {
      fontSize: theme.typography.pxToRem(11),
    },
    sizeExtraLarge: {
      padding: `${theme.spacing.unit * 3.5}px 24px`,
      fontSize: theme.typography.pxToRem(18),
      borderRadius: 15,
      '& span.material-icons': {
        position: 'absolute',
        left: theme.spacing.unit * 3,
        fontSize: theme.spacing.unit * 5,
      },
    },
    /* Styles applied to the root element if `variant="text"` and `color="tertiary"`. */
    textTertiary: {
      color: get(theme.palette, 'tertiary.main'),
      '&:hover': {
        backgroundColor: fade(get(theme.palette, 'tertiary.main'), theme.palette.action.hoverOpacity),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    /* Styles applied to the root element if `variant="[contained]"` and `color="tertiary"`. */
    containedTertiary: {
      color: get(theme.palette, 'tertiary.contrastText'),
      backgroundColor: get(theme.palette, 'tertiary.main'),
      '&:hover': {
        backgroundColor: get(theme.palette, 'tertiary.dark'),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: get(theme.palette, 'tertiary.main'),
        },
      },
    },
    /* Styles applied to the root element if `variant="outlined"` and `color="tertiary"`. */
    outlinedTertiary: {
      color: get(theme.palette, 'tertiary.main'),
      border: `1px solid ${fade(get(theme.palette, 'tertiary.main'), 0.5)}`,
      '&:hover': {
        border: `1px solid ${get(theme.palette, 'tertiary.main')}`,
        backgroundColor: fade(get(theme.palette, 'tertiary.main'), theme.palette.action.hoverOpacity),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    /* Styles applied to the root element if `variant="text"` and `color="error"`. */
    textError: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, theme.palette.action.hoverOpacity),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    /* Styles applied to the root element if `variant="outlined"` and `color="error"`. */
    outlinedError: {
      color: theme.palette.error.main,
      border: `1px solid ${fade(theme.palette.error.main, 0.5)}`,
      '&:hover': {
        border: `1px solid ${theme.palette.error.main}`,
        backgroundColor: fade(theme.palette.error.main, theme.palette.action.hoverOpacity),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    /* Styles applied to the root element if `variant="[contained]"` and `color="error"`. */
    containedError: {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: theme.palette.error.main,
        },
      },
    },
    /* Styles applied to the root element if `variant="text"` and `color="success"`. */
    textSuccess: {
      color: theme.custom.palette.colorSuccess,
      '&:hover': {
        backgroundColor: fade(theme.custom.palette.colorSuccess, theme.palette.action.hoverOpacity),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    /* Styles applied to the root element if `variant="outlined"` and `color="error"`. */
    outlinedSuccess: {
      color: theme.custom.palette.colorSuccess,
      border: `1px solid ${fade(theme.custom.palette.colorSuccess, 0.5)}`,
      '&:hover': {
        border: `1px solid ${theme.custom.palette.colorSuccess}`,
        backgroundColor: fade(theme.custom.palette.colorSuccess, theme.palette.action.hoverOpacity),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    /* Styles applied to the root element if `variant="[contained]"` and `color="success"`. */
    containedSuccess: {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.custom.palette.colorSuccess,
      '&:hover': {
        backgroundColor: theme.custom.palette.colorSuccessHover,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: theme.custom.palette.colorSuccess,
        },
      },
    },
  });

export interface ButtonProps extends Omit<MuiButtonProps, 'className' | 'size' | 'color'> {
  classes: { [c: string]: string };
  className?: string;
  icon?: any;
  size?: MuiButtonProps['size'] | 'xsmall' | 'xlarge';
  color?: MuiButtonProps['color'] | 'tertiary' | 'error' | 'success';
}

const Button: React.FC<ButtonProps> = ({
  classes,
  className,
  icon,
  size,
  color,
  children,
  variant = 'text',
  ...other
}) => {
  let sizeValue: MuiButtonProps['size'];
  if (size === 'xlarge') {
    sizeValue = 'large';
  } else if (size === 'xsmall') {
    sizeValue = 'small';
  } else {
    sizeValue = size;
  }
  let colorValue: MuiButtonProps['color'];
  if (color === 'error' || color === 'success') {
    colorValue = 'default';
  } else if (color === 'tertiary') {
    colorValue = 'primary';
  } else {
    colorValue = color;
  }

  const text = variant === 'text';
  const outlined = variant === 'outlined';
  const contained = variant === 'contained';
  const tertiary = color === 'tertiary';
  const error = color === 'error';
  const success = color === 'success';
  return (
    <MuiButton
      className={classNames(className, {
        [classes.withIcon]: icon,
        [classes.sizeExtraSmall]: size === 'xsmall',
        [classes.sizeExtraLarge]: size === 'xlarge',
        [classes.textTertiary]: text && tertiary,
        [classes.textError]: text && error,
        [classes.textSuccess]: text && success,
        [classes.outlinedTertiary]: outlined && tertiary,
        [classes.outlinedError]: outlined && error,
        [classes.outlinedSuccess]: outlined && success,
        [classes.containedTertiary]: contained && tertiary,
        [classes.containedError]: contained && error,
        [classes.containedSuccess]: contained && success,
      })}
      size={sizeValue}
      color={colorValue}
      variant={variant}
      {...other}
    >
      {icon}
      {children}
    </MuiButton>
  );
};

export default withStyles(styles)(Button);
