import { Location } from 'history';
import { createReducer } from 'typesafe-actions';

import { changeLocation } from '@lib/actions';

interface LocationStore {
  currentLocation?: Location;
  prevLocation?: Location;
}

const initialState = {} as LocationStore;

const createRouteReducer = () =>
  createReducer(initialState).handleAction(changeLocation, (state, action) => ({
    currentLocation: action.payload,
    prevLocation: state.currentLocation,
  }));

export default createRouteReducer;
