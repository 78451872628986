import { get, isObject, omitBy, reduce } from 'lodash';
import { Schema } from 'normalizr';

import { fetch, fetchBulk, fetchList, fetchPage, remove, request, save } from '../actions';
import { FetchPayload } from '../actions/fetch';
import { FetchBulkPayload } from '../actions/fetchBulk';
import { FetchListPayload } from '../actions/fetchList';
import { FetchPagePayload } from '../actions/fetchPage';
import { RemovePayload } from '../actions/remove';
import { RequestPayload } from '../actions/request';
import { SavePayload } from '../actions/save';
import createResourceLookups, { CreateResourceLookupsArgs } from './createResourceLookups';
import createResourceSchema, { CreateResourceSchemaArgs } from './createResourceSchema';
import createResourceSelectors, { CreateResourceSelectorsArgs } from './createResourceSelectors';
import { EntityBase } from './types';

export interface CreateResourceArgs<E extends EntityBase>
  extends Partial<CreateResourceLookupsArgs>,
    Partial<CreateResourceSchemaArgs<E>>,
    Partial<CreateResourceSelectorsArgs> {
  key: string;
  url: string;
  fetchPageUrl?: string;
  fetchListUrl?: string;
  createFetchSchema?: (schema: Schema) => Schema;
  createFetchListSchema?: (schema: Schema) => Schema;
  createFetchPageSchema?: (schema: Schema) => Schema;
  createRemoveSchema?: (schema: Schema) => Schema;
  saveMessageSuccess?: string;
  saveMessageError?: string;
  saveRequestInterceptor?: (data: E) => E;
  removeMessageSuccess?: string;
  removeMessageError?: string;
}

const createResourceWithoutActions = <E extends EntityBase>(args: CreateResourceArgs<E>) => {
  const {
    url,
    schemaDefinition,
    key,
    idAttribute,
    lookups,
    processStrategy,
    selectLookups,
    fetchPageUrl,
    fetchListUrl,
    createFetchSchema,
    createFetchListSchema,
    createFetchPageSchema,
    createRemoveSchema,
    saveMessageSuccess,
    saveMessageError,
    saveRequestInterceptor = (data: E) => data,
    removeMessageSuccess,
    removeMessageError,
  } = args;

  const fromLookups = createResourceLookups({ lookups });

  const schema = createResourceSchema<E>({
    key,
    idAttribute,
    processStrategy,
    schemaDefinition: schemaDefinition || fromLookups.schemaDefinition,
  });

  const selectors = createResourceSelectors<E>({
    key,
    selectLookups: selectLookups || fromLookups.selectLookups,
  });

  const postSaveRequestInterceptor = (data: E) => {
    // Omit extended properties
    const result = omitBy(saveRequestInterceptor(data), (_: any, k: string) => k.indexOf('$') === 0);

    // Remove lookups
    return reduce(
      result,
      (obj, value, key) => ({
        ...obj,
        [key]: lookups && lookups[key] && isObject(value) ? get(value, 'id') : value,
      }),
      {},
    );
  };

  return {
    key,
    url,
    idAttribute,
    schema,
    lookups,
    fetchListSchema: createFetchListSchema ? createFetchListSchema(schema) : { response: [schema] },
    fetchListUrl: fetchListUrl || url,
    fetchPageSchema: createFetchPageSchema ? createFetchPageSchema(schema) : { response: [schema] },
    fetchPageUrl: fetchPageUrl || `${url}/search`,
    fetchSchema: createFetchSchema ? createFetchSchema(schema) : schema,
    removeMessageError: removeMessageError || 'Error deleting item',
    removeMessageSuccess: removeMessageSuccess || 'Item deleted!',
    removeSchema: createRemoveSchema ? createRemoveSchema(schema) : schema,
    saveRequestInterceptor: (data: E) => postSaveRequestInterceptor(saveRequestInterceptor(data)),
    saveMessageError: saveMessageError || 'Error saving item',
    saveMessageSuccess: saveMessageSuccess || 'Item saved!',
    ...selectors,
  };
};

const createResource = <E extends EntityBase>(args: CreateResourceArgs<E>) => {
  const resource = createResourceWithoutActions<E>(args);

  return {
    ...resource,
    initialState: {},

    // Linking common actions for all resources
    fetch: (payload: FetchPayload) => fetch.request({ resource, ...payload }),
    fetchBulk: (payload: FetchBulkPayload) => fetchBulk.request({ resource, ...payload }),
    fetchList: (payload?: FetchListPayload) => fetchList.request({ resource, ...payload }),
    fetchPage: (payload: FetchPagePayload) => fetchPage.request({ resource, ...payload }),
    remove: (payload: RemovePayload) => remove.request({ resource, ...payload }),
    save: (payload: SavePayload<E>) => save.request({ resource, ...payload }),
    request: (payload: RequestPayload) => request.request({ resource, ...payload }),
  };
};

// Dummy for the ReturnType of a generic function
// https://stackoverflow.com/questions/51740472/typescript-returntype-of-a-generic-function-not-working
const dummy = (args: CreateResourceArgs<any>) => createResourceWithoutActions<any>(args);
interface ResourceWithoutActions extends ReturnType<typeof dummy> {}

export interface Resource extends ResourceWithoutActions {
  [otherFields: string]: any;
}

export default createResource;
