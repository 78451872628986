import { createSelector } from 'reselect';

import selectAuth from './selectAuth';

const selectWidgetUserId = createSelector(
  selectAuth,
  (auth) => auth.widgetUser,
);

export default selectWidgetUserId;
