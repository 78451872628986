import { each, get, isDate, isEmpty, isNumber, map, set } from 'lodash';
import * as Yup from 'yup';

const join = (rules: any) => (value: any, data: any) =>
  rules.map((rule: any) => rule(value, data)).filter((error: any) => !!error)[0];

export function email(value: any) {
  if (!isEmpty(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return 'Invalid email address';
  }
  return false;
}

export function required(value: any) {
  // WORKAROUND numbers and dates return true for isEmpty
  // https://github.com/lodash/lodash/issues/483
  if (isEmpty(value) && !isNumber(value) && !isDate(value)) {
    return 'Field required';
  }
  return false;
}

export function match(password: any) {
  return (value: any) => {
    if (value !== password) {
      return "Passwords don't match";
    }
    return false;
  };
}

export function createValidator(rules: any) {
  return (formData: any, props: any) => {
    const data = formData.toJS();
    const errors = {};

    // this code can be improved to be recursive and support more levels of nested collections
    Object.keys(rules).forEach((key) => {
      if (get(rules[key], 'type') === 'collection') {
        const fields = get(rules[key], 'fields');
        const nestedData = get(data, key);

        map(nestedData, (item, index) => {
          const nestedErrors: any = {};

          Object.keys(fields).forEach((fieldKey: string) => {
            const validators = get(fields[fieldKey], 'validators', fields[fieldKey]);
            const rule: any = join([].concat(validators));
            const error = rule(get(item, fieldKey), item, data, props);

            if (error) nestedErrors[fieldKey] = error;
          });

          if (Object.keys(nestedErrors).length > 0) set(errors, `${key}[${index}]`, nestedErrors);
        });
      } else {
        // option to pass a key dependency to enable/disable validator
        const keyEnabled = get(rules[key], 'keyEnabled');
        if (!keyEnabled || get(data, keyEnabled)) {
          // run validators
          const validators = get(rules[key], 'validators', rules[key]);
          const rule: any = join([].concat(validators));
          const error = rule(get(data, key), data, data, props);
          if (error) {
            set(errors, key, error);
          }
        }
      }
    });

    return errors;
  };
}

export function extractURLs(value: string) {
  const splitString = value.split(/(\s)/g);
  const urls = splitString.filter((v: any) => v.startsWith('http'));
  let string = value;
  each(urls, (url) => {
    string = string.replace(url, '');
  });
  return {
    string,
    urls,
  };
}

export function getValidationSchema(name: string, type: string, validations: any = {}, initialSchema?: any) {
  let schema = initialSchema || Yup.string();
  if (type === 'ssn') {
    schema = Yup.string().matches(
      /^$|^\d{3}-\d{2}-\d{4}$/,
      'Invalid social security number. Please use the format: XXX-XX-XXXX',
    );
  } else if (type === 'phone_number') {
    schema = Yup.string().matches(
      /^$|^\(\d{3}\)[ ]\d{3}-\d{4}$/,
      'Invalid phone number. Please use the format: (555) 555-5555',
    );
  } else if (type === 'date') {
    schema = Yup.string().matches(
      /^$|^(((0)[0-9])|((1)[0-2]))-([0-2][0-9]|(3)[0-1])-\d{4}$/,
      'Invalid date. Please use the format: mm-dd-yyyy',
    );
  } else if (type === 'email') {
    schema = Yup.string().email('Invalid email');
  }
  return {
    [name]: validations.required ? schema.required('Required') : schema,
  };
}
