import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { get, filter, find, map } from 'lodash';
import moment from 'moment';
import { DatePicker, TimePicker } from 'material-ui';
import { black, white } from 'material-ui/styles/colors';

import { getDateFromInterval } from '../../services/datesService';

const styles = (theme) => {
  const {
    palette: { primary },
    spacing: { unit },
  } = theme;

  return {
    listItem: {
      margin: 0,
      padding: `${unit / 2}px 0`,
    },
    button: {
      padding: `0 ${unit * 1.5}px`,
    },
    customDateWrapper: {
      display: 'inline-block',
      marginTop: unit,
      marginLeft: unit * 3 - 1,
      width: '100%',
      '& input': {
        color: `${primary.main} !important`,
      },
      '& hr': {
        display: 'none !important',
      },
    },
  };
};

const stylesInline = {
  customDate: {
    input: {
      backgroundColor: white,
      border: '1px solid #D0D4D6',
      color: black,
      fontWeight: 500,
      height: '100%',
      paddingLeft: 8,
      width: '100%',
    },
    wrapper: {
      backgroundColor: white,
      float: 'left',
      height: 36,
      overflow: 'hidden',
      width: 90,
    },
  },
  scheduleNote: {
    fontSize: 15,
    fontWeight: 500,
    marginTop: 16,
  },
};

class FilterDates extends React.Component {
  // eslint-disable-line react/no-deprecated
  constructor(props) {
    super(props);

    this.handleRadioSelect = this.handleRadioSelect.bind(this);

    const options = [...props.options];
    if (props.includeOptionAny) {
      options.unshift({
        id: '',
        label: 'Any Time',
        skipDateFrom: true,
        skipDateTo: true,
      });
    }
    const { initialValue } = props;
    const fromDate = initialValue ? moment(initialValue.fromDate) : moment();
    const toDate = initialValue ? moment(initialValue.toDate) : moment();
    // TODO unify selectedItem and selectedDate
    const selectedItem =
      get(initialValue, 'selectedItem') || get(initialValue, 'selectedDate') || get(options, '[0].id');

    this.state = {
      options,
      customDate: {
        fromDate: fromDate.startOf('day').toDate(),
        toDate: toDate.endOf('day').toDate(),
      },
      selectedItem,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { includeOptionAny, scheduleDate } = this.props;
    const { options, selectedItem } = this.state;

    if (includeOptionAny !== nextProps.includeOptionAny || scheduleDate !== nextProps.scheduleDate) {
      let newOptions = [...options];
      let newSelectedItem = selectedItem;

      if (nextProps.includeOptionAny) {
        if (!find(options, { id: '' })) {
          newOptions.unshift({
            id: '',
            label: 'Any Time',
            skipDateFrom: true,
            skipDateTo: true,
          });
        }
      } else {
        newOptions = filter(options, (item) => item.id !== '');

        if (selectedItem === '') newSelectedItem = 'specific_date';
      }

      const fromDate = nextProps.initialValue ? moment(nextProps.initialValue.fromDate) : moment();
      const toDate = nextProps.initialValue ? moment(nextProps.initialValue.toDate) : moment();

      const customDate = {
        fromDate: nextProps.scheduleDate ? fromDate.toDate() : fromDate.startOf('day').toDate(),
        toDate: toDate.endOf('day').toDate(),
      };

      this.setState({ options: newOptions, selectedItem: newSelectedItem, customDate });

      this.handleValueChange(customDate, find(options, { id: newSelectedItem }));
    }
  }

  handleValueChange(values, option) {
    const { handleChange } = this.props;
    handleChange(
      'date',
      {
        ...values,
        fromDate: option.skipDateFrom ? null : values.fromDate,
        toDate: option.skipDateTo ? null : values.toDate,
      },
      option,
    );
  }

  handleChangeCustomDate(value, field, option) {
    const { customDate, selectedItem } = this.state;

    customDate[field] = value;

    this.setState({ customDate });

    this.handleValueChange(
      {
        ...customDate,
        selectedItem,
      },
      option,
    );
  }

  handleRadioSelect(value) {
    const {
      selectedItem,
      options,
      customDate: { fromDate, toDate },
    } = this.state;

    if (selectedItem !== value) {
      this.setState({ selectedItem: value });
      const date = {
        selectedItem: value,
      };

      const option = find(options, { id: value });
      if (option.showDateFrom) {
        date.fromDate = fromDate;
        date.toDate = toDate;
      } else {
        const day = getDateFromInterval(value);
        if (value === '2' || value === '-2') {
          // yesterday or tomorrow
          date.fromDate = day;
          date.toDate = day;
        } else {
          // past date until today
          date.fromDate = day;
          date.toDate = new Date();
        }
      }

      this.handleValueChange(date, option);
    }
  }

  renderCustomDateFields() {
    const { classes, scheduleDate } = this.props;
    const {
      customDate: { fromDate, toDate },
      options,
      selectedItem,
    } = this.state;
    const option = find(options, { id: selectedItem });

    if (!option || !option.showDateFrom) return;

    const formatDate = (date) => moment(date).format('MM/DD/YYYY');
    const minDate = (scheduleDate && scheduleDate.toDate()) || moment('1920-01-01').toDate();
    const maxDate = option.disableFutureDates ? moment().toDate() : moment('2100-01-01').toDate();

    return (
      <div className={classes.customDateWrapper}>
        <DatePicker
          autoOk
          firstDayOfWeek={0}
          formatDate={formatDate}
          minDate={minDate}
          maxDate={maxDate}
          name="fromDateFilter"
          onChange={(event, value) => this.handleChangeCustomDate(moment(value).toDate(), 'fromDate', option)}
          style={stylesInline.customDate.wrapper}
          textFieldStyle={stylesInline.customDate.input}
          value={fromDate}
        />
        {option.showDateTo && (
          <TimePicker
            autoOk
            name="fromTimeFilter"
            onChange={(event, value) => this.handleChangeCustomDate(value, 'fromDate', option)}
            style={stylesInline.customDate.wrapper}
            textFieldStyle={stylesInline.customDate.input}
            value={fromDate}
          />
        )}
        {option.showDateTo && (
          <div style={{ marginTop: 10, display: 'inherit' }}>
            <DatePicker
              autoOk
              firstDayOfWeek={0}
              formatDate={formatDate}
              minDate={minDate}
              maxDate={maxDate}
              name="toDateFilter"
              onChange={(event, value) => this.handleChangeCustomDate(moment(value).toDate(), 'toDate', option)}
              style={stylesInline.customDate.wrapper}
              textFieldStyle={stylesInline.customDate.input}
              value={toDate}
            />
            <TimePicker
              autoOk
              name="toTimeFilter"
              onChange={(event, value) => this.handleChangeCustomDate(value, 'toDate', option)}
              style={stylesInline.customDate.wrapper}
              textFieldStyle={stylesInline.customDate.input}
              value={toDate}
            />
          </div>
        )}
      </div>
    );
  }

  renderOptions() {
    const { classes } = this.props;
    const { selectedItem, options } = this.state;
    const defaultSelected = options[0].id;
    return (
      <RadioGroup
        defaultValue={defaultSelected}
        value={selectedItem}
        onChange={(event, v) => this.handleRadioSelect(v)}
      >
        {map(options, ({ id, label }) => (
          <FormControlLabel
            classes={{
              root: classes.listItem,
            }}
            key={id}
            value={id}
            control={
              <Radio
                className={classes.button}
                color="primary"
                icon={<RadioButtonUncheckedIcon fontSize="small" />}
                checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
              />
            }
            label={label}
          />
        ))}
      </RadioGroup>
    );
  }

  renderScheduleNote() {
    const { scheduleDate } = this.props;

    if (!scheduleDate) return;

    return (
      <div style={stylesInline.scheduleNote}>
        Note: You cannot select appointment dates that occur before your scheduled date
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderOptions()}
        {this.renderCustomDateFields()}
        {this.renderScheduleNote()}
      </div>
    );
  }
}

FilterDates.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  initialValue: PropTypes.object,
  includeOptionAny: PropTypes.bool,
  scheduleDate: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

export default withStyles(styles)(FilterDates);
