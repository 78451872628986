import { schema } from 'normalizr';

import api from '../utils/axiosClient';

const url = '/contexts';
const entitiesKey = 'contexts';
const idAttribute = 'refId';

// Schemas
export const contextSchema = new schema.Entity(entitiesKey, {}, { idAttribute });

// Methods
async function fetchContext({ refId }) {
  return api.get(`${url}/${refId}`, {
    schema: contextSchema,
  });
}

export default {
  entitiesKey,
  fetchContext,
};
