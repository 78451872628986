import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Field } from 'redux-form/immutable';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { get, find, map, includes, isString, isArray, isObject, isFunction } from 'lodash';
import classnames from 'classnames';
import { addQuery } from '../../services/queryService';

import FilterExpansionPanel from './FilterExpansionPanel';
import FieldComponentFilter from './FieldComponentFilter';
import FilterSingleChoice from './FilterSingleChoice';
import FilterAppointmentStatus from './FilterAppointmentStatus';
import FilterListAppointmentTypes from './FilterListAppointmentTypes';
import FilterListProviders from './FilterListProviders';
import FilterListFacilities from './FilterListFacilities';
import FilterDates from './FilterDates';
import FilterTextBox from './FilterTextBox';
import FilterListUsers from './FilterListUsers';

export const FILTER = {
  SINGLE_CHOICE: {
    key: 'singleChoiceFilter',
    label: 'Single Choice',
    FilterComponent: FilterSingleChoice,
  },
  REFERRAL_STATUS: {
    key: 'referralStatusFilter',
    label: 'Status',
    FilterComponent: FilterSingleChoice,
    items: [
      {
        _id: 'all',
        name: 'All',
      },
      {
        _id: 'active:=<',
        name: 'Active',
      },
      {
        _id: 'called,called-late',
        name: 'Called',
      },
      {
        _id: 'active:=>',
        name: 'Pending',
      },
      {
        _id: 'pending',
        name: 'Needs Approval',
      },
      {
        _id: 'scheduled,scheduled-late',
        name: 'Scheduled',
      },
      {
        _id: 'cancelled',
        name: 'Cancelled',
      },
      {
        _id: 'incomplete',
        name: 'Incomplete',
      },
      {
        _id: 'closed',
        name: 'Closed',
      },
    ],
  },
  APPOINTMENT_STATUS: {
    key: 'statusFilter',
    label: 'Status',
    FilterComponent: FilterAppointmentStatus,
  },
  APPOINTMENT_TYPE: {
    key: 'appointmentTypeFilter',
    label: 'Appointment Type',
    FilterComponent: FilterListAppointmentTypes,
  },
  PROVIDER: {
    key: 'providerFilter',
    label: 'Provider',
    FilterComponent: FilterListProviders,
  },
  FACILITY: {
    key: 'facilityFilter',
    label: 'Facility',
    FilterComponent: FilterListFacilities,
  },
  DATE: {
    key: 'dateFilter',
    label: 'Date',
    FilterComponent: FilterDates,
  },
  OPEN_TEXT: {
    key: 'openText',
    FilterComponent: FilterTextBox,
    defaultExpanded: true,
  },
  ASSIGNEES: {
    key: 'assignee',
    label: 'Assignees',
    FilterComponent: FilterListUsers,
  },
};

const styles = ({
  palette: {
    common: { white },
  },
}) => ({
  root: {
    background: white,
    display: 'inline-block',
    height: 620,
    overflow: 'auto',
    width: '100%',
    '& > div:last-child > div:last-child > div:last-child': {
      borderBottom: 'none !important',
    },
  },
});

export class Filters extends React.PureComponent {
  hasFilter(key) {
    const { filters } = this.props;
    return includes(filters, key) || find(filters, { key });
  }

  getInitialValue(field, isDate) {
    const {
      defaultValues,
      location: { query },
    } = this.props;
    const result = get(query, field) || get(defaultValues, field);
    return isDate && result ? new Date(parseInt(result, 10)) : result;
  }

  handleChange = (field, data) => {
    const { syncQueryParams, onChange } = this.props;
    let value = isFunction(data.toJS) ? data.toJS() : data;
    if (onChange) {
      onChange(field, value);
    }
    if (isArray(value)) {
      value = value.join(',');
    }
    if (syncQueryParams) {
      addQuery(
        isObject(value)
          ? value
          : {
              [field]: value,
            },
      );
    }
  };

  handleDateChange = (field, values, option) => {
    let fromDate = values.fromDate
      ? moment(values.fromDate)
          .startOf('day')
          .valueOf()
      : null;
    let toDate = values.toDate
      ? moment(values.toDate)
          .endOf('day')
          .valueOf()
      : null;

    if (option.specificDate) {
      toDate = fromDate
        ? moment(fromDate)
            .endOf('day')
            .valueOf()
        : null;
    }

    // custom range
    if (option.id === '0') {
      fromDate = values.fromDate ? moment(values.fromDate).valueOf() : null;
      toDate = values.toDate ? moment(values.toDate).valueOf() : null;
    }

    const value = {
      selectedDate: option.id,
      fromDate,
      toDate,
    };

    this.handleChange(field, value);
  };

  render() {
    const { classes, className, filters } = this.props;
    return (
      <div className={classnames(classes.root, className || '')}>
        {map(filters, ({ key, label, defaultExpanded, ...other }) => (
          <FilterExpansionPanel key={key} title={label} defaultExpanded={defaultExpanded}>
            {this.renderFilter(key, other)}
          </FilterExpansionPanel>
        ))}
      </div>
    );
  }

  renderFilter = (key, filter) => {
    const {
      defaultValues,
      syncQueryParams,
      useFormFields,
      location: { query },
    } = this.props;
    const { fieldName, ...filterProps } = filter;
    const { FilterComponent, includeOptionAny, options, scheduleDate } = filterProps;
    const name = fieldName || key;
    const value = syncQueryParams ? query[name] : defaultValues[key];

    if (key === FILTER.SINGLE_CHOICE.key || key === FILTER.REFERRAL_STATUS.key) {
      return <FilterComponent value={value} onFilterChange={(v) => this.handleChange(name, v)} {...filterProps} />;
    }

    if (key === FILTER.DATE.key) {
      return (
        <FilterComponent
          options={options}
          handleChange={(_, v, option) => this.handleDateChange(name, v, option)}
          initialValue={{
            selectedDate: scheduleDate || this.getInitialValue('selectedDate'),
            fromDate: scheduleDate || this.getInitialValue('fromDate', true),
            toDate: scheduleDate || this.getInitialValue('toDate', true),
          }}
          includeOptionAny={includeOptionAny}
          scheduleDate={scheduleDate}
        />
      );
    }
    if (key === FILTER.OPEN_TEXT.key) {
      return <Field component={FilterComponent} name={name} handleChange={(v) => this.handleChange(name, v)} />;
    }
    if (useFormFields) {
      return <Field component={FieldComponentFilter} name={name} {...filterProps} />;
    }
    return (
      <FieldComponentFilter
        value={isString(value) ? value.split(',') : value}
        onFilterChange={(v) => this.handleChange(name, v)}
        {...filterProps}
      />
    );
  };
}

Filters.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  location: PropTypes.object.isRequired,
  filters: PropTypes.array.isRequired,
  defaultValues: PropTypes.object,
  onChange: PropTypes.func,
  syncQueryParams: PropTypes.bool,
  useFormFields: PropTypes.bool,
};

Filters.defaultProps = {
  defaultValues: {},
  onChange: undefined,
  syncQueryParams: false,
  useFormFields: false,
};

export default compose(
  withStyles(styles),
  withRouter,
)(Filters);
