import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { browserHistory } from 'react-router';

import MainTabs from 'components/MainTabs';

const RootWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const TABS = ['chat', 'appointments', 'files'];

class Main extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onTabChange = this.onTabChange.bind(this);
  }

  onTabChange(tabKey) {
    browserHistory.push(`/main/${tabKey}`);
  }

  render() {
    return (
      <RootWrapper>
        {this.renderTabs()}
        <ContentWrapper>{this.props.children}</ContentWrapper>
      </RootWrapper>
    );
  }

  renderTabs() {
    const {
      params: { page },
    } = this.props;
    return <MainTabs tabs={TABS} tab={page} onTabChange={this.onTabChange} />;
  }
}

Main.propTypes = {
  children: PropTypes.node,
  params: PropTypes.object.isRequired,
};

export default Main;
