import { parseDate } from '../services/datesService';
import appointmentTypesResource from './appointmentTypesResource';
import createResource from './createResource';
import facilitiesResource from './facilitiesResource';
import patientsResource from './patientsResource';
import providersResource from './providersResource';
import { Appointment } from './types';

const appointmentsResource = createResource<Appointment>({
  key: 'appointments',
  url: '/appointments',
  fetchPageUrl: '/appointments/crudList',
  fetchListUrl: '/appointments/crudList',
  lookups: {
    facility: facilitiesResource,
    patient: patientsResource,
    provider: providersResource,
    type: appointmentTypesResource,
  },
  processStrategy: (data) => ({
    ...data,
    $date: parseDate(data.date),
  }),
});

export default appointmentsResource;
