import React from 'react';

import { Theme, withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => ({
  toolbar: theme.mixins.toolbar,
});

interface HeaderGapProps {
  classes: { toolbar: string };
}

const HeaderGap: React.FC<HeaderGapProps> = ({ classes }) => <div className={classes.toolbar} />;

export default withStyles(styles)(HeaderGap);
