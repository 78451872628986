import configService from './configService';

const apiBasePath = configService.getApiBasePath();

type EventType = 'apiError' | 'formQuestionSubmitted';

type EventData = {
  event: EventType;
  [field: string]: any;
};

export function trackEvent(data: EventData) {
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push(data);
}

export function trackApiError(response: any) {
  const { status: apiStatus, statusText: apiStatusText, config } = response;
  const { method = 'get', url = '???' } = config || response;
  const endpoint = url.replace(apiBasePath, '');
  const apiEndpoint = `${method.toUpperCase()} ${endpoint}`;
  trackEvent({
    event: 'apiError',
    apiStatus,
    apiStatusText,
    apiEndpoint,
  });
}

export function trackFormQuestionSubmitted(formId: string, formQuestionId: string, formQuestionIndex: number) {
  trackEvent({
    event: 'formQuestionSubmitted',
    formId,
    formQuestionId,
    formQuestionIndex,
  });
}
