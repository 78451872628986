import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get, map, concat, isEmpty, isEqual, without, difference, compact, includes } from 'lodash';

const limit = 8;

class FieldComponentFilter extends PureComponent {
  state = {
    idsExcluded: [],
    allSelected: false,
  };

  componentDidMount() {
    const { input, value } = this.props;
    let newValue = get(input, 'value', value);
    if (newValue && newValue.toJS) {
      newValue = newValue.toJS();
    }
    this.handleNewValue(newValue);
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (!isEqual(value, prevProps.value)) {
      this.handleNewValue(value);
    }
  }

  handleNewValue = (value) => {
    const { skipSwitch } = this.props;
    const idsExcluded = without(compact(value), 'any');
    this.setState({
      idsExcluded,
      allSelected: !skipSwitch && isEmpty(idsExcluded),
    });
  };

  handleIdsSelectedChange = (ids, force) => {
    const { onFilterChange, input } = this.props;
    const { idsExcluded, allSelected } = this.state;
    if (force || !isEqual(ids, idsExcluded)) {
      let value = allSelected ? map(ids, (id) => `!=${id}`) : ids;
      // NOTE: in case we're using the component inside a redux-form Field
      if (input) {
        input.onChange(value);
      }
      // NOTE: in case we handle the values from the parent
      if (isEmpty(value) && allSelected) {
        value = ['any'];
      }
      if (onFilterChange) {
        onFilterChange(value);
      }
    }
  };

  handleItemSelected = (id, selected, idsSingleSelection) => {
    const { allSelected, idsExcluded: prevIdsSelected } = this.state;

    // Remove other nested IDs in case of single selection
    let idsExcluded = difference(prevIdsSelected, idsSingleSelection);
    if ((selected && !allSelected) || (!selected && allSelected)) {
      // Concat the new ID to the list of IDs
      idsExcluded = concat(idsExcluded, id);
    } else {
      // Exclude the ID from the list of IDs
      idsExcluded = without(idsExcluded, id);
    }
    const force = includes(idsSingleSelection, id);
    this.handleIdsSelectedChange(idsExcluded, force);
    this.setState({ idsExcluded });
  };

  handleAllSelected = (allSelected) => {
    this.setState({
      allSelected,
      idsExcluded: [],
    });
    setTimeout(() => this.handleIdsSelectedChange([], true));
  };

  handleClearItemsSelected = () => {
    const idsExcluded = [];
    this.handleIdsSelectedChange(idsExcluded);
    this.setState({ idsExcluded });
  };

  render() {
    const { FilterComponent, value, onFilterChange, ...other } = this.props;
    const { allSelected, idsExcluded } = this.state;

    return (
      <FilterComponent
        allSelected={allSelected}
        idsExcluded={idsExcluded}
        limit={limit}
        onAllSelected={this.handleAllSelected}
        onItemSelected={this.handleItemSelected}
        onClearItemsSelected={this.handleClearItemsSelected}
        {...other}
      />
    );
  }
}

FieldComponentFilter.propTypes = {
  FilterComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  skipSwitch: PropTypes.bool,
  input: PropTypes.object,
  onFilterChange: PropTypes.func,
};

FieldComponentFilter.defaultProps = {
  value: [],
  skipSwitch: false,
  input: undefined,
  onFilterChange: undefined,
};

export default FieldComponentFilter;
