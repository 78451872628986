import { fork, takeLatest } from 'redux-saga/effects';

import {
  DISPATCH_CHANGE_LOCALE,
  DISPATCH_FETCH_FONT_FAMILIES,
  DISPATCH_INIT_APP,
  DISPATCH_REFRESH_CONTEXT,
} from '@lib/actions/actionTypes';
import saga from '@lib/actions/saga';
import { sagaFetchFontFamilies, sagaRefreshContext } from '@lib/sagas';

import sagaChangeLocale from './sagaChangeLocale';
import sagaInitApp from './sagaInitApp';

export default function* sagas() {
  yield fork(saga);

  yield takeLatest(DISPATCH_FETCH_FONT_FAMILIES, sagaFetchFontFamilies);
  yield takeLatest(DISPATCH_REFRESH_CONTEXT, sagaRefreshContext);

  // sagas patient-web-app only
  yield takeLatest(DISPATCH_CHANGE_LOCALE, sagaChangeLocale);
  yield takeLatest(DISPATCH_INIT_APP, sagaInitApp);
}
