import configService from './configService';

export const BRANDS = {
  luma: 'luma',
  ucsf: 'ucsf',
};

function getBrandName() {
  let name;
  switch (configService.getBrand()) {
    case BRANDS.ucsf:
      name = 'UCSF';
      break;
    default:
      name = 'Luma Health';
      break;
  }
  return name;
}

export default {
  getBrandName,
};
