import { compact, flatten, head, isEmpty, map, uniq, without } from 'lodash';
import { normalize, schema } from 'normalizr';
import { all, put, select } from 'redux-saga/effects';
import { createAsyncAction } from 'typesafe-actions';

import { Resource } from '../resources/createResource';
import { EntityID } from '../resources/types';
import api, { Error, RequestConfig, Response } from './api';

export interface FetchBulkPayload extends RequestConfig {
  ids: EntityID[];
}

interface Payload extends FetchBulkPayload {
  resource: Resource;
}

const fetchBulk = createAsyncAction(
  '@lib/fetchBulk/REQUEST',
  '@lib/fetchBulk/SUCCESS',
  '@lib/fetchBulk/FAILURE',
  '@lib/fetchBulk/CANCEL',
)<Payload, Response, Error, Payload>();

// saga
export function* sagaFetchBulk(action: ReturnType<typeof fetchBulk.request>) {
  const {
    payload: { resource, ids, meta = {}, ...config },
  } = action;
  const { forceFetch } = meta;

  let idsToFetch = compact(uniq(flatten(ids)));
  if (!forceFetch) {
    const entitiesCached = yield all(map(idsToFetch, (id) => select(resource.makeSelectEntity(id))));
    const idsCached = compact(uniq(map(entitiesCached, 'id')));
    idsToFetch = without(idsToFetch, ...idsCached);
    if (isEmpty(idsToFetch)) {
      yield put(fetchBulk.cancel(action.payload));
      return entitiesCached;
    }
  }

  const responses: Response[] = yield all(
    map(idsToFetch, (id) =>
      api({
        resource,
        config: {
          url: `${resource.url}/${id}`,
          schema: resource.fetchSchema,
          params: {
            includeDeleted: true,
          },
          meta: {
            ignoreErrors: [404],
            ...meta,
          },
          ...config,
        },
      }),
    ),
  );

  // Dispatching action success with all normalized data at once to improve performance
  const data = map(responses, 'data');
  const firstResponse = head(responses) as Response;
  const response: Response = {
    ...firstResponse,
    data,
    normalized: normalize(data, new schema.Array(resource.fetchSchema)),
    meta,
  };
  yield put(fetchBulk.success(response));
}

export default fetchBulk;
