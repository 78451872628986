import { createSelector } from 'reselect';
import { head, values } from 'lodash';

import selectEntities from './selectEntities';

const selectContexts = createSelector(
  selectEntities,
  (entities) => entities.contexts,
);

const selectContext = createSelector(
  selectContexts,
  (contexts) => head(values(contexts)),
);

export default selectContext;
