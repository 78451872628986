import { defaultsDeep } from 'lodash';
import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { fade } from 'material-ui/utils/colorManipulator';

import { getThemePropsLuma, getThemePropsUCSF } from '@lib/core/styles';
import { BRANDS } from '@lib/services/brandsService';
import configService from '@lib/services/configService';
import { createMuiTheme, Theme } from '@material-ui/core/styles';

const fontFamily = '"freight-sans-pro",sans-serif';

const primary1Color = '#003b6d';
const primary2Color = fade(primary1Color, 0.6);
const accent1Color = '#f37021';
const accent2Color = fade(accent1Color, 0.4);
const colorSuccess = '#25a575';

const palette = {
  primary1Color,
  primary2Color,
  accent1Color,
  accent2Color,
  textColor: '#0E3551',
  pickerHeaderColor: primary1Color,
  custom: {
    colorSuccess,
  },
};

const getThemeLegacy = () =>
  getMuiTheme({
    fontFamily,
    palette,
    appBar: {
      color: 'white',
      textColor: primary1Color,
      height: 80,
    },
    toolbar: {
      backgroundColor: 'transparent',
    },
    subheader: {
      color: primary1Color,
      fontWeight: 600,
    },
    listItem: {
      secondaryTextColor: primary1Color,
    },
  });

const getThemeLegacyDark = () =>
  getMuiTheme(darkBaseTheme, {
    fontFamily,
    palette: {
      accent1Color,
      primary1Color: '#ffffff',
      textColor: '#d0d4d6',
      canvasColor: primary1Color,
    },
  });

function getTheme(props = {}) {
  let defaultProps = {
    typography: {
      useNextVariants: true,
    },
    custom: {
      palette: {
        colorSuccess: '#25a575',
      },
    },
  };
  defaultProps =
    configService.getBrand() === BRANDS.ucsf ? getThemePropsUCSF(defaultProps) : getThemePropsLuma(defaultProps);
  return createMuiTheme(defaultsDeep({}, props, defaultProps)) as LumaTheme;
}

export type LumaTheme = Theme & {
  custom?: any;
};

export { getTheme, getThemeLegacy, getThemeLegacyDark };
