import { createSelector } from 'reselect';

import selectState from './selectState';

const selectLoading = createSelector(
  selectState,
  (state) => state.loading,
);

export default selectLoading;
