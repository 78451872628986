import { get } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import { Theme, withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => ({
  root: {
    position: 'fixed' as 'fixed',
    background: 'rgba(255, 255, 255, 0.6)',
    zIndex: 1400,
    top: 0,
    right: 0,
    bottom: 0,
    // TODO: Skip `custom` on theme
    left: get(theme, 'custom.MuiDrawer.width'),
  },
  wrapper: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

interface Props {
  classes: any;
  loading: boolean;
}

const LoadingProgress: React.FC<Props> = ({ classes, loading: propLoading }) => {
  const [loading, setLoading] = useState(propLoading);
  const timerRef = useRef<any>();

  useEffect(() => {
    clearTimeout(timerRef.current);

    if (propLoading) {
      setLoading(true);
    } else {
      timerRef.current = setTimeout(() => {
        setLoading(false);
      }, 300);
    }
    return () => clearTimeout(timerRef.current);
  }, [propLoading]);

  return (
    <Fade
      in={loading}
      style={{
        transitionDelay: loading ? '300ms' : '0ms',
      }}
      unmountOnExit
    >
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <CircularProgress />
        </div>
      </div>
    </Fade>
  );
};

export default withStyles(styles)(LoadingProgress);
