import { createSelector } from 'reselect';

import { usersResource } from '../resources';

import selectAuthMaster from './selectAuthMaster';
import selectSettings from './selectSettings';

const selectCurrentMaster = createSelector(
  usersResource.selectEntitiesObj,
  selectAuthMaster,
  selectSettings,
  (users, id, settings) => {
    const res = users[id];
    if (!res) {
      return null;
    }
    return {
      ...res,
      settings: settings[res.setting],
    };
  },
);

export default selectCurrentMaster;
