import { createSelector } from 'reselect';

import selectState from './selectState';

const makeSelectResult = (resultKey: string) =>
  createSelector(
    selectState,
    (state) => state.result[resultKey] || {},
  );

export default makeSelectResult;
