import { createAsyncAction } from 'typesafe-actions';

import { Resource } from '../resources/createResource';
import api, { Error, RequestConfig, Response } from './api';

export interface FetchListPayload extends RequestConfig {}

interface Payload extends FetchListPayload {
  resource: Resource;
}

const fetchList = createAsyncAction('@lib/fetchList/REQUEST', '@lib/fetchList/SUCCESS', '@lib/fetchList/FAILURE')<
  Payload,
  Response,
  Error
>();

// saga
export function* sagaFetchList(action: ReturnType<typeof fetchList.request>) {
  const {
    payload: { resource, ...config },
  } = action;

  return yield api({
    action: fetchList,
    resource,
    config: {
      url: resource.fetchListUrl,
      schema: resource.fetchListSchema,
      ...config,
    },
  });
}

export default fetchList;
