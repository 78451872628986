import { get, includes } from 'lodash';

import { RequestConfig } from '@lib/actions/api';

import axiosClient from '../utils/axiosClient';
import createResource from './createResource';
import fileUploadsResource from './fileUploadsResource';
import settingsResource from './settingsResource';
import { EntityID, UserBase } from './types';

const usersResource = createResource<UserBase>({
  key: 'users',
  url: '/users',
  // NOTE: use `lookups` instead of `schemaDefinition`?
  // lookups: {
  //   settings: settingsResource,
  // },
  schemaDefinition: {
    settings: settingsResource.settingsSchema,
    avatar: fileUploadsResource.schema,
  },
  processStrategy: (data) => ({
    ...data,
    // extended
    $isWidgetUser: includes(get(data, 'roles'), 'widget'),
  }),
});

const customActions = {
  uploadFile: (
    id: EntityID,
    payload: RequestConfig & {
      data: { name: string; extension: string; fileBase64: string };
    },
  ) =>
    usersResource.save({
      method: 'put',
      url: `${usersResource.url}/${id}/upload`,
      ...payload,
    }),
};

export default {
  ...usersResource,
  ...customActions,
  // TODO: remove
  fetchUser: ({ id }: { id: string }) =>
    axiosClient.get(`${usersResource.url}/${id}`, {
      // @ts-ignore
      schema: usersResource.schema,
    }),
};
