/*
 * App header messages
 *
 * This contains all the text for the Header component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  welcome: {
    id: 'app.containers.Header.welcome',
    defaultMessage: 'Welcome {user}!',
  },
});
