import classnames from 'classnames';
// @ts-ignore
import Liquid from 'liquid-node';
import { head } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import FormattedSimpleMessage from '@lib/core/FormattedSimpleMessage';
import { messageTemplatesResource } from '@lib/resources';
import { selectContext } from '@lib/selectors';
import { isHTML } from '@lib/services/stringsService';
import { extractURLs } from '@lib/services/validationsService';
import { createStyles, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { LumaTheme } from '../styles/getTheme';

const engine = new Liquid.Engine();

const styles = ({ spacing: { unit } }: LumaTheme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',

      // FIXME: skip changing default styles here to make it generic/reusable
      justifyContent: 'space-around',
      alignItems: 'center',
      overflow: 'auto',
    },
    wrapper: {
      maxWidth: 627,
      padding: unit,
      textAlign: 'center',
    },
    button: {
      marginTop: 30,
    },
  });

interface Props {
  classes: any;
  className?: string;
  id: string;
  defaultMessage?: string;
  buttonLabel?: any;
}

const MessageTemplate: React.FC<Props> = ({ classes, className, id, defaultMessage, buttonLabel }) => {
  const messageTemplate = useSelector(messageTemplatesResource.makeSelectEntity(id));
  const context = useSelector(selectContext);

  const [text, setText] = useState('');

  const messageTemplateValue = messageTemplate && messageTemplate.value;

  useEffect(() => {
    async function updateText() {
      const value = await engine.parseAndRender(messageTemplateValue, context);
      setText(value);
    }
    updateText();
  }, [messageTemplateValue, context]);

  if (!text) {
    return <div />;
  }

  // Render HTML
  if (isHTML(text)) {
    return <div className={className} dangerouslySetInnerHTML={{ __html: text }} />;
  }

  // Render message, replacing URLs by button
  // TODO: Move button out of this component
  const { string, urls } = extractURLs(text);
  const url = head(urls);
  return (
    <div className={classnames(classes.root, className)}>
      <div className={classes.wrapper}>
        {string || defaultMessage}
        {buttonLabel && url && (
          <Button className={classes.button} type="submit" variant="contained" color="primary" fullWidth href={url}>
            <FormattedSimpleMessage {...buttonLabel} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(MessageTemplate);
