import { compact, map } from 'lodash';
import { createSelector } from 'reselect';

import { facilitiesResource } from '@lib/resources';
import { makeSelectResultIds } from '@lib/selectors';

export const resultKey = '@lib/core/Filters/FilterListFacilities';

export const selectFacilitiesFromPage = createSelector(
  makeSelectResultIds(resultKey),
  facilitiesResource.selectEntitiesObj,
  (ids, obj) => compact(map(ids, (id) => obj[id])),
);
