import classnames from 'classnames';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { availabilitiesResource } from '@lib/resources';
import { EntityID } from '@lib/resources/types';
import { formatAddress } from '@lib/services/addressesService';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Icon from '@material-ui/core/Icon';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Map from '../Map';

const styles = ({ palette: { secondary, common }, spacing: { unit }, breakpoints: { down } }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      [down('sm')]: {
        flexDirection: 'column',
      },
    },
    dateWrapper: {
      backgroundColor: secondary.main,
      color: common.white,
      width: 100,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      [down('md')]: {
        display: 'none',
      },
    },
    map: {
      width: 185,
      [down('sm')]: {
        height: 150,
        width: '100%',
        justifyContent: 'center',
      },
    },
    content: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
      [down('md')]: {
        justifyContent: 'center',
      },
      '&:last-child': {
        padding: `${unit * 2.5}px ${unit * 4.5}px`,
        [down('md')]: {
          padding: unit * 2,
        },
      },
    },
    row: {
      display: 'flex',
      marginBottom: unit,
    },
    rowInset: {
      marginTop: -1 * unit * 0.5,
      marginLeft: unit * 4,
    },
    icon: {
      marginRight: unit * 1.5,
    },
  });

interface Props {
  classes: any;
  className?: string;
  id: EntityID;
  showDate?: boolean;
}

const CardAvailability: React.FC<Props> = ({ classes, className, id, showDate, ...other }) => {
  const dispatch = useDispatch();

  const availability = useSelector(availabilitiesResource.makeSelectEntity(id));

  useEffect(() => {
    dispatch(availabilitiesResource.fetch({ id, meta: { includeLookups: ['provider', 'facility'] } }));
  }, [dispatch, id]);

  if (!availability) {
    return <></>;
  }

  const date = moment(availability.date);
  const address = formatAddress(availability.facility);
  const addressFallback = availability.facility.address;

  return (
    <Card className={classnames(classes.root, className)} {...other}>
      {showDate && (
        <div className={classes.dateWrapper}>
          <Typography variant="body1" color="inherit" gutterBottom>
            {date.format('MMM').replace('.', '')}
          </Typography>
          <Typography variant="h3" color="inherit">
            {date.format('D')}
          </Typography>
        </div>
      )}
      {!showDate && <Map className={classes.map} query={address} queryFallback={addressFallback} />}
      <CardContent className={classes.content}>
        <div className={classes.row}>
          <Icon className={classes.icon} fontSize="small" color="primary">
            date_range
          </Icon>
          <Typography variant="body1" color="primary">
            {moment(moment(availability.date)).format('LLLL')}
          </Typography>
        </div>
        <div className={classes.row}>
          <Icon className={classes.icon} fontSize="small" color="primary">
            person
          </Icon>
          <Typography variant="body1" color="primary">
            {availability.provider.name}
          </Typography>
        </div>
        <div className={classes.row}>
          <Icon className={classes.icon} fontSize="small" color="primary">
            business
          </Icon>
          <Typography variant="body1" color="primary">
            {availability.facility.name}
          </Typography>
        </div>
        <div className={classes.rowInset}>
          <a href={`https://www.google.com/maps/search/${address}`} target="_blank" rel="noopener noreferrer">
            <Typography variant="caption" color="primary">
            {address}
            </Typography>
          </a>
        </div>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(CardAvailability);
