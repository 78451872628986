/**
 * The global state selectors
 */

import { createSelector } from 'reselect';

const selectGlobal = (state) => state.get('global');
const selectStateForm = (state) => state.get('form');

const makeSelectLoading = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.get('loading'),
  );

const makeSelectError = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.get('error'),
  );

const makeSelectLocationState = () => {
  let prevRoutingState;
  let prevRoutingStateJS;

  return (state) => {
    const routingState = state.get('route'); // or state.route

    if (!routingState.equals(prevRoutingState)) {
      prevRoutingState = routingState;
      prevRoutingStateJS = routingState.toJS();
    }

    return prevRoutingStateJS;
  };
};

const makeSelectFormField = (formName, fieldName) =>
  createSelector(
    selectStateForm,
    (state) => (fieldName ? state.getIn([formName, 'values', fieldName]) : state.getIn([formName, 'values'])),
  );

export { selectGlobal, makeSelectLoading, makeSelectError, makeSelectLocationState, makeSelectFormField };
