import React from 'react';
// @ts-ignore
import { emojify } from 'react-emojione';

import { Theme, withStyles } from '@material-ui/core/styles';

import { isIE } from '../../services/navigatorService';

const styles = (theme: Theme) => {
  const {
    breakpoints,
    // @ts-ignore
    custom: {
      palette: { colorLightYellow, colorSuccess },
    },
    palette: {
      common: { white },
    },
    spacing: { unit },
  } = theme;

  return {
    emoji: {
      color: colorLightYellow,
      fontSize: 22,
      float: 'left' as 'left',
    },
    link: {
      color: white,
    },
    root: {
      backgroundColor: colorSuccess,
      fontWeight: 600,
      padding: `${unit / 2}px 0px ${unit / 2}px ${unit * 6}px`,
      height: 'auto',
      [breakpoints.down('md')]: {
        display: 'none !important',
      },
    },
    text: {
      display: 'inline-block',
      height: '100%',
      width: '90%',
      padding: `${unit / 2}px 0px 0px ${unit * 2}px`,
      float: 'left' as 'left',
      color: white,
    },
  };
};

interface Props {
  classes: any;
}

const InternetExplorerAlert: React.FC<Props> = ({ classes }) => {
  // TODO: Use SnackbarContent instead of using custom styles
  return isIE ? (
    <div className={classes.root}>
      <span className={classes.emoji}>{emojify(':sparkles: ', { output: 'unicode' })}</span>
      <span className={classes.text}>
        The product works best and ensures the highest level of security and privacy with modern browsers. Please
        upgrade to&nbsp;
        <a className={classes.link} href="https://www.google.com/chrome/browser/desktop/index.html">
          Google Chrome
        </a>,&nbsp;
        <a className={classes.link} href="https://www.mozilla.org/firefox/new/">
          Mozilla Firefox
        </a>&nbsp;
        or&nbsp;
        <a className={classes.link} href="https://support.apple.com/downloads/safari">
          Safari
        </a>
        .
      </span>
    </div>
  ) : (
    <></>
  );
};

export default withStyles(styles)(InternetExplorerAlert);
