import appointmentTypesResource from './appointmentTypesResource';
import createResource from './createResource';
import facilitiesResource from './facilitiesResource';
import providersResource from './providersResource';
import { Availability } from './types';

const availabilitiesResource = createResource<Availability>({
  key: 'availabilities',
  url: '/availabilities',
  fetchPageUrl: '/scheduler/availabilities',
  lookups: {
    appointmentType: appointmentTypesResource,
    facility: facilitiesResource,
    provider: providersResource,
  },
});

const customActions = {
  bookAppointment: (availabilityId: Availability['id'], payload: any) =>
    availabilitiesResource.save({
      url: `${availabilitiesResource.url}/${availabilityId}/book`,
      method: 'put',
      ...payload,
    }),
};

export default {
  ...availabilitiesResource,
  ...customActions,
};
