import '@lib/utils/polyfills';
import 'index.css';

import configureStore from 'configureStore';
import { makeSelectLocationState } from 'containers/App/selectors';
import LanguageProvider from 'containers/LanguageProvider';
import translationMessages from 'i18n/translationMessages';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { applyRouterMiddleware, browserHistory, Router } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
// @ts-ignore
import { useScroll } from 'react-router-scroll';
import { createRoutes } from 'routes';
import * as serviceWorker from 'serviceWorker';

import { getThemeLegacy } from '@lib/core/styles/getTheme';
import axiosClientService from '@lib/services/axiosClientService';
import configService from '@lib/services/configService';
import storageService from '@lib/services/storageService';
import axiosClient from '@lib/utils/axiosClient';
import storageClient from '@lib/utils/storageClient';

(window as any).commitHash = configService.getCommitHash();

// Initialize primary GTM container
const gtmId = configService.getGtmId();
if (gtmId) {
  TagManager.initialize({ gtmId });
}
// Initialize additional GTM container (from URL parameter or .env)
const gtmIdAdditional = new URLSearchParams(window.location.search).get('gtmId') || configService.getGtmIdAdditional();
if (gtmIdAdditional) {
  TagManager.initialize({ gtmId: gtmIdAdditional });
}

// NOTE: Set up different shared-lib services
axiosClientService.set(axiosClient);
storageService.setStorage(storageClient);

// Create redux store with history
// this uses the singleton browserHistory provided by react-router
// Optionally, this could be changed to leverage a created history
// e.g. `const browserHistory = useRouterHistory(createBrowserHistory)();`
const store = configureStore(browserHistory);

// Sync history and store, as the react-router-redux reducer
// is under the non-default key ("routing"), selectLocationState
// must be provided for resolving how to retrieve the "route" in the state
const history = syncHistoryWithStore(browserHistory, store, {
  selectLocationState: makeSelectLocationState(),
});

// Set up the router, wrapping all Routes in the App component
const routes = createRoutes(store);

const muiTheme = getThemeLegacy();

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider muiTheme={muiTheme}>
      <LanguageProvider messages={translationMessages}>
        <Router
          history={history}
          routes={routes}
          render={
            // Scroll to top when going to a new page, imitating default browser
            // behaviour
            applyRouterMiddleware(useScroll())
          }
        />
      </LanguageProvider>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
