import { schema } from 'normalizr';

import api from '../utils/axiosClient';

const url = '/tokens';
const entitiesKey = 'tokens';
const idAttribute = 'iss';

// Schemas
const tokenSchema = new schema.Entity(entitiesKey, {}, { idAttribute });

// Methods
function validateToken({ token }) {
  return api.post(
    `${url}/validate`,
    { token },
    {
      schema: tokenSchema,
    },
  );
}

export default {
  entitiesKey,
  validateToken,
};
