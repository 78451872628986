import { applyMiddleware, combineReducers, compose, createStore, Middleware, ReducersMapObject, Store } from 'redux';

export interface StoreWithExtensions extends Store {
  injectedReducers: any;
  injectedSagas: any;
  globalReducers: ReducersMapObject;
  runSaga?: any;
}

class StoreInstance {
  _store: Store | undefined = undefined;
  _combineReducers = combineReducers;

  configureStore(middlewares: Middleware[], initialState: any, globalReducers: ReducersMapObject) {
    // If Redux DevTools Extension is installed use it, otherwise use Redux compose
    /* eslint-disable no-underscore-dangle */
    const composeEnhancers =
      process.env.NODE_ENV !== 'production' &&
      typeof window === 'object' &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose;
    /* eslint-enable */

    const enhancers = [applyMiddleware(...middlewares)];
    const store: StoreWithExtensions = createStore(
      this.createReducer(globalReducers),
      initialState,
      composeEnhancers(...enhancers),
    );

    // Extensions
    store.injectedReducers = {}; // Reducer registry
    store.injectedSagas = {}; // Saga registry
    store.globalReducers = globalReducers;

    this._store = store;
    return store;
  }

  createReducer(globalReducers: ReducersMapObject, injectedReducers: ReducersMapObject | undefined = {}) {
    return this._combineReducers({
      ...globalReducers,
      ...injectedReducers,
    });
  }

  set combineReducers(value: any) {
    this._combineReducers = value;
  }

  get store() {
    return this._store;
  }
}

export default new StoreInstance();
