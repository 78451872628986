import { compact, map } from 'lodash';
import { createSelector } from 'reselect';

import { providersResource } from '@lib/resources';
import { makeSelectResultIds } from '@lib/selectors';

export const resultKey = '@lib/core/Filters/FilterListProviders';

export const selectProvidersFromPage = createSelector(
  makeSelectResultIds(resultKey),
  providersResource.selectEntitiesObj,
  (ids, obj) => compact(map(ids, (id) => obj[id])),
);
