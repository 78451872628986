import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';

import { UserBase } from '@lib/resources/types';
import usersResource from '@lib/resources/usersResource';

import FilterList, { FilterListProps } from '../FilterList';
import { resultKey, selectUsers } from './selectors';

interface Props extends FilterListProps {
  items: UserBase[];
  dispatch: Dispatch;
}

const FilterListUsers: React.FC<Props> = ({ allItems, items, dispatch, idsExcluded, ...other }) => {
  useEffect(() => {
    dispatch(
      usersResource.fetchBulk({
        ids: idsExcluded,
      }),
    );
  }, [dispatch, idsExcluded]);

  return (
    <FilterList
      itemsLabel="assignees"
      allItems={allItems}
      items={items}
      idsExcluded={idsExcluded}
      onSearchChange={(page, limit, name) =>
        dispatch(
          usersResource.fetchPage({
            params: { page, limit, name },
            meta: {
              resultKey,
            },
          }),
        )
      }
      {...other}
    />
  );
};

const withConnect = connect(
  createStructuredSelector({
    allItems: usersResource.selectEntitiesObj,
    items: selectUsers,
  }),
);

export default withConnect(FilterListUsers);
