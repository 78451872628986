import { createSelector } from 'reselect';

import selectSettings from './selectSettings';
import selectCurrentUser from './selectCurrentUser';

// NOTE: not applicable to patients because patients do not have settings
const selectCurrentUserSettings = createSelector(
  selectCurrentUser,
  selectSettings,
  (currentUser, settings) => currentUser && settings[currentUser.setting],
);

export default selectCurrentUserSettings;
