import { compact, find, get, map, random } from 'lodash';
import { DeepPartial } from 'redux';

import {
  PatientFormTemplate,
  PatientFormTemplateItem,
  PatientFormTemplateItemAnswer,
  PatientFormTemplateItemTypeKeys,
} from '@lib/resources/types';

import { updateText } from './stringsService';

function updateSquigglies(data: PatientFormTemplate, prevData?: PatientFormTemplate) {
  if (!prevData) {
    return data;
  }
  const {
    template: { items },
  } = data;
  const {
    template: { items: prevItems },
  } = prevData;

  // Check if there are any squigglies changed
  const replacements = compact(
    map(items, (item) => {
      const prevSquiggly = get(find(prevItems, { id: item.id }), 'squiggly');
      if (prevSquiggly && prevSquiggly !== item.squiggly) {
        return {
          prev: `{{${prevSquiggly}}}`,
          next: `{{${item.squiggly}}}`,
        };
      }
    }),
  );

  return {
    ...data,
    template: {
      ...data.template,
      items: map(items, (item) => ({
        ...item,
        // Update texts with new squigglies
        title: updateText(item.title, replacements),
        description: item.description && updateText(item.description, replacements),
        locales: {
          es: {
            title: updateText(get(item, 'locales.es.title'), replacements),
            description: updateText(get(item, 'locales.es.description'), replacements),
          },
        },
      })),
    },
  };
}

export function requestInterceptor(data: PatientFormTemplate, prevData?: PatientFormTemplate) {
  const { insurance, reminder } = data;
  const newData = {
    ...data,
    // TODO: skip insurance and reminder workarounds
    insurance: {
      enabled: data.type === 'intake' && insurance.enabled,
    },
    reminder: {
      enabled: data.type === 'intake' && reminder.enabled,
    },
  };
  return updateSquigglies(newData, prevData);
}

type PatientFormTemplateItemTypeConfig = {
  label: string;
  icon: string;
  isAvailableOnMenu: boolean;
  disabled: boolean;
  titlePlaceholder: string;
  canSelectAnswer: boolean;
  isFinal: boolean;
  canBeFinal?: boolean;
  isText: boolean;
  supportsSquiggly: boolean;
};

// Utils
export const ITEMS: Record<PatientFormTemplateItemTypeKeys, PatientFormTemplateItemTypeConfig> = {
  template_welcome: {
    label: 'Template Welcome',
    icon: 'play_arrow',
    isAvailableOnMenu: false,
    disabled: false,
    titlePlaceholder: 'Type welcome message here...',
    canSelectAnswer: false,
    isFinal: false,
    isText: false,
    supportsSquiggly: false,
  },
  multiple_choice: {
    label: 'Multiple Choice',
    icon: 'check_box_outlined',
    isAvailableOnMenu: true,
    disabled: false,
    titlePlaceholder: 'Type question here...',
    canSelectAnswer: true,
    isFinal: false,
    isText: false,
    supportsSquiggly: true,
  },
  yes_no: {
    label: 'Yes/No',
    icon: 'tonality',
    // TODO: handle redirects for yes_no type before making it available
    isAvailableOnMenu: false,
    disabled: false,
    titlePlaceholder: 'Type question here...',
    canSelectAnswer: true,
    isFinal: false,
    isText: false,
    supportsSquiggly: true,
  },
  dropdown: {
    label: 'Dropdown',
    icon: 'keyboard_arrow_down',
    isAvailableOnMenu: true,
    disabled: false,
    titlePlaceholder: 'Type question here...',
    canSelectAnswer: true,
    isFinal: false,
    isText: false,
    supportsSquiggly: true,
  },
  short_text: {
    label: 'Short Text',
    icon: 'short_text',
    isAvailableOnMenu: true,
    disabled: false,
    titlePlaceholder: 'Type title here...',
    canSelectAnswer: false,
    isFinal: false,
    isText: true,
    supportsSquiggly: true,
  },
  long_text: {
    label: 'Long Text',
    icon: 'notes',
    isAvailableOnMenu: true,
    disabled: false,
    titlePlaceholder: 'Type title here...',
    canSelectAnswer: false,
    isFinal: false,
    isText: true,
    supportsSquiggly: true,
  },
  email: {
    label: 'Email',
    icon: 'email',
    isAvailableOnMenu: true,
    disabled: false,
    titlePlaceholder: 'Type title here...',
    canSelectAnswer: false,
    isFinal: false,
    isText: true,
    supportsSquiggly: true,
  },
  phone_number: {
    label: 'Phone Number',
    icon: 'phone',
    isAvailableOnMenu: true,
    disabled: false,
    titlePlaceholder: 'Type title here...',
    canSelectAnswer: false,
    isFinal: false,
    isText: true,
    supportsSquiggly: true,
  },
  date: {
    label: 'Date',
    icon: 'today',
    isAvailableOnMenu: true,
    disabled: false,
    titlePlaceholder: 'Type title here...',
    canSelectAnswer: false,
    isFinal: false,
    isText: true,
    supportsSquiggly: true,
  },
  address: {
    label: 'Address',
    icon: 'my_location',
    isAvailableOnMenu: true,
    disabled: false,
    titlePlaceholder: 'Type title here...',
    canSelectAnswer: false,
    isFinal: false,
    isText: false,
    supportsSquiggly: true,
  },
  ssn: {
    label: 'SSN',
    icon: 'assignment_ind',
    isAvailableOnMenu: true,
    disabled: false,
    titlePlaceholder: 'Type title here...',
    canSelectAnswer: false,
    isFinal: false,
    isText: true,
    supportsSquiggly: true,
  },
  medication_search: {
    label: 'Medication search',
    icon: 'search',
    isAvailableOnMenu: true,
    disabled: false,
    titlePlaceholder: 'Type title here...',
    canSelectAnswer: false,
    isFinal: false,
    isText: false,
    supportsSquiggly: false,
  },
  pharmacy_search: {
    label: 'Pharmacy search',
    icon: 'search',
    isAvailableOnMenu: true,
    disabled: false,
    titlePlaceholder: 'Type title here...',
    canSelectAnswer: false,
    isFinal: false,
    isText: false,
    supportsSquiggly: false,
  },
  e_signature: {
    label: 'E-Signature',
    icon: 'edit',
    isAvailableOnMenu: true,
    disabled: false,
    titlePlaceholder: 'Type title here...',
    canSelectAnswer: false,
    isFinal: false,
    isText: false,
    supportsSquiggly: false,
  },
  template: {
    label: 'Template',
    icon: 'code',
    isAvailableOnMenu: true,
    disabled: false,
    titlePlaceholder: 'Type title here...',
    canSelectAnswer: false,
    isFinal: true,
    canBeFinal: true,
    isText: false,
    supportsSquiggly: false,
  },
  '2FA': {
    label: 'Two-factor authentication',
    icon: 'lock',
    isAvailableOnMenu: false,
    disabled: false,
    titlePlaceholder: 'Type title here...',
    canSelectAnswer: false,
    isFinal: false,
    isText: false,
    supportsSquiggly: false,
  },
  patient_information: {
    label: 'Patient Information',
    icon: 'person',
    isAvailableOnMenu: true,
    disabled: false,
    titlePlaceholder: 'Type title here...',
    canSelectAnswer: false,
    isFinal: false,
    isText: false,
    supportsSquiggly: false,
  },
  group: {
    label: 'Question Group',
    icon: 'list',
    isAvailableOnMenu: true,
    disabled: false,
    titlePlaceholder: 'Type title here...',
    canSelectAnswer: false,
    isFinal: false,
    isText: false,
    supportsSquiggly: false,
  },
  jump: {
    label: 'Jump',
    icon: 'low_priority',
    isAvailableOnMenu: true,
    disabled: false,
    titlePlaceholder: 'Type title here...',
    canSelectAnswer: true,
    isFinal: false,
    isText: false,
    supportsSquiggly: false,
  },
};

function generateRandomIdWithPrefix(prefix: string) {
  return `${prefix ? `${prefix}-` : ''}${new Date().getTime()}${random(99999)}`;
}

export function getWelcomeScreenDefaults(): PatientFormTemplateItem {
  return {
    id: generateRandomIdWithPrefix('welcome'),
    type: 'template_welcome',
    title: 'Welcome!',
    properties: {
      template: '',
      actionLabel: 'Start',
    },
    validations: {},
  };
}

export function getAnswerDefaults(): PatientFormTemplateItemAnswer {
  return {
    id: generateRandomIdWithPrefix('answer'),
    redirectType: 'none',
  };
}

function getItemPropertiesDefaults(
  type: PatientFormTemplateItemTypeKeys,
  patientFormTemplateType: PatientFormTemplate['type'],
) {
  if (type === 'patient_information') {
    const patientInformationItems: PatientFormTemplateItem[] = [
      {
        ...getItemDefaults('short_text', patientFormTemplateType),
        id: 'firstname',
        title: 'First Name',
        squiggly: 'patient.firstName',
        properties: {
          mustExist: true,
        },
        validations: {
          required: true,
        },
      },
      {
        ...getItemDefaults('short_text', patientFormTemplateType),
        id: 'lastname',
        title: 'Last Name',
        squiggly: 'patient.lastName',
        properties: {
          mustExist: true,
        },
        validations: {
          required: true,
        },
      },
      {
        ...getItemDefaults('phone_number', patientFormTemplateType),
        id: 'phone',
        title: 'Phone Number',
        squiggly: 'patient.phone',
        properties: {
          mustExist: true,
        },
        validations: {
          required: true,
        },
      },
      {
        ...getItemDefaults('email', patientFormTemplateType),
        id: 'email',
        title: 'E-mail',
        squiggly: 'patient.email',
        validations: {
          required: false,
        },
      },
      {
        ...getItemDefaults('date', patientFormTemplateType),
        id: 'dateOfBirth',
        title: 'Date of Birth',
        squiggly: 'patient.dob',
        properties: {
          mustExist: true,
        },
        validations: {
          required: true,
        },
      },
      {
        ...getItemDefaults('address', patientFormTemplateType),
        id: 'address',
        title: 'Address',
        squiggly: 'patient.address',
        validations: {
          required: false,
        },
      },
    ];
    if (patientFormTemplateType === 'pre-qualification') {
      patientInformationItems.push({
        ...getItemDefaults('2FA', patientFormTemplateType),
        title: 'To confirm your identity, we will text you a verification code',
        validations: {
          required: true,
        },
      });
    }
    return {
      showDescription: false,
      items: patientInformationItems,
    };
  }
  if (type === 'group') {
    return {
      showDescription: false,
      items: [getItemDefaults('short_text', patientFormTemplateType)],
    };
  }
  if (type === 'jump') {
    return {
      showDescription: false,
      script: `if (context.get('patientForm').answers['squiggly'] === 'foo') jump = 'jump1';`,
      answers: [
        {
          ...getAnswerDefaults(),
          label: 'jump1',
        },
      ],
    };
  }
  // TODO: adjust different properties per type so we can cleanup the config on type change
  const canSelectAnswer = ITEMS[type].canSelectAnswer;
  return {
    showDescription: false,
    showCustomAction: false,
    addToAppointment: false,
    allowMultipleSelection: canSelectAnswer ? false : undefined,
    allowOtherChoice: canSelectAnswer ? false : undefined,
    answers: canSelectAnswer ? [getAnswerDefaults()] : undefined,
  };
}

// TODO: Move methods to get defaults to service
export function getItemDefaults(
  type: PatientFormTemplateItemTypeKeys,
  patientFormTemplateType: PatientFormTemplate['type'],
): PatientFormTemplateItem {
  const id = generateRandomIdWithPrefix('item');
  let title = '';
  if (type === 'patient_information') {
    title = 'Patient Information';
  } else if (type === 'jump') {
    title = 'Jump';
  }
  return {
    id,
    type,
    squiggly: id,
    title,
    redirectType: ITEMS[type].isFinal ? 'isFinal' : 'none',
    properties: getItemPropertiesDefaults(type, patientFormTemplateType),
    validations: {
      required: false,
    },
  };
}

export function getDefaults(): DeepPartial<PatientFormTemplate> {
  return {
    title: '',
    reminder: {
      enabled: false,
    },
    insurance: {
      enabled: false,
    },
    externalId: {
      source: 'luma',
    },
    template: {
      // version: '0.1.0', // initial version
      // version: '0.2.0', // template.welcomeScreens
      // version: '0.2.1', // item.properties.addToAppointment
      // version: '0.3.0', // fix typo `wellcome > welcome` + add more item types + required + description
      // version: '0.4.0', // type: 'intake|pre-qualification'
      // version: '0.5.0', // item type email + item.properties.showCustomAction + required is false by default
      // version: '0.6.0', // item.squiggly
      // version: '0.7.0', // item.redirectType + item.properties.goTo
      // version: 'missed', // new item types: 'medication_search', 'pharmacy_search'
      // version: '0.8.0', // new item types: 'medication_search', 'pharmacy_search', 'group'
      // version: '0.9.0', // new item type: 'jump'
      // version: '0.10.0', // new item type: 'patient_information' with '2FA' nested
      // version: '0.11.0', // new item.redirectType: 'isFinal'
      version: '0.12.0', // new item type: 'address'
    },
  };
}
