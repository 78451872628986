import configService from '@lib/services/configService';
import storageService from '@lib/services/storageService';

import createResource from './createResource';
import { Provider } from './types';

const providersResource = createResource<Provider>({
  key: 'providers',
  url: '/providers',
  processStrategy: (data) => ({
    ...data,
    // extended
    $srcAvatar: data.avatar
      ? `${configService.getApiBasePath()}/providers/${data.id}/avatar?token=${storageService.getToken()}`
      : '',
  }),
});

export default providersResource;
