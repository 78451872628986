import { Schema } from 'normalizr';
import { createAsyncAction } from 'typesafe-actions';

import { Resource } from '../resources/createResource';
import api, { Error, RequestConfig, Response } from './api';

export interface RequestPayload extends RequestConfig {
  schema?: Schema;
}

interface Payload extends RequestPayload {
  resource: Resource;
}

const request = createAsyncAction('@lib/request/REQUEST', '@lib/request/SUCCESS', '@lib/request/FAILURE')<
  Payload,
  Response,
  Error
>();

// saga
export function* sagaRequest(action: ReturnType<typeof request.request>) {
  const {
    payload: { resource, ...config },
  } = action;

  return yield api({
    action: request,
    resource,
    config,
  });
}

export default request;
