import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox, FontIcon, TextField } from 'material-ui';
import { isEmpty, map, without, trim } from 'lodash';

const styles = (theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing.unit * 2,
  },
});

const stylesInline = {
  wrapper: {
    display: 'inline-flex',
    flexDirection: 'column',
    float: 'left',
    width: '100%',
  },
  header: {
    backgroundColor: '#fff',
    borderBottom: '1px solid darkgray',
    cursor: 'pointer',
    display: 'inline-block',
    fontWeight: 600,
    height: 46,
    minHeight: 46,
    padding: '12px 16px',
    width: '100%',
  },
  collapseButton: {
    float: 'right',
  },
  body: {
    borderBottom: '1px solid darkgray',
    display: 'inline-block',
    height: '100%',
    maxHeight: 500,
    overflow: 'auto',
    padding: 10,
    width: '100%',
  },
  listOption: {
    padding: 5,
  },
  input: {
    wrapper: {
      backgroundColor: '#fff',
      border: '1px solid #D0D4D6',
      marginBottom: 8,
      width: '100%',
    },
    icon: {
      float: 'right',
      fontSize: 16,
      textAlign: 'center',
      top: 10,
      width: 30,
    },
    placeholder: {
      fontWeight: 500,
      top: 6,
    },
    style: {
      height: 35,
      paddingLeft: 10,
      width: 'calc(100% - 30px)',
    },
  },
  noFilter: {
    color: '#182538',
    fontWeight: 500,
    paddingLeft: 10,
  },
};

class FilterTextBox extends React.Component {
  constructor(props) {
    super(props);

    const {
      input: { value },
    } = props;
    this.state = {
      value: '',
      values: value ? value.split(',') : [],
    };
  }

  componentDidUpdate(prevProps) {
    const {
      input: { value },
    } = this.props;
    if (value !== prevProps.input.value) {
      this.handleValuesChange(value.split(','));
    }
  }

  handleValuesChange = (values) => {
    this.setState({ values });
  };

  handleInputChange = (event) => {
    this.setState({
      value: event.target.value,
    });
  };

  handleInputKeyPress = (event) => {
    const { handleChange } = this.props;
    const { values: prevValues } = this.state;
    const value = trim(event.target.value);

    // submit on enter
    if (event.nativeEvent.keyCode === 13 && value) {
      const values = [...prevValues];
      values.push(value);
      this.setState({
        values,
        value: '',
      });
      handleChange(values.join(','));
    }
  };

  handleCheck = (event) => {
    const { handleChange } = this.props;
    const { values: prevValues } = this.state;

    const values = without(prevValues, event.target.value);
    this.setState({ values });
    handleChange(values.join(','));
  };

  renderList = () => {
    const { values } = this.state;
    if (isEmpty(values)) {
      return <div style={stylesInline.noFilter}>press enter to filter</div>;
    }
    return (
      <div>
        {map(values, (value) => (
          <div key={value} style={stylesInline.listOption}>
            <Checkbox label={value} checked={!isEmpty(value)} value={value} onCheck={this.handleCheck} />
          </div>
        ))}
      </div>
    );
  };

  renderInput = () => {
    const { value } = this.state;
    return (
      <div style={stylesInline.input.wrapper}>
        <TextField
          style={stylesInline.input.style}
          value={value}
          hintText="Enter a code"
          hintStyle={stylesInline.input.placeholder}
          onChange={this.handleInputChange}
          onKeyPress={this.handleInputKeyPress}
          underlineShow={false}
        />
        <FontIcon style={stylesInline.input.icon} className="material-icons">
          search
        </FontIcon>
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.renderInput()}
        {this.renderList()}
      </div>
    );
  }
}

FilterTextBox.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func,
};

export default withStyles(styles)(FilterTextBox);
