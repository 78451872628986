import classnames from 'classnames';
import { get } from 'lodash';
import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';

import { UserBase } from '../../resources/types';
import configService from '../../services/configService';
import storageService from '../../services/storageService';
import { getInitials, stringToColor } from '../../services/stringsService';
import { LumaTheme } from '../styles/getTheme';

const styles = (theme: LumaTheme) => {
  const {
    spacing: { unit },
    palette: {
      common: { white },
    },
    custom: {
      palette: { colorDarkGray },
    },
  } = theme;

  return {
    avatar: {
      color: white,
      fontSize: '0.85rem',
      fontWeight: 500,
    },
    normal: {
      height: 31,
      width: 31,
    },
    personIcon: {
      color: colorDarkGray,
      fontSize: 22,
    },
    small: {
      height: '26px !important',
      width: '26px !important',
    },
    large: {
      fontSize: '1.15rem',
      height: unit * 5,
      width: unit * 5,
    },
    xlarge: {
      fontSize: '1.15rem',
      height: unit * 8,
      width: unit * 8,
    },
  };
};

interface Props {
  classes: any;
  className?: string;
  user: UserBase;
  size?: 'default' | 'small' | 'large' | 'xlarge';
}

const UserAvatar: React.FC<Props> = ({ classes, className, user, size = 'default' }) => {
  const initials = getInitials(get(user, 'name', ''));
  const backgroundColor = stringToColor(initials);
  const content = user ? initials : <PersonIcon className={classes.personIcon} />;
  const avatar = get(user, 'avatar');
  let srcAvatar = get(user, '$srcAvatar');
  if (!srcAvatar && avatar) {
    const token = storageService.getToken();
    srcAvatar = `${configService.getApiBasePath()}/fileUploads/${avatar}/file?token=${token}`;
  }

  return (
    <Avatar
      className={classnames(
        classes.avatar,
        classes.normal,
        {
          [classes.small]: size === 'small',
          [classes.large]: size === 'large',
          [classes.xlarge]: size === 'xlarge',
        },
        className,
      )}
      src={srcAvatar}
      style={{ backgroundColor: initials ? backgroundColor : 'initial' }}
    >
      {content}
    </Avatar>
  );
};

export default withStyles(styles)(UserAvatar);
