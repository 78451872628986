import { call, select } from 'redux-saga/effects';
import { includes } from 'lodash';

import { FETCH_CURRENT_USER, FETCH_CURRENT_MASTER } from '@lib/actions/actionTypes';
import { usersResource } from '@lib/resources';
import { selectAuthUser, selectCurrentToken, selectAuthMaster } from '@lib/selectors';
import { sagaFetch } from '@lib/sagas';

// bind resource generator
const fetchUser = sagaFetch.bind(null, FETCH_CURRENT_USER, usersResource.fetchUser);
const fetchMaster = sagaFetch.bind(null, FETCH_CURRENT_MASTER, usersResource.fetchUser);

export default function* sagaFetchCurrentUser() {
  // fetch current user
  const id = yield select(selectAuthUser);
  yield call(fetchUser, { id });

  // fetch current master
  const { roles } = yield select(selectCurrentToken);
  if (!includes(roles, 'widget')) {
    const masterId = yield select(selectAuthMaster);
    yield call(fetchMaster, { id: masterId });
  }
}
