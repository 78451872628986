import { compact, map } from 'lodash';
import { createSelector } from 'reselect';

import { usersResource } from '@lib/resources';
import { makeSelectResultIds } from '@lib/selectors';

export const resultKey = '@lib/core/Filters/FilterListUsers';

export const selectUsers = createSelector(
  makeSelectResultIds(resultKey),
  usersResource.selectEntitiesObj,
  (ids, obj) => compact(map(ids, (id) => obj[id])),
);
