import PropTypes from 'prop-types';
import React from 'react';

import usePlacesService from '@lib/hooks/usePlacesService';

import AddressAutocomplete from '../AddressAutocomplete';

FieldComponentAddressAutocomplete.propTypes = {
  helperText: PropTypes.string,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

FieldComponentAddressAutocomplete.defaultProps = {
  helperText: '',
};

interface Props {
  helperText?: string;
  input: any;
  meta: any;
  // TODO: Convert AddressAutocomplete to TS
  [AddressAutocompleteProp: string]: any;
}

export default function FieldComponentAddressAutocomplete({
  helperText,
  input: { onChange, value },
  meta: { touched, error },
  ...other
}: Props) {
  // TODO: Load this script inside `components/AddressAutocomplete`
  // https://github.com/hibiken/react-places-autocomplete/issues/270
  const [service] = usePlacesService();

  return (
    <>
      {service && (
        // @ts-ignore
        <AddressAutocomplete
          error={touched && !!error}
          initialValue={value}
          helperText={touched && !!error ? error : helperText}
          onChange={onChange}
          {...other}
        />
      )}
    </>
  );
}
