import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { ActionTypes } from '../actions/actionTypes';

// resuable fetch subroutine
// types : action types object with REQUEST, SUCCESS, ERROR
// fn    : resource function: authResource.signIn | ...
// args  : arguments passed to the resource function
export default function* sagaFetch(types: ActionTypes, fn: any, args: any = {}): SagaIterator {
  let requestAction;
  try {
    requestAction = { type: types.REQUEST, ...args };
    yield put(requestAction);
    const res = yield call(fn, args);
    yield put({ type: types.SUCCESS, ...res, meta: { requestAction } });
    return res;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    yield put({ type: types.ERROR, error });
    return { error, meta: { requestAction } };
  }
}
