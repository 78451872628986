import { createSelector } from 'reselect';

import selectAuth from './selectAuth';

const selectAuthUser = createSelector(
  selectAuth,
  (auth) => auth.user,
);

export default selectAuthUser;
