import { createSelector } from 'reselect';

import selectCurrentUserSettings from './selectCurrentUserSettings';
import selectCurrentMasterSettings from './selectCurrentMasterSettings';

const selectUserSettings = createSelector(
  selectCurrentUserSettings,
  selectCurrentMasterSettings,
  (userSettings, masterSettings) => masterSettings || userSettings,
);

export default selectUserSettings;
