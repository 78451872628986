import classNames from 'classnames';
import React from 'react';

import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent, { SnackbarContentProps as MuiSnackbarContentProps } from '@material-ui/core/SnackbarContent';
import { Theme, withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

import { capitalizeFirstLetter } from '../../services/stringsService';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles = (theme: Theme) => ({
  success: {
    backgroundColor: theme.palette.primary.dark,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  action: {
    // TODO unify styles? I didn't find any other component using this look & feel
    backgroundColor: '#bcd6de',
    color: '#395b7f',
    padding: `0 ${theme.spacing.unit * 3}px`,
    boxShadow: 'none',
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 25,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  messageAction: {
    padding: 0,
  },
  messageWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
});

export type SnackbarContentWrapperVariantKey = 'success' | 'warning' | 'error' | 'info' | 'action';

export interface SnackbarContentProps extends MuiSnackbarContentProps {
  classes: any;
  className?: string;
  message: string;
  variant: SnackbarContentWrapperVariantKey;
  onClose?: any;
}

const SnackbarContentWrapper: React.FC<SnackbarContentProps> = ({
  classes,
  className,
  message,
  variant,
  onClose,
  ...other
}) => {
  const Icon = (variantIcon as any)[variant];
  return (
    <SnackbarContent
      classes={{
        root: classNames(classes[variant], className),
        message: classes[`message${capitalizeFirstLetter(variant)}`],
      }}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.messageWrapper}>
          {Icon && <Icon className={classNames(classes.icon, classes.iconVariant)} />}
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
};

export default withStyles(styles)(SnackbarContentWrapper);
