import { Reducer } from 'redux';

import StoreInstance from './StoreInstance';

function injectReducerFactory(store: any) {
  return function injectReducer(key: string, reducer: Reducer) {
    // Check `store.injectedReducers[key] === reducer` for hot reloading when a key is the same but a reducer is different
    if (Reflect.has(store.injectedReducers, key) && store.injectedReducers[key] === reducer) return;

    store.injectedReducers[key] = reducer; // eslint-disable-line no-param-reassign
    store.replaceReducer(StoreInstance.createReducer(store.globalReducers, store.injectedReducers));
  };
}

export default function getInjectors(store: any) {
  return {
    injectReducer: injectReducerFactory(store),
  };
}
