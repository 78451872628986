import { AxiosRequestConfig } from 'axios';
import { get } from 'lodash';
import { schema as normalizrSchema, Schema } from 'normalizr';

import api from '../utils/axiosClient';

const idAttribute = '_id';

export interface Entity {
  id?: string;
  user?: string;
  createdBy?: string;
  updatedBy?: string;
  updatedAt?: string;
  createdAt?: string;
  deleted?: number | boolean;
}

export interface RequestConfig extends AxiosRequestConfig {
  url: string;
  id?: string;
  // TODO: handle schema on `sagaFetch` as axios#0.19 doesn't support custom configs anymore
  schema?: Schema;
}

export interface RequestSaveConfig extends RequestConfig {
  data: Entity;
}

export interface RequestDeleteConfig extends RequestConfig {
  entitiesKey: string;
  id: string;
}

// Interceptors
const defaultProcessStrategy: (data: Entity) => Entity = (data) => ({
  ...data,
  id: data.id || get(data, idAttribute),
});

// Utils
export const getSchema: <R extends Entity>(entitiesKey: string, processStrategy?: (data: R) => R) => Schema = (
  entitiesKey,
  processStrategy = (data) => data,
) => {
  return new normalizrSchema.Entity(
    entitiesKey,
    {},
    {
      idAttribute,
      processStrategy: (data) => defaultProcessStrategy(processStrategy(data)),
    },
  );
};

// Methods
function fetch(args: RequestConfig) {
  const { url, id, schema, ...other } = args;
  return api.get(id ? `${url}/${id}` : url, {
    // @ts-ignore
    schema: id ? schema : { response: [schema] },
    ...other,
  });
}

function fetchPage(args: RequestConfig) {
  const { url, schema, ...other } = args;
  return api.get(url, {
    // @ts-ignore
    schema: { response: [schema] },
    ...other,
  });
}

function save(args: RequestSaveConfig) {
  const { url, data, schema, ...other } = args;
  const id = get(data, idAttribute);
  // @ts-ignore
  return api({
    method: id ? 'put' : 'post',
    url: id ? `${url}/${id}` : url,
    schema,
    data,
    ...other,
  });
}

async function del(args: RequestDeleteConfig) {
  const { url, id, entitiesKey, ...other } = args;
  const res = await api.delete(`${url}/${id}`, {
    ...other,
  });
  return {
    ...res,
    normalized: {
      entities: {
        [entitiesKey]: {
          [id]: {
            deleted: true,
          },
        },
      },
    },
  };
}

export default {
  fetch,
  fetchPage,
  save,
  del,
};
