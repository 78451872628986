// These are the pages you can go to.
// They are all wrapped in the App component, which should contain the navbar etc
// See http://blog.mxstbr.com/2016/01/react-apps-with-pages for more information
// about the code splitting business
import getReducerInjectors from '@lib/utils/reducerInjectors';
import getSagaInjectors from '@lib/utils/sagaInjectors';
import { capitalizeFirstLetter } from '@lib/services/stringsService';

import App from 'containers/App';
import Main from 'containers/Main';

const errorLoading = (err) => {
  console.error('Dynamic page loading failed', err); // eslint-disable-line no-console
};

const loadModule = (cb) => (componentModule) => {
  cb(null, componentModule.default);
};

const rootRedirect = (nextState, replace) => {
  const {
    location: { pathname, query },
  } = nextState;
  if (pathname !== '/') {
    return;
  }
  replace({
    pathname: '/login',
    query,
  });
};

export function createRoutes(store) {
  // Create reusable async injectors using getAsyncInjectors factory
  const reducerInjectors = getReducerInjectors(store);
  const sagaInjectors = getSagaInjectors(store);

  const getComponentMain = (nextState, cb) => {
    // lookup nested component per page
    const {
      params: { page },
    } = nextState;
    const compName = `${capitalizeFirstLetter(page)}Page`;
    const importModules = Promise.all([
      import(`containers/${compName}/reducer`),
      import(`containers/${compName}/sagas`),
      import(`containers/${compName}/index`),
    ]);

    const renderRoute = loadModule(cb);

    importModules.then(([reducer, sagas, component]) => {
      reducerInjectors.injectReducer(capitalizeFirstLetter(compName), reducer.default);
      sagaInjectors.injectSaga(compName, { saga: sagas.default });
      renderRoute(component);
    });

    importModules.catch(errorLoading);
  };

  const internalRoutes = [
    {
      component: App,
      path: '/',
      onEnter: rootRedirect,
      childRoutes: [
        {
          path: '/login',
          getComponent(nextState, cb) {
            const importModules = Promise.all([import('containers/LoginPage/sagas'), import('containers/LoginPage')]);
            const renderRoute = loadModule(cb);
            importModules.then(([sagas, component]) => {
              sagaInjectors.injectSaga('LoginPage', { saga: sagas.default });
              renderRoute(component);
            });

            importModules.catch(errorLoading);
          },
        },
        {
          path: '/signup',
          getComponent(nextState, cb) {
            const importModules = Promise.all([import('containers/SignupPage/sagas'), import('containers/SignupPage')]);
            const renderRoute = loadModule(cb);
            importModules.then(([sagas, component]) => {
              sagaInjectors.injectSaga('SignupPage', { saga: sagas.default });
              renderRoute(component);
            });

            importModules.catch(errorLoading);
          },
        },
        {
          path: '/verify',
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              import('containers/VerifyUserPage/reducer'),
              import('containers/VerifyUserPage/sagas'),
              import('containers/VerifyUserPage'),
            ]);
            const renderRoute = loadModule(cb);
            importModules.then(([reducer, sagas, component]) => {
              reducerInjectors.injectReducer('VerifyUserPage', reducer.default);
              sagaInjectors.injectSaga('VerifyUserPage', { saga: sagas.default });
              renderRoute(component);
            });

            importModules.catch(errorLoading);
          },
        },
        {
          path: '/main',
          component: Main,
          childRoutes: [
            {
              path: '/main/:page',
              getComponent: getComponentMain,
            },
          ],
        },
        {
          path: '/availabilities',
          getComponent(nextState, cb) {
            import('screens/AvailabilitiesScreen')
              .then(loadModule(cb))
              .catch(errorLoading);
          },
        },
        {
          path: '/reschedule/availabilities',
          getComponent(nextState, cb) {
            import('screens/AvailabilitiesScreen')
              .then(loadModule(cb))
              .catch(errorLoading);
          },
        },
        {
          path: '/insurance',
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              import('containers/InsurancePage/reducer'),
              import('containers/InsurancePage/sagas'),
              import('containers/InsurancePage'),
            ]);
            const renderRoute = loadModule(cb);
            importModules.then(([reducer, sagas, component]) => {
              reducerInjectors.injectReducer('InsurancePage', reducer.default);
              sagaInjectors.injectSaga('InsurancePage', { saga: sagas.default });
              renderRoute(component);
            });
            importModules.catch(errorLoading);
          },
        },
        {
          path: '/reschedule/book',
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              import('containers/RescheduleBookPage/reducer'),
              import('containers/RescheduleBookPage/sagas'),
              import('containers/RescheduleBookPage'),
            ]);
            const renderRoute = loadModule(cb);
            importModules.then(([reducer, sagas, component]) => {
              reducerInjectors.injectReducer('RescheduleBookPage', reducer.default);
              sagaInjectors.injectSaga('RescheduleBookPage', { saga: sagas.default });
              renderRoute(component);
            });

            importModules.catch(errorLoading);
          },
        },
        {
          path: '/reschedule/book/success',
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              import('containers/RescheduleBookSuccessPage/reducer'),
              import('containers/RescheduleBookSuccessPage/sagas'),
              import('containers/RescheduleBookSuccessPage'),
            ]);
            const renderRoute = loadModule(cb);
            importModules.then(([reducer, sagas, component]) => {
              reducerInjectors.injectReducer('RescheduleBookSuccessPage', reducer.default);
              sagaInjectors.injectSaga('RescheduleBookSuccessPage', { saga: sagas.default });
              renderRoute(component);
            });

            importModules.catch(errorLoading);
          },
        },
        {
          path: '/reschedule/waitlist',
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              import('containers/RescheduleWaitlistPage/reducer'),
              import('containers/RescheduleWaitlistPage/sagas'),
              import('containers/RescheduleWaitlistPage'),
            ]);
            const renderRoute = loadModule(cb);
            importModules.then(([reducer, sagas, component]) => {
              reducerInjectors.injectReducer('RescheduleWaitlistPage', reducer.default);
              sagaInjectors.injectSaga('RescheduleWaitlistPage', { saga: sagas.default });
              renderRoute(component);
            });

            importModules.catch(errorLoading);
          },
        },
        {
          path: '/reschedule/waitlist/success',
          getComponent(nextState, cb) {
            import('containers/RescheduleWaitlistSuccessPage')
              .then(loadModule(cb))
              .catch(errorLoading);
          },
        },
        {
          path: '/offers/reply',
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              import('containers/OffersReplyPage/reducer'),
              import('containers/OffersReplyPage/sagas'),
              import('containers/OffersReplyPage'),
            ]);
            const renderRoute = loadModule(cb);
            importModules.then(([reducer, sagas, component]) => {
              reducerInjectors.injectReducer('OffersReplyPage', reducer.default);
              sagaInjectors.injectSaga('OffersReplyPage', { saga: sagas.default });
              renderRoute(component);
            });

            importModules.catch(errorLoading);
          },
        },
        {
          path: '/reminders/reply',
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              import('containers/RemindersReplyPage/reducer'),
              import('containers/RemindersReplyPage/sagas'),
              import('containers/RemindersReplyPage'),
            ]);
            const renderRoute = loadModule(cb);
            importModules.then(([reducer, sagas, component]) => {
              reducerInjectors.injectReducer('RemindersReplyPage', reducer.default);
              sagaInjectors.injectSaga('RemindersReplyPage', { saga: sagas.default });
              renderRoute(component);
            });

            importModules.catch(errorLoading);
          },
        },
        {
          path: '/book',
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              import('containers/BookPage/reducer'),
              import('containers/BookPage/sagas'),
              import('containers/BookPage'),
            ]);
            const renderRoute = loadModule(cb);
            importModules.then(([reducer, sagas, component]) => {
              reducerInjectors.injectReducer('BookPage', reducer.default);
              sagaInjectors.injectSaga('BookPage', { saga: sagas.default });
              renderRoute(component);
            });

            importModules.catch(errorLoading);
          },
        },
        {
          path: '/waitlists/success',
          getComponent(nextState, cb) {
            import('containers/WaitlistSuccessPage')
              .then(loadModule(cb))
              .catch(errorLoading);
          },
        },
        {
          path: '/waitlists/:id',
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              import('containers/WaitlistPage/reducer'),
              import('containers/WaitlistPage/sagas'),
              import('containers/WaitlistPage'),
            ]);

            const renderRoute = loadModule(cb);

            importModules.then(([reducer, sagas, component]) => {
              reducerInjectors.injectReducer('WaitlistPage', reducer.default);
              sagaInjectors.injectSaga('WaitlistPage', { saga: sagas.default });
              renderRoute(component);
            });

            importModules.catch(errorLoading);
          },
        },
        {
          path: '/feedback/score/:id',
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              import('containers/FeedbackPage/reducer'),
              import('containers/FeedbackPage/sagas'),
              import('containers/FeedbackPage'),
            ]);

            const renderRoute = loadModule(cb);

            importModules.then(([reducer, sagas, component]) => {
              reducerInjectors.injectReducer('FeedbackPage', reducer.default);
              sagaInjectors.injectSaga('FeedbackPage', { saga: sagas.default });
              renderRoute(component);
            });

            importModules.catch(errorLoading);
          },
        },
        {
          path: '/unsubscribe',
          getComponent(nextState, cb) {
            import('containers/UnsubscribePage')
              .then(loadModule(cb))
              .catch(errorLoading);
          },
        },
        {
          path: '/form',
          getComponent(nextState, cb) {
            import('screens/PatientFormScreen')
              .then(loadModule(cb))
              .catch(errorLoading);
          },
        },
        {
          path: '/session-expired',
          getComponent(nextState, cb) {
            import('screens/SessionExpiredScreen')
              .then(loadModule(cb))
              .catch(errorLoading);
          },
        },
        // TODO: remove `/survey` route and use `/forms`
        {
          path: '/survey',
          getComponent(nextState, cb) {
            import('screens/PatientFormScreen')
              .then(loadModule(cb))
              .catch(errorLoading);
          },
        },
        // NotFoundPage
        {
          path: '*',
          name: 'notfound',
          getComponent(nextState, cb) {
            import('containers/NotFoundPage')
              .then(loadModule(cb))
              .catch(errorLoading);
          },
        },
      ],
    },
  ];

  return internalRoutes;
}
