import { createSelector } from 'reselect';
import { get } from 'lodash';

import makeSelectResult from './makeSelectResult';

const makeSelectResultIds = (key) =>
  createSelector(
    makeSelectResult(key),
    (result) => get(result, 'response') || result,
  );

export default makeSelectResultIds;
