import { createSelector } from 'reselect';

import { usersResource } from '../resources';

import selectAuthUser from './selectAuthUser';
import selectSettings from './selectSettings';

const selectCurrentUser = createSelector(
  usersResource.selectEntitiesObj,
  selectAuthUser,
  selectSettings,
  (users, id, settings) => {
    const res = users[id];
    if (!res) {
      return null;
    }
    return {
      ...res,
      settings: settings[res.setting],
    };
  },
);

export default selectCurrentUser;
