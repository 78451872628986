import { get, head, map } from 'lodash';
import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Theme, withStyles } from '@material-ui/core/styles';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import UserAvatar from '../UserAvatar';

const styles = (theme: Theme) => {
  const {
    spacing: { unit },
  } = theme;
  return {
    listItem: {
      margin: 0,
      padding: `${unit / 2}px 0`,
    },
    userAvatarWrapper: {
      padding: `${unit / 2}px ${unit * 1.5}px ${unit / 2}px ${unit / 2}px`,
    },
    button: {
      padding: `0 ${unit * 1.5}px`,
    },
    text: {
      paddingLeft: 0,
      '& > span': {
        fontSize: '16px !important',
      },
    },
    radioGroup: {
      paddingLeft: 30,
    },
  };
};

export interface FilterListItemProps {
  classes: any;
  item: any;
  disabled?: boolean;
  checked?: boolean;
  showAvatar?: boolean;
  onItemSelected: (value: string | string[], selected: boolean, idsSingleSelection?: string[]) => void;
}

const FilterListItem: React.FC<FilterListItemProps> = ({
  classes,
  item,
  disabled,
  checked,
  showAvatar,
  onItemSelected,
  ...other
}) => {
  const { id, name, radioGroup } = item;
  const nestedItems = get(radioGroup, 'items');
  const nestedItemIds = map(nestedItems, 'id');
  const defaultNestedValue = head(nestedItemIds);

  return (
    <React.Fragment>
      <ListItem
        classes={{
          dense: classes.listItem,
        }}
        role={undefined}
        disableGutters
        button={!disabled}
        dense
        onClick={() =>
          onItemSelected(!checked && defaultNestedValue ? [id, defaultNestedValue] : id, !checked, nestedItemIds)
        }
        {...other}
      >
        <Checkbox
          classes={{
            root: classes.button,
          }}
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          checked={checked}
          disabled={disabled}
          color="primary"
          tabIndex={-1}
          disableRipple
        />
        {showAvatar && (
          <span className={classes.userAvatarWrapper}>
            <UserAvatar user={item} />
          </span>
        )}
        <ListItemText className={classes.text} primary={name} />
      </ListItem>
      {!disabled && checked && defaultNestedValue && (
        <RadioGroup
          className={classes.radioGroup}
          defaultValue={defaultNestedValue}
          onChange={(_, value) => onItemSelected(value, true, nestedItemIds)}
        >
          {map(radioGroup.items, (nestedItem) => (
            <FormControlLabel
              classes={{
                root: classes.listItem,
              }}
              key={nestedItem.id}
              value={nestedItem.id}
              control={
                <Radio
                  className={classes.button}
                  color="primary"
                  icon={<RadioButtonUncheckedIcon fontSize="small" />}
                  checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                />
              }
              label={nestedItem.name}
            />
          ))}
        </RadioGroup>
      )}
    </React.Fragment>
  );
};

export default withStyles(styles)(FilterListItem);
