import { get } from 'lodash';
import { schema as normalizr, Schema } from 'normalizr';

import { EntityBase } from './types';

export interface CreateResourceSchemaArgs<E extends EntityBase> {
  key: string;
  schemaDefinition?: Schema;
  idAttribute?: string;
  processStrategy?: (data: E) => E;
}

export const defaultProcessStrategy = <E extends Partial<EntityBase>>(data: E, $isPopulated: boolean) => {
  const $isDeleted = data.deleted === 1;
  let $toString = data.name || '...';
  if ($isDeleted) {
    $toString += ' [DELETED]';
  }
  return {
    ...data,
    id: get(data, '_id') || data.id,
    $isDeleted,
    $toString,
    $isPopulated,
  };
};

function createResourceSchema<E extends EntityBase>(args: CreateResourceSchemaArgs<E>): Schema {
  const idAttribute = args.idAttribute || '_id';
  const processStrategy = (data: E) => {
    const res = defaultProcessStrategy(data, true);
    return args.processStrategy ? args.processStrategy(res) : res;
  };
  return new normalizr.Entity(args.key, args.schemaDefinition || {}, { idAttribute, processStrategy });
}

export default createResourceSchema;
