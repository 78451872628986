import { extend, get as lodashGet, omit, pick } from 'lodash';
import moment from 'moment';

import configService from './configService';

interface Storage {
  get: (key: string) => any;
  set: (key: string, value: any) => void;
  remove: (key: string) => void;
}

interface StorageNative extends Omit<Storage, 'get'> {
  get: (key: string) => Promise<any>;
}

let storage: Storage | StorageNative;

function setStorage(storageClient: Storage | StorageNative) {
  storage = storageClient;
}

const appPrefix = configService.getAppPrefix();

// TODO: cleanup storage to make it more generic
const KEYS = {
  token: `${appPrefix}:auth_token`,
  parameters: `${appPrefix}:parameters`,
  uploadedCards: `${appPrefix}:uploaded_cards`,
  locale: `${appPrefix}:locale`,
  formChoices: `${appPrefix}:form_choices`,
  patientFormPending: `${appPrefix}:patient_forms_pending`,
  user: `${appPrefix}:auth_user`,
  tutorialDone: `${appPrefix}:referral_tutorial`,
};

function get(key: string) {
  try {
    return storage.get(key);
  } catch (e) {
    // ignoring corrupted values
  }
}

function set(key: string, value: any) {
  return storage.set(key, value);
}

function remove(key: string) {
  return storage.remove(key);
}

function getToken() {
  return get(KEYS.token) || lodashGet(getUser(), 'token');
}

function setToken(value: any) {
  return set(KEYS.token, value);
}

function removeAuth() {
  stopSessionRefresh();
  remove(KEYS.token);
  remove(KEYS.user);
}

function getParameters() {
  return get(KEYS.parameters) || {};
}

function setParameters(value: any) {
  return set(KEYS.parameters, omit(value, ['token']));
}

function extendParameters(newParams: any) {
  const oldParams = getParameters();
  const params = extend({}, oldParams, newParams);
  setParameters(params);
  return params;
}

function getUploadedCards() {
  return get(KEYS.uploadedCards);
}

function setUploadedCards(value: any) {
  return set(KEYS.uploadedCards, value);
}

function getLocale() {
  return get(KEYS.locale);
}

function setLocale(value: any) {
  return set(KEYS.locale, value);
}

function removeLocale() {
  return remove(KEYS.locale);
}

function getFormChoices() {
  return get(KEYS.formChoices);
}

function setFormChoices(value: any) {
  return set(KEYS.formChoices, value);
}

function removeFormChoices() {
  return remove(KEYS.formChoices);
}

function getUser(): any {
  return get(KEYS.user);
}

function setUser(user: any) {
  const data = pick(user, ['token', 'expireAt']);
  set(KEYS.user, data);
  resetSession();
  startSessionRefresh();
}

function getCurrentUserExpireAt() {
  return lodashGet(getUser(), 'expireAt');
}

function isTutorialActivityDone() {
  return get(KEYS.tutorialDone) || false;
}

function setTutorialActivityDone(value: any) {
  return set(KEYS.tutorialDone, value);
}

function getPatientFormPending() {
  return get(KEYS.patientFormPending);
}

function setPatientFormPending(id: any) {
  return set(KEYS.patientFormPending, id);
}

function removePatientFormPending() {
  return remove(KEYS.patientFormPending);
}

// FIXME remove dependencies on window
function startSessionRefresh() {
  // @ts-ignore
  window.addEventListener('click', resetSession);
  // @ts-ignore
  window.addEventListener('keydown', resetSession);
}

function stopSessionRefresh() {
  // @ts-ignore
  window.removeEventListener('click', resetSession);
  // @ts-ignore
  window.removeEventListener('keydown', resetSession);
}

function getSessionTimeMinutes() {
  // DISCUSSION should this go inside config ?
  return 30;
}

function resetSession() {
  const user = getUser();
  if (user) {
    user.expireAt = moment().add(getSessionTimeMinutes(), 'minutes');
    set(KEYS.user, user);
  }
}

function refreshSession() {
  const user = getUser();
  if (user) {
    setUser(user);
  }
}

export default {
  setStorage,
  get,
  set,
  remove,
  getToken,
  setToken,
  removeAuth,
  getParameters,
  setParameters,
  getUploadedCards,
  setUploadedCards,
  extendParameters,
  getLocale,
  setLocale,
  removeLocale,
  getFormChoices,
  setFormChoices,
  removeFormChoices,
  getUser,
  setUser,
  getCurrentUserExpireAt,
  getPatientFormPending,
  setPatientFormPending,
  removePatientFormPending,
  isTutorialActivityDone,
  setTutorialActivityDone,
  getSessionTimeMinutes,
  refreshSession,
};
