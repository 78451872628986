import { call, select, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { includes, last, filter, some } from 'lodash';

import { FETCH_CONTEXT, DISPATCH_MESSAGE_ID_OVERRIDE } from '../actions/actionTypes';
import contextsResource from '../resources/contextsResource';
import { selectContext } from '../selectors';

import sagaFetch from './sagaFetch';

// bind resource generator
const fetchContext = sagaFetch.bind(null, FETCH_CONTEXT, contextsResource.fetchContext);

const findTemplateName = (context, status) => {
  if (!context.templates) {
    return;
  }
  return last(filter(context.templates, (name) => includes(name.toLowerCase(), status)));
};

const containsTemplateName = (context, templateNames) =>
  some(templateNames, (templateName) => includes(context.templates, templateName));

export default function* sagaRefreshContext(action) {
  const { refId, templateName, status } = action;
  let currentContext = yield select(selectContext);
  if (!refId || !templateName || !currentContext || !status || !containsTemplateName(currentContext, templateName)) {
    return;
  }

  const MAX_RETRIES = 2;
  let retryCount = 0;
  let templateNameOverride = findTemplateName(currentContext, status);

  while (containsTemplateName(currentContext, templateName) && !templateNameOverride && retryCount < MAX_RETRIES) {
    yield delay(500);
    yield call(fetchContext, { refId });
    currentContext = yield select(selectContext);
    templateNameOverride = findTemplateName(currentContext, status);
    retryCount += 1;
  }

  const messageIdOverride = templateNameOverride ? `messages.web.${templateNameOverride}` : '';
  yield put({ type: DISPATCH_MESSAGE_ID_OVERRIDE, messageIdOverride });
}
