import { createReducer } from 'typesafe-actions';

import { showMessage } from '../actions';

const initialState = {};

const createMessageReducer = () =>
  createReducer(initialState).handleAction(showMessage, (_, action) => ({
    timestamp: new Date().getTime(),
    message: action.payload,
    variant: action.meta,
  }));

export default createMessageReducer;
