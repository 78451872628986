import { Address } from '@lib/resources/types';

export const formatAddress = (data: Address) => {
  const { address, city, state, postcode } = data;

  if (address && city && state && postcode) {
    return `${address}, ${city}, ${state} ${postcode}`;
  }

  return '';
};
