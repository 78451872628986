import configService from '../services/configService';

export const PREFIX = `@${configService.getAppPrefix()}`;
export const SUFFIX_REQUEST = '_REQUEST';
export const SUFFIX_SUCCESS = '_SUCCESS';
export const SUFFIX_ERROR = '_ERROR';

export type ActionTypeKeys = 'REQUEST' | 'SUCCESS' | 'ERROR';

export type ActionTypes = Record<ActionTypeKeys, string>;

const createActionType: (base: string) => string = (base) => {
  return `${PREFIX}/${base}`;
};

export const createActionTypes: (base: string) => ActionTypes = (base) => ({
  REQUEST: createActionType(`${base}${SUFFIX_REQUEST}`),
  SUCCESS: createActionType(`${base}${SUFFIX_SUCCESS}`),
  ERROR: createActionType(`${base}${SUFFIX_ERROR}`),
});

// sync actions
export const DISPATCH_APP_READY = createActionType('DISPATCH_APP_READY');
export const DISPATCH_CHANGE_LOCALE = createActionType('DISPATCH_CHANGE_LOCALE');
export const DISPATCH_FETCH_APPOINTMENTS = createActionType('DISPATCH_FETCH_APPOINTMENTS');
export const DISPATCH_FETCH_CUSTOM_WEB_STYLES = createActionType('DISPATCH_FETCH_CUSTOM_WEB_STYLES');
export const DISPATCH_FETCH_FONT_FAMILIES = createActionType('DISPATCH_FETCH_FONT_FAMILIES');
export const DISPATCH_HACK = createActionType('DISPATCH_HACK');
export const DISPATCH_INIT_APP = createActionType('DISPATCH_INIT_APP');
export const DISPATCH_MESSAGE_ID_OVERRIDE = createActionType('DISPATCH_MESSAGE_ID_OVERRIDE');
export const DISPATCH_PUBLISH_CUSTOM_WEB_STYLES = createActionType('DISPATCH_PUBLISH_CUSTOM_WEB_STYLES');
export const DISPATCH_REFRESH_CONTEXT = createActionType('DISPATCH_REFRESH_CONTEXT');
export const DISPATCH_SAVE_CUSTOM_WEB_STYLES = createActionType('DISPATCH_SAVE_CUSTOM_WEB_STYLES');
export const DISPATCH_SAVE_SETTINGS = createActionType('DISPATCH_SAVE_SETTINGS');
export const DISPATCH_SIGN_IN = createActionType('DISPATCH_SIGN_IN');
export const DISPATCH_SIGN_OUT = createActionType('DISPATCH_SIGN_OUT');

// async actions (with REQUEST, SUCCESS and ERROR types)
export const FETCH_APPOINTMENTS = createActionTypes('FETCH_APPOINTMENTS');
export const FETCH_CONTEXT = createActionTypes('FETCH_CONTEXT');
export const FETCH_CURRENT_MASTER = createActionTypes('FETCH_CURRENT_MASTER');
export const FETCH_CURRENT_USER = createActionTypes('FETCH_CURRENT_USER');
export const FETCH_CUSTOM_WEB_STYLES = createActionTypes('FETCH_CUSTOM_WEB_STYLES');
export const FETCH_FONT_FAMILIES = createActionTypes('FETCH_FONT_FAMILIES');
export const FETCH_WIDGET_USER = createActionTypes('FETCH_WIDGET_USER');
export const LOGIN_WIDGET_USER = createActionTypes('LOGIN_WIDGET_USER');
export const PUBLISH_CUSTOM_WEB_STYLES = createActionTypes('PUBLISH_CUSTOM_WEB_STYLES');
export const SAVE_CUSTOM_WEB_STYLES = createActionTypes('SAVE_CUSTOM_WEB_STYLES');
export const SAVE_SETTINGS = createActionTypes('SAVE_SETTINGS');
export const SIGN_IN = createActionTypes('SIGN_IN');
export const SIGN_OUT = createActionTypes('SIGN_OUT');
export const VALIDATE_TOKEN = createActionTypes('VALIDATE_TOKEN');
