import { put, select } from 'redux-saga/effects';
import { createAsyncAction } from 'typesafe-actions';

import { Resource } from '../resources/createResource';
import { EntityID } from '../resources/types';
import api, { Error, RequestConfig, Response } from './api';

export interface FetchPayload extends RequestConfig {
  id: EntityID;
}

interface Payload extends FetchPayload {
  resource: Resource;
}

const fetch = createAsyncAction('@lib/fetch/REQUEST', '@lib/fetch/SUCCESS', '@lib/fetch/FAILURE', '@lib/fetch/CANCEL')<
  Payload,
  Response,
  Error,
  Payload
>();

// saga
export function* sagaFetch(action: ReturnType<typeof fetch.request>) {
  const {
    payload: { resource, id, meta = {}, ...config },
  } = action;
  const { forceFetch, ...restMeta } = meta;

  const entityCached = yield select(resource.makeSelectEntity(id));
  if (entityCached && !forceFetch) {
    yield put(fetch.cancel(action.payload));
    return entityCached;
  }

  return yield api({
    action: fetch,
    resource,
    config: {
      url: `${resource.url}/${id}`,
      schema: resource.fetchSchema,
      meta: restMeta,
      ...config,
    },
  });
}

export default fetch;
