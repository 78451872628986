import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { map } from 'lodash';

const styles = (theme) => ({
  listItem: {
    margin: 0,
    padding: `${theme.spacing.unit / 2}px 0`,
  },
  button: {
    padding: `0 ${theme.spacing.unit * 1.5}px`,
  },
});

class FilterSingleChoice extends PureComponent {
  render() {
    const { classes, items, value, onFilterChange } = this.props;

    return (
      <RadioGroup value={value || items[0]._id} onChange={(event, v) => onFilterChange(v)}>
        {map(items, ({ _id, name }) => (
          <FormControlLabel
            classes={{
              root: classes.listItem,
            }}
            key={_id}
            value={_id}
            control={
              <Radio
                className={classes.button}
                color="primary"
                icon={<RadioButtonUncheckedIcon fontSize="small" />}
                checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
              />
            }
            label={name}
          />
        ))}
      </RadioGroup>
    );
  }
}

FilterSingleChoice.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  value: PropTypes.string,
  onFilterChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(FilterSingleChoice);
