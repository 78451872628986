/*
 * Translate banner messages
 *
 * This contains all the text for the TranslateBanner component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  cancelButton: {
    id: 'app.components.TranslateBanner.cancelButton',
    defaultMessage: 'Do not ask me again',
  },
  confirmButton: {
    id: 'app.components.TranslateBanner.confirmButton',
    defaultMessage: 'Sure!',
  },
  text: {
    id: 'app.components.TranslateBanner.text',
    defaultMessage: 'Hi! Would you like to try {brandName} in {language}?',
  },
});
