import { get, mergeWith, isArray, merge } from 'lodash';

import { SIGN_OUT, DISPATCH_MESSAGE_ID_OVERRIDE } from '@lib/actions/actionTypes';

// Updates an entity cache in response to any action with normalized.entities
export default function createEntitiesReducer(initialState) {
  return (state = initialState, action) => {
    const entities = get(action, 'payload.normalized.entities') || get(action, 'normalized.entities');
    if (entities) {
      return mergeWith({}, state, entities, (dest, src) => (isArray(dest) && isArray(src) ? src : undefined));
    }
    switch (action.type) {
      case DISPATCH_MESSAGE_ID_OVERRIDE:
        return merge({}, state, {
          messageIdOverride: action.messageIdOverride,
        });
      // SIGN_OUT -> reset state
      case SIGN_OUT:
        return initialState;
      default:
        return state;
    }
  };
}
