import { createSelector } from 'reselect';
import { omitBy } from 'lodash';

import selectEntities from './selectEntities';
import selectAuthUser from './selectAuthUser';

const selectTokens = createSelector(
  selectEntities,
  (entities) => omitBy(entities.tokens, 'deleted'),
);

const selectCurrentToken = createSelector(
  selectTokens,
  selectAuthUser,
  (tokens, id) => tokens[id],
);

export default selectCurrentToken;
