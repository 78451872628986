import { createMuiTheme } from '@material-ui/core/styles';
import { defaultsDeep } from 'lodash';

import getThemePropsDefault from './getThemePropsDefault';

const fontFamily = '"freight-sans-pro",sans-serif';
const primaryMain = '#003b6d';
const secondaryMain = '#f37021';

const placeholder = {
  color: 'initial',
};

const getThemePropsLuma = (defaultProps = getThemePropsDefault(), theme = createMuiTheme(getThemePropsDefault())) =>
  defaultsDeep(
    {},
    {
      themeName: 'Luma Theme',
      typography: {
        fontFamily,
      },
      palette: {
        primary: {
          main: primaryMain,
        },
        secondary: {
          main: secondaryMain,
        },
        tertiary: theme.palette.augmentColor({
          main: primaryMain,
        }),
        background: {
          default: '#fafafa',
        },
      },
      mixins: {
        toolbar: {
          minHeight: theme.spacing.unit * 8,
          [theme.breakpoints.up('sm')]: {
            minHeight: theme.spacing.unit * 10,
          },
        },
      },
      overrides: {
        MuiInput: {
          input: {
            color: primaryMain,
            '&::-webkit-input-placeholder': placeholder,
            '&::-moz-placeholder': placeholder, // Firefox 19+
            '&:-ms-input-placeholder': placeholder, // IE 11
            '&::-ms-input-placeholder': placeholder, // Edge
          },
        },
        MuiFormLabel: {
          root: {
            color: 'rgba(0, 0, 0, 0.3)',
          },
        },
        MuiButton: {
          containedSecondary: {
            color: theme.palette.common.white,
            backgroundColor: secondaryMain,
          },
        },
        // Luma components
        LumaHeader: {
          root: {
            backgroundColor: theme.palette.common.white,
          },
          logo: {
            maxWidth: 140,
          },
        },
        LumaFeedbackPage: {
          root: {
            background: '#cde7ed',
          },
        },
        LumaRemindersReplyPage: {
          root: {
            background: '#cde7ed',
          },
        },
        LumaVerifyUserPage: {
          submitButton: {
            backgroundColor: secondaryMain,
          },
        },
        LumaSignupPage: {
          submitButton: {
            backgroundColor: secondaryMain,
          },
        },
      },
      props: {
        LumaApp: {
          pageTitle: 'Luma Health',
          favicon: './favicon-luma.ico',
        },
        LumaHeader: {
          logoSrc: 'https://s3-us-west-2.amazonaws.com/lumahealth/logo.png',
        },
      },
    },
    defaultProps,
  );

export default getThemePropsLuma;
