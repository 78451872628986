import { AxiosRequestConfig } from 'axios';
import { defaultsDeep, filter, includes, kebabCase, omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import providersResource from '@lib/resources/providersResource';

import { EntityID } from '../../../resources/types';
import FilterList, { FilterListProps } from '../FilterList';
import { resultKey, selectProvidersFromPage } from './selectors';

interface Props extends FilterListProps {
  itemsIds?: EntityID[];
  isReferringProvider?: boolean;
  isRenderingProvider?: boolean;
  fetchPageConfig?: AxiosRequestConfig;
}

const FilterListProviders: React.FC<Props> = ({ itemsIds, idsExcluded, fetchPageConfig, limit, ...other }) => {
  const providers = useSelector(providersResource.selectEntitiesObj);
  const dispatch = useDispatch();

  const shouldUseItemsFromProp = !!itemsIds;
  const itemsFromProp = filter(providers, ({ id }) => includes(itemsIds, id));
  const [searchTerm, setSearchTerm] = useState();
  const filteredItemsFromProp = searchTerm
    ? filter(itemsFromProp, (item) => includes(kebabCase(item.name), kebabCase(searchTerm)))
    : itemsFromProp;

  const itemsFromPageRequest = useSelector(selectProvidersFromPage);

  useEffect(() => {
    dispatch(
      providersResource.fetchBulk({
        ids: itemsIds || idsExcluded,
      }),
    );
  }, [dispatch, itemsIds, idsExcluded]);

  return (
    <FilterList
      itemsLabel="providers"
      allItems={providers}
      items={shouldUseItemsFromProp ? filteredItemsFromProp.slice(0, limit) : itemsFromPageRequest}
      idsExcluded={idsExcluded}
      onSearchChange={(page, limit, name) => {
        if (shouldUseItemsFromProp) {
          setSearchTerm(name);
        } else {
          dispatch(
            providersResource.fetchPage(
              defaultsDeep(
                {},
                {
                  params: { page, limit, name },
                  meta: { resultKey },
                },
                fetchPageConfig,
              ),
            ),
          );
        }
      }}
      limit={limit}
      {...omit(other, 'allItems', 'items', 'isReferringProvider', 'isRenderingProvider')}
    />
  );
};

export default FilterListProviders;
