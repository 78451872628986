import { schema } from 'normalizr';

import api from '../utils/axiosClient';

const url = '/settings';
const entitiesKey = 'settings';

// Schemas
const settingsSchema = new schema.Entity(
  entitiesKey,
  {},
  {
    idAttribute: (_, { setting, _id }) => setting || _id,
  },
);

// Methods
async function save({ id, data }: any) {
  return api.put(
    `${url}/${id}`,
    {
      settings: data,
    },
    {
      // @ts-ignore
      schema: { settings: settingsSchema },
    },
  );
}

export default {
  entitiesKey,
  settingsSchema,
  save,
};
