import moment from 'moment';

export function getDateFromInterval(interval) {
  if (interval) {
    if (interval > 0) {
      return moment()
        .subtract(interval - 1, 'days')
        .startOf('day')
        .toDate()
        .getTime();
    }

    return moment()
      .add(-interval - 1, 'days')
      .startOf('day')
      .toDate()
      .getTime();
  }
}

export function parseDate(value, format) {
  if (!value) return '';

  if (value.year) {
    const { year, month, day } = value;

    return moment(`${year}-${month}-${day}`, 'YYYY-MM-DD').format(format || 'LL');
  }

  return moment(value, 'YYYY-MM-DD hh:mm').format(format || 'LL');
}

export function dateMask(value, previousValue) {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    if (onlyNums.length === 2) {
      return `${onlyNums}/`;
    }

    if (onlyNums.length === 4) {
      return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/`;
    }
  }

  if (onlyNums.length <= 2) {
    return `${onlyNums}`;
  }

  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2)}`;
  }

  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(4, 8)}`;
}
