/*
 * Language switch messages
 *
 * This contains all the text for the LanguageSwitch component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  en: {
    id: 'app.components.LanguageSwitch.en',
    defaultMessage: 'English',
  },
  es: {
    id: 'app.components.LanguageSwitch.es',
    defaultMessage: 'Spanish',
  },
});
