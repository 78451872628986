import { createSelector } from 'reselect';

import { ResultPaginated } from '../reducers/createResultReducer';
import makeSelectResult from './makeSelectResult';

const makeSelectResultPage = (resultKey: string) =>
  createSelector(
    makeSelectResult(resultKey),
    (result) => result as ResultPaginated,
  );

export default makeSelectResultPage;
