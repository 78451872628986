import { random, forEach, replace } from 'lodash';
import {
  blueGrey,
  blue,
  brown,
  cyan,
  deepPurple,
  green,
  grey,
  indigo,
  orange,
  pink,
  purple,
  red,
  teal,
} from '@material-ui/core/colors';

export function getInitials(str) {
  if (!str) {
    return '';
  }
  const s = str.replace(/\W*(\w)\w*/g, '$1').toUpperCase();
  let initials = s.charAt(0);
  if (s.length > 1) {
    initials += s.charAt(s.length - 1);
  }
  return initials;
}

export function stringToColor(s) {
  const colors = [
    deepPurple[300],
    grey[700],
    blue[800],
    blueGrey[700],
    indigo[900],
    brown[900],
    green[800],
    red[400],
    deepPurple[800],
    red[700],
    purple[800],
    green[900],
    pink[900],
    grey[800],
    pink[500],
    blueGrey[900],
    cyan[700],
    deepPurple[500],
    brown[700],
    purple[400],
    brown[500],
    cyan[900],
    pink[700],
    red[900],
    brown[600],
    teal[600],
    purple[600],
    blue[600],
    blue[500],
    pink[800],
    indigo[500],
    indigo[800],
    indigo[700],
    blueGrey[600],
    red[800],
    orange[900],
    grey[600],
    green[600],
    purple[500],
    teal[800],
    teal[500],
    deepPurple[700],
    blue[700],
    teal[900],
    blueGrey[800],
    cyan[800],
    green[700],
    deepPurple[400],
    brown[800],
    purple[700],
    deepPurple[600],
    grey[900],
    purple[300],
    orange[800],
    indigo[600],
    blue[900],
    teal[700],
    pink[600],
  ];

  const firstChar = s && s.length ? s[0].toLowerCase() : '';
  const index = (firstChar.charCodeAt(0) - 97) % colors.length;

  return colors[index];
}

export function parseMessageContent(messageHtml) {
  let message = messageHtml;
  const links = {};
  // regexp to match valid url values
  const urlPattern =
    '((([A-Za-z]{3,9}:(?://)?)(?:[-;:&=+$,\\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\\w]+@)[A-Za-z0-9.-]+)(:[0-9]+)?((?:/[+~%/.\\w-_]*)?\\??(?:[-+=&;%@.\\w_]*)#?(?:[.!/\\\\w]*))?)';
  const urlRegexp = new RegExp(urlPattern, 'gm');
  // regexp to match valid url values with href attribute
  const linkRegexp = /<\s*a[^>]*>(.*?)<\s*\/\s*a>/g;

  // sanitize link tags to avoid double replace
  message = message.replace(linkRegexp, (match) => {
    const linkKey = `links_${random(999)}`;

    links[linkKey] = match;

    return `{{${linkKey}}}`;
  });

  message = message.replace(/</g, '&lt;').replace(/>/g, '&gt;');

  // wrap urls on clickable links
  message = message.replace(urlRegexp, (match) => {
    const result = match.replace(/[.]{1}$/, '');
    const url = result.indexOf('.') > 0 && !/^(https?)/.test(result) ? `http://${result}` : result;

    return `<a href="${url}">${result}</a>${match[match.length - 1] === '.' ? '.' : ''}`;
  });

  // add links url again
  forEach(links, (value, key) => {
    message = message.replace(`{{${key}}}`, value);
  });

  return message
    .replace(/(a href=)/g, 'a target="_blank" href=')
    .replace(/$/g, '<br />')
    .replace(/&lt;br&gt;/g, '<br />')
    .replace(/&lt;br \/&gt;/g, '<br />');
}

export function capitalizeFirstLetter(s) {
  if (!s) {
    return '';
  }
  return (s.charAt(0).toUpperCase() + s.slice(1)).replace(/-|_/, ' ');
}

export function lowercaseFirstLetter(s) {
  if (!s) {
    return '';
  }
  return (s.charAt(0).toLowerCase() + s.slice(1)).replace(/-|_/, ' ');
}

export function updateText(text, replacements) {
  let res = text;
  forEach(replacements, ({ prev, next }) => {
    res = replace(res, prev, next);
  });
  return res;
}

export function isHTML(str) {
  // https://stackoverflow.com/a/15458968
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
}
