import createResource from './createResource';
import { Reminder } from './types';

const remindersResource = createResource<Reminder>({
  key: 'reminders',
  url: '/reminders',
  fetchListUrl: '/reminders/crudList',
  fetchPageUrl: '/reminders/crudList',
});

export default remindersResource;
