import { eventChannel, delay } from 'redux-saga';
import { fork, take, call, put, takeLatest, cancel } from 'redux-saga/effects';
import io from 'socket.io-client';
import forEach from 'lodash/forEach';

// TODO refactor
import { SIGNOUT_USER } from 'containers/LoginPage/constants';
import { onSocketMessage, onSocketTypingStart, onSocketTypingStop } from './actions';
import { REGISTER_SOCKET, EMIT_SOCKET_TYPING, ON_SOCKET_TYPING_START } from './constants';

function onSocketConnect() {
  const socket = io(process.env.REACT_APP_API_SOCKET);
  return new Promise((resolve) => {
    socket.on('connect', () => {
      console.log('Socket: connected'); // eslint-disable-line no-console
      resolve(socket);
    });
  });
}

function onSocketSubscribe(socket) {
  return eventChannel((dispatch) => {
    function subscribeEvents(socketEvents) {
      forEach(socketEvents, (callback, name) => {
        socket.on(name, (data) => {
          // eslint-disable-next-line no-console
          console.log('Socket received:', name, data);
          dispatch(callback(data));
        });
      });
    }

    // subscribe events
    subscribeEvents({
      'chat:server:outbound:message': onSocketMessage,
      'chat:client:inbound:message': onSocketMessage,
      'chat:server:typing:start': onSocketTypingStart,
      'chat:server:typing:stop': onSocketTypingStop,
    });

    // disconnect event
    socket.on('disconnect', (e) => {
      console.log('Socket: disconnected', e); // eslint-disable-line no-console
    });
    return () => {};
  });
}

function* watchSocketRead(socket) {
  const channel = yield call(onSocketSubscribe, socket);
  while (true) {
    // eslint-disable-line no-constant-condition
    const action = yield take(channel);
    yield put(action);
  }
}

function* watchSocketWrite(socket) {
  while (true) {
    // eslint-disable-line no-constant-condition
    const { payload } = yield take(EMIT_SOCKET_TYPING);
    const name = 'chat:client:typing:start';
    console.log('Socket emit:', name, payload); // eslint-disable-line no-console
    socket.emit(name, payload);
  }
}

function* watchSocketIO(socket) {
  yield fork(watchSocketRead, socket);
  yield fork(watchSocketWrite, socket);
}

function* watchSocket() {
  while (true) {
    const { user } = yield take(REGISTER_SOCKET);
    // eslint-disable-next-line no-console
    console.log(`Socket: connecting to ${process.env.REACT_APP_API_SOCKET} with user ${user._id}`);
    const socket = yield call(onSocketConnect);
    socket.emit('register', {
      id: user._id,
      app: 'web-app',
    });
    const task = yield fork(watchSocketIO, socket);
    yield take(SIGNOUT_USER);
    yield cancel(task);
  }
}

export function* onWatchSocketTypingStart(action) {
  // hide typing indicator after 5 secs
  yield call(delay, 5000);
  const { data } = action;
  yield put(onSocketTypingStop(data));
}

export function* watchOnSocketTypingStart() {
  yield takeLatest(ON_SOCKET_TYPING_START, onWatchSocketTypingStart);
}

export default function* appSaga() {
  yield fork(watchSocket);
  yield fork(watchOnSocketTypingStart);
}
