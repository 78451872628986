import { createMuiTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { defaultsDeep } from 'lodash';

import getThemePropsDefault from './getThemePropsDefault';

// const fontFamily = 'HelveticaNeue-Light';
const fontFamily = 'helvetica';
const primaryMain = '#052049';
const secondaryMain = '#178CCB';
const secondaryLight = fade(secondaryMain, 0.1);
const backgroundDefault = secondaryLight;

const getThemePropsUCSF = (defaultProps = getThemePropsDefault(), theme = createMuiTheme(getThemePropsDefault())) =>
  defaultsDeep(
    {},
    {
      themeName: 'UCSF Theme',
      typography: {
        fontFamily,
        h4: {
          fontWeight: 300,
          fontSize: 29,
          [theme.breakpoints.down('md')]: {
            fontSize: 20,
          },
        },
      },
      palette: {
        primary: {
          main: primaryMain,
        },
        secondary: {
          main: secondaryMain,
          light: secondaryLight,
        },
        tertiary: theme.palette.augmentColor({
          main: secondaryMain,
        }),
        background: {
          default: backgroundDefault,
        },
        text: {
          primary: primaryMain,
        },
        error: {
          main: '#e14256',
        },
      },
      mixins: {
        toolbar: {
          minHeight: theme.spacing.unit * 8,
          [theme.breakpoints.up('sm')]: {
            minHeight: theme.spacing.unit * 10,
          },
        },
      },
      overrides: {
        // Toolbar
        MuiToolbar: {
          gutters: {
            [theme.breakpoints.up('sm')]: {
              paddingLeft: theme.spacing.unit * 5,
              paddingRight: theme.spacing.unit * 5,
            },
          },
        },
        // Button
        MuiButton: {
          root: {
            borderRadius: 0,
            textTransform: 'none',
            boxShadow: 'none !important',
          },
          contained: {
            backgroundColor: theme.palette.common.white,
          },
          containedPrimary: {
            '&:hover': {
              color: theme.palette.common.white,
            },
          },
          sizeLarge: {
            padding: `${theme.spacing.unit * 1.25}px ${theme.spacing.unit * 2.5}px`,
            fontSize: 17,
          },
          outlinedSecondary: {
            color: 'inherit',
          },
        },
        // TextField
        MuiFormLabel: {
          root: {
            color: `${primaryMain} !important`,
            transform: 'translate(0, 1.5px) scale(0.75) !important',
          },
        },
        MuiInputBase: {
          root: {
            backgroundColor: theme.palette.common.white,
          },
        },
        MuiInput: {
          underline: {
            '&:before, &:after': {
              borderBottom: 'none !important',
            },
          },
          input: {
            backgroundColor: theme.palette.common.white,
            padding: `${theme.spacing.unit * 1.5}px ${theme.spacing.unit * 2}px`,
          },
          formControl: {
            'label + &': {
              marginTop: theme.spacing.unit * 2.5,
            },
          },
        },
        MuiOutlinedInput: {
          notchedOutline: {
            border: 'none',
          },
        },
        MuiInputAdornment: {
          positionStart: {
            marginRight: 0,
            marginLeft: theme.spacing.unit * 2,
          },
        },
        // Paper
        MuiPaper: {
          rounded: {
            borderRadius: 0,
          },
          elevation1: {
            boxShadow: 'none',
          },
        },
        // LinearProgress
        MuiLinearProgress: {
          root: {
            height: theme.spacing.unit * 1,
            borderRadius: (theme.spacing.unit * 1) / 2,
          },
          colorSecondary: {
            backgroundColor: secondaryLight,
          },
        },
        // Luma components
        LumaHeader: {
          root: {
            backgroundColor: theme.palette.common.white,
          },
          logo: {
            maxWidth: 260,
            margin: -40,
          },
        },
        LumaFeedbackPage: {
          root: {
            background: backgroundDefault,
          },
          paper: {
            boxShadow: 'none',
          },
        },
        LumaRemindersReplyPage: {
          root: {
            background: backgroundDefault,
          },
          paper: {
            boxShadow: 'none',
          },
        },
        LumaOffersReplyPage: {
          root: {
            background: backgroundDefault,
          },
          paper: {
            boxShadow: 'none',
          },
        },
        LumaVerifyUserPage: {
          submitButton: {
            backgroundColor: primaryMain,
          },
        },
      },
      props: {
        LumaApp: {
          pageTitle: 'UCSF Medical Center',
          favicon: './favicon-ucsf.ico',
        },
        // Luma components
        LumaHeader: {
          logoSrc: 'https://identity.ucsf.edu/sites/g/files/tkssra266/f/wysiwyg/vs3_bl_a_ucsf_health%402x.png',
        },
        LumaFeedbackPage: {
          showPoweredByLumaLogo: false,
        },
        LumaRemindersReplyPage: {
          showPoweredByLumaLogo: false,
        },
        LumaOffersReplyPage: {
          showPoweredByLumaLogo: false,
        },
      },
    },
    defaultProps,
  );

export default getThemePropsUCSF;
