import { has } from 'lodash';

import { PREFIX, SUFFIX_REQUEST, SUFFIX_SUCCESS, SUFFIX_ERROR } from '../actions/actionTypes';

const initialState = false;

export default function createLoadingReducer() {
  return (state = initialState, action) => {
    const { type, skipSpinner } = action;

    // Handle legacy actions
    if (has(action, 'loading')) {
      return action.loading;
    }
    if (/_SUCCESS|_ERROR/.test(action.type)) {
      return false;
    }
    if (type.indexOf(PREFIX) === 0) {
      if (type.indexOf(SUFFIX_REQUEST) > 0 && !skipSpinner) {
        return true;
      }
      if (type.indexOf(SUFFIX_SUCCESS) > 0 || type.indexOf(SUFFIX_ERROR) > 0) {
        return false;
      }
    }
    // TODO: Handle typesafe actions
    return state;
  };
}
