import { compact, map } from 'lodash';
import { createSelector } from 'reselect';

import { appointmentTypesResource } from '@lib/resources';
import { makeSelectResultIds } from '@lib/selectors';

export const resultKey = '@lib/core/Filters/FilterListAppointmentTypes';

export const selectAppointmentTypes = createSelector(
  makeSelectResultIds(resultKey),
  appointmentTypesResource.selectEntitiesObj,
  (ids, obj) => compact(map(ids, (id) => obj[id])),
);
