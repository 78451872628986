/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import globalReducer from 'containers/App/reducer';
import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import { AnyAction, combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form/immutable';

import { createLoadingReducer, createMessageReducer, createResultReducer, createRouteReducer } from '@lib/reducers';
import {
  appointmentsResource,
  appointmentTypesResource,
  availabilitiesResource,
  customWebStylesResource,
  facilitiesResource,
  patientFormsResource,
  patientFormTemplatesResource,
  patientsResource,
  providersResource,
  remindersResource,
  usersResource,
} from '@lib/resources';
import fileUploadsResource from '@lib/resources/fileUploadsResource';
import messageTemplatesResource from '@lib/resources/messageTemplatesResource';
import offersResource from '@lib/resources/offersResource';
import settingsResource from '@lib/resources/settingsResource';
import tokensResource from '@lib/resources/tokensResource';
import waitlistsResource from '@lib/resources/waitlistsResource';

import createAuthReducer from './createAuthReducer';
import createEntitiesReducer from './createEntitiesReducer';

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */

// Initial routing state
const routeInitialState = fromJS({ locationBeforeTransitions: null });

/**
 * Merge route into the global application state
 */
function routeReducer(state = routeInitialState, action: AnyAction) {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return state.merge({ locationBeforeTransitions: action.payload });
    default:
      return state;
  }
}

export default function createReducer() {
  return {
    normalizr: combineReducers({
      auth: createAuthReducer(),
      loading: createLoadingReducer(),
      message: createMessageReducer(),
      route: createRouteReducer(),
      entities: createEntitiesReducer({
        [appointmentsResource.key]: {},
        [appointmentTypesResource.key]: {},
        [availabilitiesResource.key]: {},
        [customWebStylesResource.entitiesKey]: {},
        [facilitiesResource.key]: {},
        [fileUploadsResource.key]: {},
        [messageTemplatesResource.key]: {},
        [offersResource.key]: {},
        [patientFormsResource.key]: {},
        [patientsResource.key]: {},
        [patientFormTemplatesResource.key]: {},
        [providersResource.key]: {},
        [remindersResource.key]: {},
        [settingsResource.entitiesKey]: {},
        [tokensResource.entitiesKey]: {},
        [usersResource.key]: {},
        [waitlistsResource.key]: {},
        // TODO: refactor `messageIdOverride`
        messageIdOverride: '',
      }),
      result: createResultReducer(),
    }),
    global: globalReducer,
    form: reduxFormReducer,
    // TODO: remove after upgrading react-route
    route: routeReducer,
  };
}
