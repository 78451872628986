import { createSelector } from 'reselect';

import selectAuth from './selectAuth';

const selectAppReady = createSelector(
  selectAuth,
  (auth) => auth.ready,
);

export default selectAppReady;
