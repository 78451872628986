import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { camelizeKeys } from 'humps';
import { get, includes, isArray, isFunction, map, random } from 'lodash';
import { normalize, NormalizedSchema, Schema } from 'normalizr';
import { call, put } from 'redux-saga/effects';
import { AsyncActionCreator, getType } from 'typesafe-actions';

import { Resource } from '../resources/createResource';
import axiosClientService from '../services/axiosClientService';
import fetchLookups, { sagaFetchLookups } from './fetchLookups';
import showMessage from './showMessage';

export interface Meta {
  callbackError?: (response?: AxiosError) => void;
  callbackSuccess?: (response?: Response) => void;
  camelizeResponseKeys?: boolean;
  forceFetch?: boolean;
  ignoreErrors?: boolean | number[];
  includeLookups?: boolean | string[];
  resolveLookups?: boolean;
  isMock?: boolean;
  messageError?: string | ((response: AxiosError) => string);
  messageSuccess?: string | ((response: Response) => string);
  resultKey?: string;
  skipLoading?: boolean;
  skipMessages?: boolean;
  normalize?: (data: any, schema?: Schema) => NormalizedSchema<any, number | number[]>;
  axiosClientName?: string;
}

export type RequestConfig = AxiosRequestConfig & {
  meta?: Meta;
};

export interface Response extends AxiosResponse<any> {
  normalized: NormalizedSchema<any, number | number[]>;
  meta: Meta;
}

export interface Error {
  error: AxiosError;
}

export const getEntitiesFromResponse = (response: Response, key: string) => {
  const result = response.data.result;
  const entities = response.data.entities[key] || {};
  return isArray(result) ? map(result, (id) => entities[id]) : entities[result];
};

const createFakeAxiosResponse = (config: AxiosRequestConfig = {}, resource?: Resource) => {
  const idAttribute = (resource && resource.idAttribute) || '_id';
  const data = {
    ...config.data,
    [idAttribute]: get(config, `data.${idAttribute}`) || get(config, idAttribute, `FAKE#${random(99999)}`),
  };
  return {
    data: {
      ...data,
      deleted: config.method === 'delete' ? 1 : undefined,
    },
    status: 200,
    statusText: '',
    headers: '',
    config,
  };
};

interface Config extends RequestConfig {
  schema?: Schema;
}

interface Args {
  action?:
    | AsyncActionCreator<[string, any], [string, Response], [string, Error]>
    | AsyncActionCreator<[string, any], [string, Response], [string, Error], [string, any]>;
  resource?: Resource;
  config: Config;
}

const handleNormalize = (data: any, meta: Meta, schema?: Schema) => {
  let normalized;
  if (meta.normalize) {
    normalized = meta.normalize(data, schema);
  } else if (schema) {
    normalized = normalize(data, schema);
  } else {
    normalized = { entities: {}, result: -1 };
  }
  return normalized;
};

export default function* api(args: Args) {
  const {
    action,
    resource,
    config: { schema, meta = {}, ...config },
  } = args;
  try {
    // NOTE: Each project will need to make sure that the client is instantiated prior to using it.
    const axiosClient = axiosClientService.get(meta.axiosClientName);

    // Make the call
    const res = meta.isMock
      ? createFakeAxiosResponse(config, resource)
      : ((yield call(axiosClient.request, config)) as AxiosResponse);

    // Call succeed > handle `meta`
    let data = res.data;

    // Camelize response keys
    if (meta.camelizeResponseKeys) {
      // prevent conversion of keys containing only uppercase letters or numbers
      // https://github.com/domchristie/humps#converting-object-keys
      data = camelizeKeys(data, (key: string, convert: any) => (/^[A-Z0-9_]+$/.test(key) ? key : convert(key)));
    }

    let normalized = handleNormalize(data, meta, schema);

    // Fetch lookups
    if (meta.includeLookups) {
      const entities = get(normalized, 'entities');
      if (entities && resource) {
        const fetchLookupsPayload = {
          resource,
          entities,
          keys: isArray(meta.includeLookups) ? meta.includeLookups : undefined,
        };
        if (meta.resolveLookups) {
          // TODO use `putResolve` once we upgrade `redux-saga`
          yield sagaFetchLookups({
            type: getType(fetchLookups),
            payload: {
              ...fetchLookupsPayload,
              resolve: true,
            },
          });
          normalized = handleNormalize(data, meta, schema);
        } else {
          yield put(fetchLookups(fetchLookupsPayload));
        }
      }
    }

    const response: Response = {
      ...res,
      data,
      normalized,
      meta,
      config,
    };

    // Dispatch `success` action
    if (action) {
      yield put(action.success(response));
    }

    // Show message success
    if (!meta.skipMessages && meta.messageSuccess) {
      const messageSuccess = isFunction(meta.messageSuccess) ? meta.messageSuccess(response) : meta.messageSuccess;
      yield put(showMessage(messageSuccess, 'success'));
    }

    // Callback success
    if (meta.callbackSuccess) {
      meta.callbackSuccess(response);
    }
    return response;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);

    const error = err as AxiosError;
    const ignoreError =
      (isArray(meta.ignoreErrors) && includes(meta.ignoreErrors, get(error, 'response.status'))) || meta.ignoreErrors;
    if (!ignoreError) {
      if (action) {
        // Dispatch `failure` action
        yield put(action.failure({ error }));
      }

      // Show message error
      if (!meta.skipMessages) {
        let messageError;
        if (meta.messageError) {
          messageError = isFunction(meta.messageError) ? meta.messageError(error) : meta.messageError;
        } else {
          messageError = (error.response && error.response.statusText) || 'Unknown server error';
        }
        yield put(showMessage(messageError, 'error'));
      }
    }

    // Callback error
    if (meta.callbackError) {
      meta.callbackError(error);
    }
    return error;
  }
}
