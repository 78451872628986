/**
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import TranslateBanner from 'components/TranslateBanner';
import Header from 'containers/Header';
import { appLocales } from 'i18n/translationMessages';
import { get, includes } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { browserHistory, RouteComponentProps } from 'react-router';

import { changeLocation } from '@lib/actions';
import { DISPATCH_CHANGE_LOCALE, DISPATCH_INIT_APP } from '@lib/actions/actionTypes';
import { HeaderGap, InternetExplorerAlert, LoadingProgress, Snackbar } from '@lib/core';
import { getTheme } from '@lib/core/styles/getTheme';
import { useCheckSessionExpired, usePrevious } from '@lib/hooks';
import { selectAppReady, selectCurrentUser, selectCustomThemeProps, selectLocale, selectMessage } from '@lib/selectors';
import storage from '@lib/services/storageService';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createStyles, MuiThemeProvider, withStyles } from '@material-ui/core/styles';

import { LS_DO_NOT_SHOW_LANGUAGE_BANNER } from './constants';
import { makeSelectError, makeSelectLoading } from './selectors';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflow: 'auto',
    },
  });

interface Props extends RouteComponentProps<any, any> {
  classes: any;
}

const App: React.FC<Props> = ({ classes, children, location }) => {
  const dispatch = useDispatch();

  const appReady = useSelector(selectAppReady);
  const currentUser = useSelector(selectCurrentUser);
  const locale = useSelector(selectLocale);
  const themeProps = useSelector(selectCustomThemeProps);
  const message = useSelector(selectMessage);
  const loading = useSelector(makeSelectLoading());
  const error = useSelector(makeSelectError());

  const pathname = location.pathname;
  const prevPathname = usePrevious(pathname);

  useCheckSessionExpired();

  const loginRequired = useCallback(() => !!currentUser && currentUser.$isWidgetUser && /main/.test(pathname), [
    currentUser,
    pathname,
  ]);

  useEffect(() => {
    if (!appReady) {
      dispatch(changeLocation(location));
      dispatch({ type: DISPATCH_INIT_APP, ...location });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // NOTE: Replace effect by `return <Redirect />` tag once we ugprade to `react-route` v4
  useEffect(() => {
    if (appReady && pathname !== prevPathname && loginRequired()) {
      browserHistory.push(`/login?returnUrl=${pathname}`);
    }
  }, [appReady, pathname, prevPathname, loginRequired]);

  if (!appReady || loginRequired()) {
    return <div />;
  }

  const muiTheme = getTheme(themeProps);
  const language = (currentUser && currentUser.language) || 'en';
  const showLanguageBanner =
    !storage.get(LS_DO_NOT_SHOW_LANGUAGE_BANNER) && locale !== language && includes(appLocales, language);
  const lumaAppThemeProps = get(muiTheme, 'props.LumaApp');

  return (
    <MuiThemeProvider theme={muiTheme}>
      <CssBaseline />
      {/*
      // @ts-ignore */}
      <Helmet
        titleTemplate="%s - Luma Health"
        defaultTitle={lumaAppThemeProps.pageTitle}
        link={[
          {
            rel: 'icon',
            type: 'image/png',
            href: lumaAppThemeProps.favicon,
          },
        ]}
        meta={[{ name: 'description', content: 'A React.js application for Luma Health' }]}
      />
      <Header />
      <div className={classes.root}>
        <HeaderGap />
        <InternetExplorerAlert />
        <TranslateBanner
          language={language}
          onChange={(l: string) => dispatch({ type: DISPATCH_CHANGE_LOCALE, locale: l })}
          show={showLanguageBanner}
        />
        {React.Children.toArray(children)}
        <LoadingProgress loading={loading} />
        {/* TODO: reuse message reducer to show errors */}
        <Snackbar content={error || message} variant={message.variant || 'error'} />
      </div>
    </MuiThemeProvider>
  );
};

export default withStyles(styles, { name: 'LumaApp' })(App);
