import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';
import { get } from 'lodash';

const styles = (theme) => {
  const {
    palette: {
      error: { main },
    },
  } = theme;

  return {
    asterisk: {
      color: main,
    },
    inputSmall: {
      height: '35px !important',
    },
  };
};

class TextField extends React.Component {
  render() {
    const { classes, theme, InputLabelProps, InputProps, label, showRequiredMark, size, ...other } = this.props;
    const disableUnderline = get(theme, 'custom.MuiInput.disableUnderline');
    return (
      <MuiTextField
        InputLabelProps={{
          shrink: true,
          ...InputLabelProps,
        }}
        InputProps={{
          classes: {
            input: size === 'small' ? classes.inputSmall : undefined,
          },
          disableUnderline,
          ...InputProps,
        }}
        label={
          showRequiredMark && label ? (
            <div>
              {label}
              <span className={classes.asterisk}>*</span>
            </div>
          ) : (
            label
          )
        }
        fullWidth
        {...other}
      />
    );
  }
}

TextField.propTypes = {
  classes: PropTypes.object.isRequired,
  size: PropTypes.oneOf(['default', 'small']),
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showRequiredMark: PropTypes.bool,
  theme: PropTypes.object.isRequired,
};

TextField.defaultProps = {
  size: 'default',
};

export default withStyles(styles, { withTheme: true })(TextField);
