import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Tabs, Tab } from 'material-ui/Tabs';
import map from 'lodash/map';

import FormattedSimpleMessage from '@lib/core/FormattedSimpleMessage';
import { getThemeLegacyDark } from '@lib/core/styles/getTheme';
import messages from 'containers/Main/messages';

const tabsTheme = getThemeLegacyDark();

const styles = {
  tab: {
    margin: '0 2px',
    flex: 1,
  },
};

const TabsStyled = styled(Tabs)`
  > div {
    position: relative;
    display: flex !important;
  }
`;

class MainTabs extends React.PureComponent {
  constructor(props) {
    super(props);
    this.renderTab = this.renderTab.bind(this);
  }

  render() {
    const { tabs, tab, onTabChange } = this.props;
    return (
      <MuiThemeProvider muiTheme={tabsTheme}>
        <TabsStyled
          tabTemplateStyle={{ display: 'flex' }}
          inkBarStyle={{ position: 'absolute', top: 0, marginTop: 0 }}
          value={tab}
          onChange={onTabChange}
        >
          {map(tabs, this.renderTab)}
        </TabsStyled>
      </MuiThemeProvider>
    );
  }

  renderTab(key) {
    const { tab } = this.props;
    const buttonStyle = {
      ...styles.tab,
      backgroundColor: key === tab ? 'white' : '#edf5f8',
    };
    return (
      <Tab
        key={key}
        style={{ zIndex: 0 }}
        buttonStyle={buttonStyle}
        value={key}
        label={<FormattedSimpleMessage {...messages[key]} />}
      />
    );
  }
}

MainTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  tab: PropTypes.string,
  onTabChange: PropTypes.func.isRequired,
};

export default MainTabs;
