import { schema } from 'normalizr';

import appointmentsResource from './appointmentsResource';
import appointmentTypesResource from './appointmentTypesResource';
import availabilitiesResource from './availabilitiesResource';
import createResource from './createResource';
import facilitiesResource from './facilitiesResource';
import patientsResource from './patientsResource';
import providersResource from './providersResource';
import { Offer } from './types';
import usersResource from './usersResource';
import waitlistsResource from './waitlistsResource';

const offersResource = createResource<Offer>({
  key: 'offers',
  url: '/offers',
  fetchListUrl: '/offers/crudList',
  lookups: {
    user: usersResource,
    provider: providersResource,
    facility: facilitiesResource,
    approvedBy: usersResource,
    declinedBy: usersResource,
    availability: availabilitiesResource,
    usedForAppointment: appointmentsResource,
    patientFound: patientsResource,
    waitlists: {
      ...waitlistsResource,
      schema: new schema.Array(waitlistsResource.schema),
    },
    appointmentType: {
      ...appointmentTypesResource,
      schema: new schema.Array(appointmentTypesResource.schema),
    },
  },
});

export default offersResource;
