import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';

import TextField from '../TextField';

const styles = () => ({
  closeIcon: {
    cursor: 'pointer',
  },
});

class TextFieldSearch extends PureComponent {
  render() {
    const { classes, size, value, onChange, ...other } = this.props;
    return (
      <TextField
        size={size}
        value={value}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon fontSize={size}>search</Icon>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {!!value && (
                <Icon className={classes.closeIcon} fontSize={size} onClick={() => onChange('')}>
                  close
                </Icon>
              )}
            </InputAdornment>
          ),
          ...other.InputProps,
        }}
        onChange={(event) => onChange(event.target.value)}
        {...omit(other, 'InputProps')}
      />
    );
  }
}

TextFieldSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  size: PropTypes.oneOf(['default', 'small']),
  value: PropTypes.string,
  onChange: PropTypes.func,
};

TextFieldSearch.defaultProps = {
  size: 'default',
  value: undefined,
  onChange: undefined,
};

export default withStyles(styles)(TextFieldSearch);
