import { createSelector } from 'reselect';
import { sortBy, last } from 'lodash';

import selectCustomWebStylesObj from './selectCustomWebStylesObj';

const selectCustomWebStylesLast = createSelector(
  selectCustomWebStylesObj,
  (obj) => {
    const array = sortBy(obj, 'createdAt');
    return last(array);
  },
);

export default selectCustomWebStylesLast;
