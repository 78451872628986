import { call } from 'redux-saga/effects';

import { VALIDATE_TOKEN } from '../actions/actionTypes';
import tokensResource from '../resources/tokensResource';

import sagaFetch from './sagaFetch';

// bind resource generators
const validateToken = sagaFetch.bind(null, VALIDATE_TOKEN, tokensResource.validateToken);

export default function* sagaValidateToken(action) {
  const { token } = action;
  return yield call(validateToken, { token });
}
