import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { find, map, omit } from 'lodash';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import PlacesAutocomplete from 'react-places-autocomplete';

const styles = (theme) => {
  const {
    custom: {
      palette: { colorDarkModerateBlue },
    },
    palette: {
      error: { main },
    },
  } = theme;

  return {
    asterisk: {
      color: main,
    },
    externalForm: {},
    input: {
      '&::placeholder': {
        color: `${colorDarkModerateBlue} !important`,
      },
    },
    label: {
      '&$externalForm': {
        color: `${colorDarkModerateBlue} !important`,
      },
    },
    leftIcon: {
      fontSize: 14,
    },
    list: {
      padding: 0,
    },
    underline: {
      '&$externalForm': {
        '&:before': {
          borderBottom: `1px solid ${colorDarkModerateBlue} !important`,
        },
      },
    },
  };
};

const ADDRESS_FIELDS = {
  COMPONENTS: 'address_components',
  COUNTRY: 'country',
  STATE: 'administrative_area_level_1',
  ZIP_CODE: 'postal_code',
  CITY: 'locality',
  AREA: 'administrative_area_level_2',
  ROUTE: 'route',
  STREET_NUMBER: 'street_number',
};

const searchOptions = {
  componentRestrictions: {
    country: 'us',
  },
  types: ['geocode'],
};

class AddressAutocomplete extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      value: props.value || '',
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.initialValue) this.setState({ value: '' });
  }

  getAddressData = (place, type) => {
    const res = find(place[ADDRESS_FIELDS.COMPONENTS], (data) => data.types[0] === type);
    return res ? res.long_name : null;
  };

  handleBlur = (event) => {
    const { onBlur } = this.props;

    this.setState({ focused: false });

    if (onBlur) onBlur(event);
  };

  handleChange = (value) => {
    this.setState({ value });

    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };

  handleFocus = (event) => {
    const { onFocus } = this.props;

    this.setState({ focused: true });

    if (onFocus) onFocus(event);
  };

  handleSelect = (_, placeId) => {
    const { onSelect } = this.props;
    // TODO: component to FC + TS + use hook `usePlacesService`
    new window.google.maps.places.PlacesService(document.createElement('div')).getDetails(
      { placeId },
      (place, status) => {
        const value = place.formatted_address;
        this.setState({ value });
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const street = this.getAddressData(place, ADDRESS_FIELDS.ROUTE);
          const streetNumber = this.getAddressData(place, ADDRESS_FIELDS.STREET_NUMBER);
          const values = {
            country: this.getAddressData(place, ADDRESS_FIELDS.COUNTRY),
            state: this.getAddressData(place, ADDRESS_FIELDS.STATE),
            postcode: this.getAddressData(place, ADDRESS_FIELDS.ZIP_CODE),
            city: this.getAddressData(place, ADDRESS_FIELDS.CITY) || this.getAddressData(place, ADDRESS_FIELDS.AREA),
            address: streetNumber ? `${streetNumber} ${street}` : street,
            value,
          };
          onSelect(place, values);
        }
      },
    );
  };

  renderLabel() {
    const { classes, label, showRequiredMark } = this.props;

    if (!label) return false;

    if (showRequiredMark) {
      return (
        <div>
          {label}
          <span className={classes.asterisk}>*</span>
        </div>
      );
    }

    return label;
  }

  render() {
    const {
      classes,
      className,
      externalForm,
      fullWidth,
      onChange,
      onSelect,
      placeholder,
      shrink,
      ...other
    } = this.props;
    const { focused, value } = this.state;
    return (
      <PlacesAutocomplete
        searchOptions={searchOptions}
        value={value}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          const inputProps = getInputProps({
            className: classes.textField,
            fullWidth: fullWidth !== undefined ? fullWidth : true,
            label: this.renderLabel(),
            placeholder,
            autoComplete: 'nope',
            InputProps: {
              onBlur: this.handleBlur,
              onFocus: this.handleFocus,
              disableUnderline: false,
              classes: {
                input: classnames(classes.input, { [classes.externalForm]: externalForm }),
                underline: classnames(classes.underline, { [classes.externalForm]: externalForm }),
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={classes.leftIcon} />
                </InputAdornment>
              ),
            },
            InputLabelProps: {
              classes: {
                root: classnames(classes.label, { [classes.externalForm]: externalForm }),
              },
              shrink: Boolean(value || focused || placeholder || shrink),
            },
            ...omit(other, ['externalForm', 'initialValue', 'label', 'showRequiredMark']),
          });

          return (
            <div className={className}>
              <TextField {...inputProps} value={value} />
              <Paper>
                <List component="nav" className={classes.list}>
                  {loading && (
                    <ListItem>
                      <ListItemText primary="Loading..." />
                    </ListItem>
                  )}
                  {map(suggestions, (suggestion) => (
                    <ListItem
                      {...getSuggestionItemProps(suggestion, {
                        button: true,
                      })}
                    >
                      <ListItemText primary={suggestion.description} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </div>
          );
        }}
      </PlacesAutocomplete>
    );
  }
}

AddressAutocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  externalForm: PropTypes.bool,
  fullWidth: PropTypes.bool,
  shrink: PropTypes.bool,
  initialValue: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  showRequiredMark: PropTypes.bool,
};

AddressAutocomplete.defaultProps = {
  className: undefined,
  externalForm: false,
  initialValue: '',
  onChange: undefined,
};

export default withStyles(styles)(AddressAutocomplete);
