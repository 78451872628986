import { get, isEmpty, isEqual, isString } from 'lodash';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import SnackbarMaterial from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';

import { usePrevious } from '../../hooks';
import SnackbarContentWrapper, { SnackbarContentProps } from './SnackbarContentWrapper';

const styles = {
  root: {
    zIndex: 1501,
  },
};

interface SnackbarMessage {
  message: string;
  key: number;
}

interface Props {
  classes: any;
  content: any;
  variant: SnackbarContentProps['variant'];
}

const Snackbar: React.FC<Props> = ({ classes, content, variant = 'info' }) => {
  const { formatMessage } = useIntl();
  const queueRef = React.useRef<SnackbarMessage[]>([]);

  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = React.useState<SnackbarMessage | undefined>(undefined);

  const prevContent = usePrevious(content);

  const processQueue = () => {
    if (queueRef.current.length > 0) {
      setMessageInfo(queueRef.current.shift());
      setOpen(true);
    }
  };

  useEffect(() => {
    if (content && !isEmpty(content) && !isEqual(content, prevContent)) {
      let message = get(content, 'data', content);
      if (message && message.messageIntl) {
        message = formatMessage(message.messageIntl);
      } else if (message && !isString(message)) {
        message = message.statusText || get(message, 'message.value') || message.message || 'Something went wrong';
      }
      if (message) {
        queueRef.current.push({
          message,
          key: new Date().getTime(),
        });

        if (open) {
          // immediately begin dismissing current message
          // to start showing new one
          setOpen(false);
        } else {
          processQueue();
        }
      }
    }
  }, [open, content, prevContent, formatMessage]);

  const handleClose = (event: SyntheticEvent | MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleExited = () => {
    processQueue();
  };
  return (
    <SnackbarMaterial
      className={classes.root}
      key={messageInfo ? messageInfo.key : undefined}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      onExited={handleExited}
    >
      <SnackbarContentWrapper
        onClose={handleClose}
        variant={variant}
        message={messageInfo ? messageInfo.message : ''}
      />
    </SnackbarMaterial>
  );
};

export default withStyles(styles)(Snackbar);
