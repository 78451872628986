import { defineMessages } from 'react-intl';

export default defineMessages({
  search: {
    id: '@lib.core.Filters.search',
    defaultMessage: 'Search',
  },
  clearSelections: {
    id: '@lib.core.Filters.clearSelections',
    defaultMessage: 'Clear selections',
  },
  clearExcluded: {
    id: '@lib.core.Filters.clearExcluded',
    defaultMessage: 'Clear excluded',
  },
});
