import { createSelector } from 'reselect';

import selectEntities from './selectEntities';

const selectMessageIdOverride = createSelector(
  selectEntities,
  (entities) => entities.messageIdOverride,
);

export default selectMessageIdOverride;
