import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import fetch, { sagaFetch } from './fetch';
import fetchBulk, { sagaFetchBulk } from './fetchBulk';
import fetchList, { sagaFetchList } from './fetchList';
import fetchLookups, { sagaFetchLookups } from './fetchLookups';
import fetchPage, { sagaFetchPage } from './fetchPage';
import remove, { sagaRemove } from './remove';
import request, { sagaRequest } from './request';
import sagaLocationChange from './sagaLocationChange';
import save, { sagaSave } from './save';

export default function* saga() {
  yield takeEvery(getType(fetch.request), sagaFetch);
  yield takeEvery(getType(fetchBulk.request), sagaFetchBulk);
  yield takeEvery(getType(fetchList.request), sagaFetchList);
  yield takeEvery(getType(fetchLookups), sagaFetchLookups);
  yield takeEvery(getType(fetchPage.request), sagaFetchPage);
  yield takeEvery(getType(remove.request), sagaRemove);
  yield takeEvery(getType(request.request), sagaRequest);
  yield takeEvery(LOCATION_CHANGE, sagaLocationChange);
  yield takeLatest(getType(save.request), sagaSave);
}
