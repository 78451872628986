import { createAsyncAction } from 'typesafe-actions';

import { Resource } from '../resources/createResource';
import api, { Error, Meta, RequestConfig, Response } from './api';

export interface FetchPagePayload extends RequestConfig {
  params: {
    page: number;
    limit: number;
    [param: string]: any;
  };
  meta: Meta & {
    resultKey: string;
  };
}

interface Payload extends FetchPagePayload {
  resource: Resource;
}

const fetchPage = createAsyncAction('@lib/fetchPage/REQUEST', '@lib/fetchPage/SUCCESS', '@lib/fetchPage/FAILURE')<
  Payload,
  Response,
  Error
>();

// saga
export function* sagaFetchPage(action: ReturnType<typeof fetchPage.request>) {
  const {
    payload: { resource, ...config },
  } = action;

  return yield api({
    action: fetchPage,
    resource,
    config: {
      url: resource.fetchPageUrl,
      schema: resource.fetchPageSchema,
      ...config,
    },
  });
}

export default fetchPage;
