import { createSelector } from 'reselect';

import selectRoute from './selectRoute';

const selectCurrentLocation = createSelector(
  selectRoute,
  (state) => state.currentLocation,
);

export default selectCurrentLocation;
