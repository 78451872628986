import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import classnames from 'classnames';
import { appLocales } from 'i18n/translationMessages';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';
import { debounce, map } from 'lodash';

import FormattedSimpleMessage from '@lib/core/FormattedSimpleMessage';
import { selectLocale } from '@lib/selectors';
import messages from './messages';

const styles = (theme) => ({
  button: {
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  fade: {
    overflowY: 'auto',
  },
  icon: {
    color: theme.palette.text.disabled,
  },
  menuItem: {
    backgroundColor: theme.palette.common.white,
    borderTop: '1px solid rgba(0, 0, 0, 0.25)',
    '&:first-child': {
      borderTop: 'none',
    },
    minWidth: 100,
    padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 2}px`,
  },
  menuItemIcon: {
    fontSize: 16,
    visibility: 'hidden',
  },
  menuItemIconSelected: {
    visibility: 'visible',
  },
  menuItemText: {
    fontFamily: 'freight-sans-pro',
    fontSize: 14,
    fontWeight: 500,
  },
  menuItemTextRoot: {
    padding: 0,
  },
  menuItemTextSelected: {
    fontFamily: 'freight-sans-pro',
    fontSize: 14,
    fontWeight: 600,
  },
  menuList: {
    padding: 0,
  },
  popper: {
    background: theme.palette.common.white,
    borderRadius: 3,
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    padding: theme.spacing.unit * 1,
    zIndex: 100,
  },
  wrapper: {
    display: 'inline-flex',
  },
});

class LanguageSwitch extends PureComponent {
  constructor(props) {
    super(props);

    this.renderMenuItem = this.renderMenuItem.bind(this);

    this.state = {
      menuAnchor: null,
    };
  }

  handleRequestClose = () => {
    this.setState({ menuAnchor: null });
  };

  handleRequestOpen = (event) => {
    const { menuAnchor } = this.state;

    if (!menuAnchor) this.setState({ menuAnchor: event.currentTarget });
  };

  handleItemClick = (event, language) => {
    const { onSelect } = this.props;

    onSelect(language);

    this.handleRequestClose();
  };

  renderMenuItem(item) {
    const { classes, currentLocale } = this.props;
    const selected = item === currentLocale;
    const iconClasses = classnames(classes.menuItemIcon, { [classes.menuItemIconSelected]: selected });

    return (
      <MenuItem className={classes.menuItem} key={item} onClick={(event) => this.handleItemClick(event, item)}>
        <ListItemIcon>
          <Icon className={iconClasses}>check</Icon>
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classnames({
              [classes.menuItemText]: !selected,
              [classes.menuItemTextSelected]: selected,
            }),
            root: classes.menuItemTextRoot,
          }}
          primary={<FormattedSimpleMessage {...messages[item]} />}
        />
      </MenuItem>
    );
  }

  renderMenu() {
    const { classes } = this.props;
    const { menuAnchor } = this.state;

    return (
      <Popper
        anchorEl={menuAnchor}
        className={classes.popper}
        disablePortal
        open={!!menuAnchor}
        placement="bottom-end"
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={debounce(this.handleRequestClose, 100)}>
            <Fade className={classes.fade} timeout={200} {...TransitionProps}>
              <Paper elevation={0}>
                <MenuList className={classes.menuList}>{map(appLocales, this.renderMenuItem)}</MenuList>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    );
  }

  renderButton() {
    const { classes } = this.props;

    return (
      <IconButton aria-label="Language Menu" className={classes.button} onClick={this.handleRequestOpen}>
        <Icon className={classes.icon}>language</Icon>
      </IconButton>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.wrapper}>
        {this.renderButton()}
        {this.renderMenu()}
      </div>
    );
  }
}

LanguageSwitch.propTypes = {
  classes: PropTypes.object.isRequired,
  currentLocale: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const withConnect = connect(
  createStructuredSelector({
    currentLocale: selectLocale,
  }),
);

export default compose(
  withConnect,
  withStyles(styles),
)(LanguageSwitch);
