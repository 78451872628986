import { AxiosInstance } from 'axios';

interface Clients {
  [key: string]: AxiosInstance;
}

const axiosClients: Clients = {};

function set(client: AxiosInstance, key = 'default') {
  axiosClients[key] = client;
}

function get(key = 'default') {
  return axiosClients[key];
}

export default { set, get };
