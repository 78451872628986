import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { get } from 'lodash';
import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { FormattedMessage } from 'react-intl';

import { selectCurrentUser, selectCurrentMaster, selectCustomThemeProps } from '@lib/selectors';
import { getTheme } from '@lib/core/styles/getTheme';
import LanguageSwitch from 'components/LanguageSwitch';
import { DISPATCH_CHANGE_LOCALE } from '@lib/actions/actionTypes';

import messages from './messages';

const styles = {
  root: {
    // NOTE: This class is being overridden from MUI theme props
  },
  logo: {
    // NOTE: This class is being overridden from MUI theme props
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    position: 'absolute',
    textAlign: 'center',
    left: 0,
    right: 0,
  },
  welcome: {
    fontSize: 18,
  },
};

class Header extends React.PureComponent {
  render() {
    const { classes, width, currentUser, masterUser, themeProps, dispatchChangeLocale } = this.props;
    const muiTheme = getTheme(themeProps);
    const lumaHeader = get(muiTheme, 'props.LumaHeader');

    return (
      <AppBar className={classes.root} position="fixed" color="inherit">
        <Toolbar>
          <div className={classes.grow}>
            <img className={classes.logo} src={lumaHeader.logoSrc} alt="Logo" />
          </div>
          {masterUser && !/xs|sm/.test(width) && (
            <div className={classes.title}>
              <strong>{masterUser.name}</strong>
              <br />
              <FormattedMessage {...messages.welcome} values={{ user: currentUser.name }}>
                {(text) => <span className={classes.welcome}>{text}</span>}
              </FormattedMessage>
            </div>
          )}
          <LanguageSwitch onSelect={dispatchChangeLocale} />
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  currentUser: PropTypes.object,
  masterUser: PropTypes.object,
  themeProps: PropTypes.object,
  dispatchChangeLocale: PropTypes.func.isRequired,
};

const withConnect = connect(
  createStructuredSelector({
    currentUser: selectCurrentUser,
    masterUser: selectCurrentMaster,
    themeProps: selectCustomThemeProps,
  }),
  {
    dispatchChangeLocale: (locale) => ({ type: DISPATCH_CHANGE_LOCALE, locale }),
  },
);

export default compose(
  withConnect,
  withWidth(),
  withStyles(styles, { name: 'LumaHeader' }),
)(Header);
