import hoistNonReactStatics from 'hoist-non-react-statics';
import React from 'react';
import { SagaIterator } from 'redux-saga';

import getInjectors from './sagaInjectors';
import StoreInstance from './StoreInstance';

export enum InjectSagaMode {
  RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount',
  DAEMON = '@@saga-injector/daemon',
  ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount',
}

export interface InjectSagaArgs {
  key: string;
  saga: SagaIterator;
  mode?: InjectSagaMode;
}

/**
 * Dynamically injects a saga, passes component's props as saga arguments
 *
 * @param {string} key A key of the saga
 * @param {function} saga A root saga that will be injected
 * @param {string} [mode] By default (constants.DAEMON) the saga will be started
 * on component mount and never canceled or started again. Another two options:
 *   - constants.RESTART_ON_REMOUNT — the saga will be started on component mount and
 *   cancelled with `task.cancel()` on component unmount for improved performance,
 *   - constants.ONCE_TILL_UNMOUNT — behaves like 'RESTART_ON_REMOUNT' but never runs it again.
 *
 */
export default ({ key, saga, mode }: InjectSagaArgs) => (WrappedComponent: any) => {
  class InjectSaga extends React.Component {
    static WrappedComponent = WrappedComponent;

    static displayName = `withSaga(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

    injectors: any;

    constructor(props: any, context: any) {
      super(props, context);

      this.injectors = getInjectors(StoreInstance.store);

      this.injectors.injectSaga(key, { saga, mode }, this.props);
    }

    componentWillUnmount() {
      this.injectors.ejectSaga(key);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return hoistNonReactStatics(InjectSaga, WrappedComponent);
};
