import { RequestConfig } from '@lib/actions/api';
import { FetchListPayload } from '@lib/actions/fetchList';

import createResource from './createResource';
import patientFormTemplatesResource from './patientFormTemplatesResource';
import patientsResource from './patientsResource';
import { EntityID, PatientForm } from './types';

const patientFormsResource = createResource<PatientForm>({
  key: 'patientForms',
  url: '/patientForms',
  lookups: {
    patientFormTemplate: patientFormTemplatesResource,
    patient: patientsResource,
  },
});

const customActions = {
  medicationSearch: (
    payload: FetchListPayload & {
      params: { search: string; [param: string]: any };
    },
  ) =>
    patientFormsResource.fetchList({
      url: `${patientFormsResource.url}/medicationsearch`,
      ...payload,
    }),
  pharmacySearch: (
    payload: FetchListPayload & {
      params: { search: string; [param: string]: any; latitude: number; longitude: number };
    },
  ) =>
    patientFormsResource.fetchList({
      url: `${patientFormsResource.url}/pharmacysearch`,
      ...payload,
    }),
  execScript: (patientFormId: EntityID, itemId: EntityID, payload: RequestConfig | undefined = {}) =>
    patientFormsResource.request({
      method: 'post',
      url: `${patientFormsResource.url}/${patientFormId}/script/${itemId}`,
      ...payload,
      meta: {
        skipMessages: true,
        ...payload.meta,
      },
    }),
};

export default {
  ...patientFormsResource,
  ...customActions,
};
