import api from '../utils/axiosClient';

const url = '/widgets';

// Methods
function login({ id }) {
  return api.post(`${url}/login`, { id });
}

function fetchWidgetUser() {
  return api.get(`${url}/user`);
}

export default {
  login,
  fetchWidgetUser,
};
