import { concat, map, pick, reduce } from 'lodash';
import { all, put } from 'redux-saga/effects';
import { createStandardAction, getType } from 'typesafe-actions';

import { Resource } from '../resources/createResource';
import fetchBulk, { sagaFetchBulk } from './fetchBulk';

interface Payload {
  resource: Resource;
  entities: any;
  // TODO: type `includeLookups` to entity fields instead of string
  keys?: string[];
  resolve?: boolean;
}

const fetchLookups = createStandardAction('@lib/fetchLookups')<Payload>();

// saga
export function* sagaFetchLookups(action: ReturnType<typeof fetchLookups>): Generator {
  const {
    payload: { resource, entities, keys, resolve },
  } = action;

  const lookups = keys ? pick(resource.lookups, keys) : resource.lookups;

  const effects = reduce(
    lookups,
    (result: any[], lookupResource, attribute) => {
      const ids = map(entities[resource.key], attribute);
      const fetchBulkPayload = { resource: lookupResource as Resource, ids };
      const newEffect = resolve
        ? sagaFetchBulk({ type: getType(fetchBulk.request), payload: fetchBulkPayload })
        : put(fetchBulk.request(fetchBulkPayload));
      return concat(result, newEffect);
    },
    [],
  );

  return yield all(effects);
}

export default fetchLookups;
