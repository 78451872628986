import { call, put, select } from 'redux-saga/effects';
import { browserHistory } from 'react-router';
import { get } from 'lodash';
import 'moment/locale/es';

import { LOGIN_WIDGET_USER, FETCH_CONTEXT, DISPATCH_CHANGE_LOCALE, DISPATCH_APP_READY } from '@lib/actions/actionTypes';
import { sagaSave } from '@lib/actions/save';
import { sagaFetch, sagaValidateToken } from '@lib/sagas';
import { selectCurrentUser } from '@lib/selectors';
import contextsResource from '@lib/resources/contextsResource';
import widgetUsersResource from '@lib/resources/widgetUsersResource';
import storageService from '@lib/services/storageService';
import { patientFormsResource } from '@lib/resources';

import { registerSocket } from 'containers/App/actions';

import sagaFetchCurrentUser from './sagaFetchCurrentUser';
import sagaFetchCustomWebStyles from './sagaFetchCustomWebStyles';

// bind resource generator
const fetchContext = sagaFetch.bind(null, FETCH_CONTEXT, contextsResource.fetchContext);
const loginWidgetUser = sagaFetch.bind(null, LOGIN_WIDGET_USER, widgetUsersResource.login);

function signOut() {
  storageService.removeAuth();
  browserHistory.push('/login');
}

export default function* sagaInitApp(action = {}) {
  // keep URL parameters on storage
  if (action.query) {
    storageService.setParameters({
      reschedule: /reschedule/i.test(action.pathname),
      ...action.query,
    });
  }

  // if user exists, login to get token

  // NOTE: some views are still using the token from the URL directly instead of getting it from the storage
  // which will cause problems in case we pass both: `user` and `token` URL parameters to those views
  // because they'll be using the token from the URL instead of the one from the user

  // FIXME: remove all appearances of location.query.token and use storageService.getToken when necessary
  let token;
  const user = get(action.query, 'user');
  if (user) {
    const res = yield call(loginWidgetUser, { id: user });
    if (res.error) {
      signOut();
      return;
    }
    token = res.data.token;
  } else {
    token = get(action.query, 'token') || storageService.getToken();
  }

  // prevent to set undefined to patient token
  if (token) {
    yield sagaValidateToken({ token });
    storageService.setToken(token);
    // fetch current user
    yield call(sagaFetchCurrentUser);
  }

  // skip fetching auth for some screens
  if (!/|verify/.test(action.pathname)) {
    // validate token
    if (!token || !!(yield sagaValidateToken({ token })).error) {
      signOut();
    } else if (!action || !/login/.test(action.pathname)) {
      // fetch current user
      yield sagaFetchCurrentUser();

      // fetch theme
      yield sagaFetchCustomWebStyles(action);

      // fetch context
      const refId = get(action.query, 'refId');
      if (refId) {
        yield call(fetchContext, { refId });
      }
    }
  }
  const currentUser = yield select(selectCurrentUser);

  if (currentUser) {
    // TODO: normalize registering socket
    yield put(registerSocket(currentUser));

    // Set patient on pending patient forms
    const pendingPatientFormId = storageService.getPatientFormPending();
    if (pendingPatientFormId && currentUser.type === 'patient') {
      const patientForm = yield select(patientFormsResource.makeSelectEntity(pendingPatientFormId));
      yield call(sagaSave, {
        payload: {
          resource: patientFormsResource,
          data: { ...patientForm, patient: currentUser.id },
          meta: { ignoreErrors: true, skipMessages: true },
        },
      });
      storageService.removePatientFormPending();
    }
  }

  // update locale
  const locale = storageService.getLocale() || get(currentUser, 'language') || 'en';
  yield put({ type: DISPATCH_CHANGE_LOCALE, locale });

  // app ready
  yield put({ type: DISPATCH_APP_READY });

  // callbackSuccess
  if (action.callbackSuccess) {
    action.callbackSuccess();
  }
}
