import { createSelector } from 'reselect';

import selectAuth from './selectAuth';

const selectAuthMaster = createSelector(
  selectAuth,
  (auth) => auth.master,
);

export default selectAuthMaster;
