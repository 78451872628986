import { createAsyncAction } from 'typesafe-actions';

import { Resource } from '../resources/createResource';
import { EntityID } from '../resources/types';
import api, { Error, RequestConfig, Response } from './api';

export interface RemovePayload extends RequestConfig {
  id: EntityID;
}

interface Payload extends RemovePayload {
  resource: Resource;
}

const remove = createAsyncAction('@lib/remove/REQUEST', '@lib/remove/SUCCESS', '@lib/remove/FAILURE')<
  Payload,
  Response,
  Error
>();

// saga
export function* sagaRemove(action: ReturnType<typeof remove.request>) {
  const {
    payload: { resource, id, meta, ...config },
  } = action;

  return yield api({
    action: remove,
    resource,
    config: {
      method: 'delete',
      url: `${resource.url}/${id}`,
      schema: resource.removeSchema,
      meta: {
        messageSuccess: resource.removeMessageSuccess,
        messageError: resource.removeMessageError,
        ...meta,
      },
      ...config,
    },
  });
}

export default remove;
