import GoogleMapReact, { Coords, Props as GoogleMapReactProps } from 'google-map-react';
import { head } from 'lodash';
import React, { useEffect, useState } from 'react';

import usePlacesService from '@lib/hooks/usePlacesService';
import configService from '@lib/services/configService';
import { createStyles, withStyles } from '@material-ui/core';

import { LumaTheme } from '../styles/getTheme';

const key = configService.getKeyGooglMaps();

const styles = (theme: LumaTheme) =>
  createStyles({
    marker: {
      width: theme.spacing.unit * 3,
      height: theme.spacing.unit * 3,
    },
  });

interface Props extends GoogleMapReactProps {
  classes: any;
  // `className` required > Always set the container height explicitly
  // https://github.com/google-map-react/google-map-react#getting-started
  className: string;
  query?: string;
  queryFallback?: string;
  defaultZoomFallback?: number;
}

const Map: React.FC<Props> = ({
  classes,
  className,
  query,
  queryFallback,
  defaultZoom = 15,
  defaultZoomFallback = 12,
  defaultCenter: coods,
  ...other
}) => {
  const [service] = usePlacesService();
  const [defaultCenter, setDefaultCenter] = useState<Coords | undefined>(coods);
  const [usingFallback, setUsingFallback] = useState(false);

  const handleSearch = (q: string, callbackEmpty?: any) => {
    service.textSearch({ query: q }, (results: any[]) => {
      const item = head(results);
      if (item) {
        const result = {
          lat: item.geometry.location.lat(),
          lng: item.geometry.location.lng(),
        };
        setDefaultCenter(result);
      } else if (callbackEmpty) {
        callbackEmpty();
      }
    });
  };

  useEffect(() => {
    if (query && service) {
      handleSearch(query, () => {
        if (queryFallback) {
          setUsingFallback(true);
          handleSearch(queryFallback);
        }
      });
    }
    // eslint-disable-next-line
  }, [query, queryFallback, service]);

  return (
    <div className={className}>
      {defaultCenter && key && (
        <GoogleMapReact
          bootstrapURLKeys={{ key }}
          defaultCenter={defaultCenter}
          defaultZoom={usingFallback ? defaultZoomFallback : defaultZoom}
          {...other}
        >
          {!usingFallback && (
            // @ts-ignore
            <img
              className={classes.marker}
              src="https://maps.gstatic.com/mapfiles/place_api/icons/geocode-71.png"
              lat={defaultCenter.lat}
              lng={defaultCenter.lng}
              alt={query}
            />
          )}
        </GoogleMapReact>
      )}
    </div>
  );
};

export default withStyles(styles)(Map);
