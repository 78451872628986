import axios from 'axios';
import { get } from 'lodash';
import { normalize } from 'normalizr';
import { browserHistory } from 'react-router';

import configService from '../services/configService';
import { trackApiError } from '../services/eventsService';
import storage from '../services/storageService';

// Create a new instance of axios
// https://github.com/axios/axios#creating-an-instance
const axiosClient = axios.create({
  baseURL: configService.getApiBasePath(),
});

// Request interceptors
axiosClient.interceptors.request.use((config) => ({
  ...config,
  headers: {
    'x-access-token': storage.getToken(),
    ...config.headers,
  },
}));

// Response interceptors
axiosClient.interceptors.response.use(
  // TODO: remove extended props from axios config
  // @ts-ignore
  ({ data, config: { schema, config }, ...other }) => ({
    ...other,
    data,
    config,
    // Normalizes the result JSON according to schema.
    // This makes every API response have the same shape, regardless of how nested it was.
    normalized: schema ? normalize(data, schema) : data,
  }),
  (error) =>
    Promise.reject(error).finally(() => {
      if (get(error, 'response.config.ignoreError')) {
        return;
      }
      // Track API error event
      trackApiError(error.response);

      // Redirect on 401 errors
      if (get(error, 'response.status') === 401) {
        storage.removeAuth();
        browserHistory.push('/login');
      }
    }),
);

export default axiosClient;
