import { put, select } from 'redux-saga/effects';
import { get } from 'lodash';
import moment from 'moment';
import 'moment/locale/es';

import storage from '@lib/services/storageService';
import messageTemplatesResource from '@lib/resources/messageTemplatesResource';
import { selectCurrentUser } from '@lib/selectors';

export default function* sagaChangeLocale(action) {
  const { locale } = action;
  moment.locale(locale);

  // update locale on storage
  const currentUser = yield select(selectCurrentUser);
  const currentUserLocale = get(currentUser, 'language');
  if (locale === currentUserLocale) {
    storage.removeLocale();
  } else {
    storage.setLocale(locale);
  }

  // fetch message templates if token available
  if (storage.getToken()) {
    yield put(
      messageTemplatesResource.fetchList({
        params: { lang: locale },
        meta: { ignoreError: [401] },
      }),
    );
  }
}
