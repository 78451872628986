export { default as appointmentsResource } from './appointmentsResource';
export { default as appointmentTypesResource } from './appointmentTypesResource';
export { default as availabilitiesResource } from './availabilitiesResource';
export { default as contextsResource } from './contextsResource';
export { default as customWebStylesResource } from './customWebStylesResource';
export { default as facilitiesResource } from './facilitiesResource';
export { default as messageTemplatesResource } from './messageTemplatesResource';
export { default as offersResource } from './offersResource';
export { default as patientFormsResource } from './patientFormsResource';
export { default as patientFormTemplatesResource } from './patientFormTemplatesResource';
export { default as patientsResource } from './patientsResource';
export { default as providersResource } from './providersResource';
export { default as remindersResource } from './remindersResource';
export { default as settingsResource } from './settingsResource';
export { default as tokensResource } from './tokensResource';
export { default as usersListResource } from './usersListResource';
export { default as usersResource } from './usersResource';
export { default as widgetUsersResource } from './widgetUsersResource';
