import { mapValues } from 'lodash';

import { FetchListPayload } from '@lib/actions/fetchList';

import createResource from './createResource';
import { MessageTemplate } from './types';

const messageTemplatesResource = createResource<MessageTemplate>({
  key: 'messageTemplates',
  url: '/messageTemplates',
});

const customActions = {
  fetchList: (payload: FetchListPayload) =>
    messageTemplatesResource.fetchList({
      ...payload,
      meta: {
        normalize: (data: any) => ({
          entities: {
            [messageTemplatesResource.key]: mapValues(data, (value: string, id: string) => ({
              id,
              value,
            })),
          },
          result: [],
        }),
        ...payload.meta,
      },
    }),
};

export default {
  ...messageTemplatesResource,
  ...customActions,
};
