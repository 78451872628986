import { call, select } from 'redux-saga/effects';
import { get } from 'lodash';

import customWebStylesResource from '@lib/resources/customWebStylesResource';
import { selectCustomThemeProps } from '@lib/selectors';
import { FETCH_CUSTOM_WEB_STYLES } from '@lib/actions/actionTypes';
import { sagaFetch, sagaFetchFontFamilies } from '@lib/sagas';

// bind resource generator
const fetchCustomWebStyles = sagaFetch.bind(null, FETCH_CUSTOM_WEB_STYLES, customWebStylesResource.fetch);

export default function* sagaFetchCustomWebStyles(action) {
  const id = get(action, 'query.customWebStylesId');
  if (id) {
    yield call(fetchCustomWebStyles, { id });
  } else {
    yield call(fetchCustomWebStyles, { params: { published: true } });
  }

  // fetch font family
  const themeProps = yield select(selectCustomThemeProps);
  const fontFamily = get(themeProps, 'typography.fontFamily');
  if (fontFamily) {
    yield sagaFetchFontFamilies({ families: [fontFamily] });
  }
}
