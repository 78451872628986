import { createSelector } from 'reselect';
import { get } from 'lodash';

import selectCustomWebStylesLast from './selectCustomWebStylesLast';

const selectCustomThemeProps = createSelector(
  selectCustomWebStylesLast,
  (obj) => get(obj, 'styles.theme'),
);

export default selectCustomThemeProps;
