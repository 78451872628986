import React from 'react';
import ReactRecaptcha, { RecaptchaProps } from 'react-recaptcha';
import { useSelector } from 'react-redux';

import { useScript } from '@lib/hooks';
import { selectLocale } from '@lib/selectors';
import configService from '@lib/services/configService';

interface Props extends RecaptchaProps {}

export default function Recaptcha(props: Props) {
  const [loaded] = useScript('//www.google.com/recaptcha/api.js');

  const locale = useSelector(selectLocale);

  if (!loaded) {
    return <React.Fragment />;
  }

  return (
    <ReactRecaptcha
      sitekey={configService.getKeyRecaptcha()}
      render="explicit"
      hl={locale}
      // WORKAROUND: https://github.com/appleboy/react-recaptcha/issues/231#issuecomment-383533303
      onloadCallback={() => {}}
      {...props}
    />
  );
}
