import { formValueSelector } from 'redux-form/immutable';

/**
 * Selectors for redux-form
 */
function selectFormValue(...args) {
  const state = args.shift();
  const formName = args.shift();
  return formValueSelector(formName)(state, ...args);
}

export default selectFormValue;
