import { FetchListPayload } from '../actions/fetchList';
import { RequestPayload } from '../actions/request';
import { SavePayload } from '../actions/save';
import { parseDate } from '../services/datesService';
import createResource from './createResource';
import providersResource from './providersResource';
import { EntityID, Patient } from './types';

interface PatientSearchPayload extends FetchListPayload {
  params: {
    scope: string;
    value: string;
    type: string;
  },
}

const patientsResource = createResource<Patient>({
  key: 'patients',
  url: '/patients',
  fetchListUrl: '/patients/crudList',
  fetchPageUrl: '/patients/crudList',
  lookups: {
    primaryCareProvider: providersResource,
  },
  processStrategy: (data) => ({
    ...data,
    $dateOfBirth: parseDate(data.dateOfBirth),
  }),
});

const customActions = {
  patientSearch: (payload: PatientSearchPayload) =>
    patientsResource.fetchList({
      url: `${patientsResource.url}/search`,
      ...payload,
    }),

  verifyPatient: (id: EntityID, code: string, payload: RequestPayload) =>
    patientsResource.request({
      method: 'POST',
      url: `${patientsResource.url}/${id}/verify`,
      data: { code },
      ...payload,
    }),

  login: (payload: SavePayload<Patient>) =>
    patientsResource.save({
      method: 'POST',
      url: `${patientsResource.url}/login`,
      ...payload,
    }),
};

export default {
  ...patientsResource,
  ...customActions,
};
