import { createSelector } from 'reselect';

import { entitiesKey } from '../resources/customWebStylesResource';

import selectEntities from './selectEntities';

const selectCustomWebStylesObj = createSelector(
  selectEntities,
  (obj) => obj[entitiesKey],
);

export default selectCustomWebStylesObj;
