import { isEqual } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { browserHistory } from 'react-router';

import { selectCurrentToken } from '../selectors';
import configService from '../services/configService';
import storageService from '../services/storageService';
import usePrevious from './usePrevious';

const minutes = configService.getSessionExpireMinutes();
const miliseconds = minutes * 60 * 1000;

function useCheckSessionExpired(pathOnExpire: string = '/session-expired') {
  const [sessionExpired, setSessionExpired] = useState(false);
  const [timer, setTimer] = useState();

  const currentToken = useSelector(selectCurrentToken);
  const prevCurrentToken = usePrevious(currentToken);

  const initializeTimer = useCallback(() => {
    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        setSessionExpired(true);
      }, miliseconds),
    );
  }, [timer, setTimer, setSessionExpired]);

  const addListeners = useCallback(() => {
    window.addEventListener('click', initializeTimer);
    window.addEventListener('keydown', initializeTimer);
  }, [initializeTimer]);

  const removeListeners = useCallback(() => {
    window.removeEventListener('click', initializeTimer);
    window.removeEventListener('keydown', initializeTimer);
    clearTimeout(timer);
  }, [initializeTimer, timer]);

  useEffect(() => {
    if (!isEqual(currentToken, prevCurrentToken)) {
      if (currentToken && moment().isAfter(currentToken.exp)) {
        setSessionExpired(true);
      } else if (currentToken) {
        addListeners();
        initializeTimer();
      } else {
        // Remove event listeners on sign out
        removeListeners();
      }
    }
  }, [currentToken, prevCurrentToken, addListeners, removeListeners, initializeTimer]);

  useEffect(() => {
    if (sessionExpired) {
      removeListeners();
      storageService.removeAuth();
      browserHistory.push(pathOnExpire);
    }
  }, [sessionExpired, removeListeners, pathOnExpire]);

  return sessionExpired;
}

export default useCheckSessionExpired;
