import { createSelector } from 'reselect';

import selectRoute from './selectRoute';

const selectPrevLocation = createSelector(
  selectRoute,
  (state) => state.prevLocation,
);

export default selectPrevLocation;
