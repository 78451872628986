import { compact, filter, head, isArray, map, mapValues, matches, omit, omitBy, reduce, sortBy, values } from 'lodash';
import { DeepPartial } from 'redux';
import { createSelector, Selector } from 'reselect';

import makeSelectResultPage from '../selectors/makeSelectResultPage';
import selectAllEntitiesObj from '../selectors/selectEntities';
import selectId from '../selectors/selectId';
import { defaultProcessStrategy } from './createResourceSchema';
import { EntityBase, EntityID } from './types';

export type EntitiesObject<E> = {
  [key: string]: E;
};

export interface CreateResourceSelectorsArgs {
  key: string;
  selectLookups?: Selector<any, any>;
}

const createResourceSelectors = <E extends EntityBase>({
  key,
  selectLookups = () => {},
}: CreateResourceSelectorsArgs) => {
  const selectAll = createSelector(
    selectAllEntitiesObj,
    (obj) => obj[key] as EntitiesObject<E>,
  );

  const selectEntitiesObjWithoutLookups = createSelector(
    selectAll,
    (obj) => omit(omitBy(obj, 'deleted'), [-1]),
  );

  const selectListWithoutLookups = createSelector(
    selectEntitiesObjWithoutLookups,
    (obj) => values(obj) as E[],
  );

  const selectEntitiesObj = createSelector(
    selectEntitiesObjWithoutLookups,
    selectLookups,
    (obj, lookupsObj) =>
      mapValues(obj, (value: any) => ({
        ...value,
        ...reduce(
          lookupsObj,
          (accumulator: EntitiesObject<E | E[]>, lookupObj: { [key: string]: any }, key: string) => {
            const lookupId: EntityID | EntityID[] = value[key];
            if (isArray(lookupId)) {
              accumulator[key] = map(lookupId, (id) => lookupObj[id] || defaultProcessStrategy({ id }, false));
            } else {
              accumulator[key] = lookupObj[lookupId] || defaultProcessStrategy({ id: lookupId }, false);
            }
            return accumulator;
          },
          {},
        ),
      })) as EntitiesObject<E>,
  );

  const selectList = createSelector(
    selectEntitiesObj,
    (obj) => values(obj),
  );

  const selectEntity = createSelector(
    selectEntitiesObj,
    selectId,
    (obj, id = '') => obj[id],
  );

  const makeSelectEntity = (id: EntityID) =>
    createSelector(
      selectEntitiesObj,
      (obj) => obj[id],
    );

  const makeSelectListSorted = (fields: string[]) =>
    createSelector(
      selectList,
      (list) => sortBy(list, fields),
    );

  const makeSelectPage = (resultKey: string) =>
    createSelector(
      makeSelectResultPage(resultKey),
      selectEntitiesObj,
      (result, obj) =>
        result && {
          ...result,
          items: compact(map(result.response, (id) => obj[id])),
          itemsAgregated: compact(map(result.responseAgregated, (id) => obj[id])),
        },
    );

  const makeSelectListFiltered = (match: DeepPartial<E>) =>
    createSelector(
      selectList,
      (list) => filter(list, matches(match)),
    );

  const makeSelectEntityFiltered = (match: DeepPartial<E>) =>
    createSelector(
      makeSelectListFiltered(match),
      (list) => head(list),
    );

  return {
    selectAll,
    selectEntitiesObjWithoutLookups,
    selectListWithoutLookups,
    selectEntitiesObj,
    selectList,
    selectEntity,
    makeSelectListSorted,
    makeSelectEntity,
    makeSelectPage,
    makeSelectListFiltered,
    makeSelectEntityFiltered,
  };
};

export default createResourceSelectors;
