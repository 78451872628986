import classnames from 'classnames';
import { compact, filter, includes, map } from 'lodash';
import React, { PureComponent } from 'react';

import { EntitiesObject } from '@lib/resources/createResourceSelectors';
import { Entity } from '@lib/resources/types';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import { Theme, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

import FormattedSimpleMessage from '../FormattedSimpleMessage';
import TextFieldSearch from '../TextFieldSearch';
import FilterListItem, { FilterListItemProps } from './FilterListItem';
import messages from './messages';

const styles = (theme: Theme) => ({
  root: {
    flex: 1,
    padding: `0 ${theme.spacing.unit * 2}px`,
  },
  formControlLabelPlacementStart: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    marginLeft: 0,
    marginRight: -1 * theme.spacing.unit * 2,
  },
  formControlLabel: {
    fontWeight: 500,
  },
  switchBase: {
    height: theme.spacing.unit * 3,
  },
  marginTop: {
    marginTop: theme.spacing.unit,
  },
  options: {
    margin: `0 ${-1 * theme.spacing.unit * 2}px`,
  },
});

export interface FilterListProps {
  classes: any;
  loading?: boolean;
  skipSwitch?: boolean;
  allSelected?: boolean;
  skipAllSelectedItemsDisabled?: boolean;
  allItems: EntitiesObject<Entity>;
  items: Entity[];
  idsExcluded: string[];
  itemsLabel?: string;
  limit: number;
  onSearchChange?: (page: number, limit: number, value?: any) => void;
  onItemSelected: FilterListItemProps['onItemSelected'];
  onAllSelected: (allSelected: boolean) => void;
  onClearItemsSelected: () => void;
}

class FilterList extends PureComponent<FilterListProps> {
  state = {
    value: '',
  };

  componentDidMount() {
    this.handleSearchChange();
  }

  handleAllSelected = (event: any) => {
    const { onAllSelected } = this.props;
    const { value } = this.state;
    const allSelected = event.target.checked;
    onAllSelected(allSelected);
    if (!allSelected && value) {
      this.handleSearchChange();
    }
  };

  handleSearchChange = (value = '') => {
    const { onSearchChange, limit } = this.props;
    if (!onSearchChange) {
      return;
    }
    this.setState({ value });
    onSearchChange(1, limit, value || undefined);
  };

  render() {
    const {
      classes,
      loading,
      skipSwitch,
      allItems,
      items,
      idsExcluded,
      allSelected,
      skipAllSelectedItemsDisabled,
      itemsLabel,
      limit,
      onSearchChange,
      onItemSelected,
      onClearItemsSelected,
      onAllSelected,
      ...other
    } = this.props;
    const { value } = this.state;

    // Items excluded:
    // - Selected items in case of toggle: none
    // - Unselected items in case of toggle: all
    const itemsExcluded = compact(map(idsExcluded, (id) => allItems[id]));
    const itemsNotExcluded = compact(filter(items, ({ id }) => !includes(idsExcluded, id)));

    const limitReached = limit <= items.length || !!value;
    const allLabel = skipAllSelectedItemsDisabled ? 'Select all' : 'All';
    return (
      <div className={classes.root}>
        {!skipSwitch && (
          <React.Fragment>
            <FormControlLabel
              classes={{
                // @ts-ignore
                labelPlacementStart: classes.formControlLabelPlacementStart,
                label: classes.formControlLabel,
              }}
              control={
                <Switch
                  classes={{
                    switchBase: classes.switchBase,
                  }}
                  color="primary"
                  // NOTE: If toggle is on but user excludes something, the toggle should be switched off
                  // https://lumahealth.slack.com/archives/D54KHRBL7/p1556238108000700
                  checked={allSelected && !itemsExcluded.length}
                  onChange={this.handleAllSelected}
                  value="allSelected"
                />
              }
              label={allLabel}
              labelPlacement="start"
            />
            {itemsLabel && allSelected && !itemsExcluded.length && (
              <Typography variant="caption" color="default">
                {`Any current and future ${itemsLabel}`}
              </Typography>
            )}
          </React.Fragment>
        )}
        {!!onSearchChange && limitReached && (!allSelected || skipAllSelectedItemsDisabled) && (
          // @ts-ignore
          <TextFieldSearch
            autoFocus
            className={classes.marginTop}
            onChange={this.handleSearchChange}
            placeholder="Search"
            size="small"
            value={value}
          />
        )}
        {itemsExcluded.length > 0 && (
          <Button className={classes.marginTop} size="small" fullWidth onClick={onClearItemsSelected}>
            <FormattedSimpleMessage {...(allSelected ? messages.clearExcluded : messages.clearSelections)} />
          </Button>
        )}
        <div className={classes.options}>
          {/* Items excluded */}
          {itemsExcluded.length > 0 && (
            <List>
              {map(itemsExcluded, (item) => (
                <FilterListItem
                  key={item.id}
                  item={item}
                  checked={!allSelected}
                  onItemSelected={onItemSelected}
                  {...other}
                />
              ))}
            </List>
          )}
          {itemsExcluded.length > 0 && itemsNotExcluded.length > 0 && <Divider />}
          {/* Items not excluded */}
          {!loading && itemsNotExcluded.length > 0 && (
            <List>
              {map(itemsNotExcluded, (item) => (
                <FilterListItem
                  key={item.id}
                  item={item}
                  checked={allSelected}
                  onItemSelected={onItemSelected}
                  disabled={!skipAllSelectedItemsDisabled && allSelected}
                  {...other}
                />
              ))}
            </List>
          )}
        </div>
        {itemsLabel && limitReached && !loading && (
          <Typography
            className={classnames({
              [classes.marginTop]: itemsNotExcluded.length === 0,
            })}
            variant="caption"
            gutterBottom
          >
            {`${
              itemsNotExcluded.length > 0 ? `Displaying ${itemsNotExcluded.length} ${itemsLabel}. ` : ''
            }Please use search to see other ${itemsLabel}.`}
          </Typography>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(FilterList);
