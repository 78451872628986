import { useEffect, useState } from 'react';

import configService from '@lib/services/configService';

import useScript from './useScript';

const key = configService.getKeyGooglMaps();

export default function usePlacesService() {
  const [scriptLoaded] = useScript(`//maps.googleapis.com/maps/api/js?key=${key}&libraries=places`);
  const [service, setService] = useState();

  const updateService = () => {
    const google = (window as any).google;

    // WORKAROUND Adding an extra check because `window.google` might be undefined
    // https://stackoverflow.com/questions/50030311/window-google-is-undefined-in-react
    // FIXME Find a better solution with no erros on the console
    if (google) {
      setService(new google.maps.places.PlacesService(document.createElement('div')));
    }
    return !!google;
  };

  useEffect(() => {
    if (scriptLoaded && !service) {
      const done = updateService();
      if (!done) {
        setTimeout(() => {
          updateService();
        }, 100);
      }
    }
  }, [scriptLoaded, service]);

  return [service];
}
