/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const UPDATE_USER = 'lumahealth/App/UPDATE_USER';

export const REGISTER_SOCKET = 'lumahealth/App/REGISTER_SOCKET';
export const ON_SOCKET_MESSAGE = 'lumahealth/App/ON_SOCKET_MESSAGE';
export const ON_SOCKET_TYPING_START = 'lumahealth/App/ON_SOCKET_TYPING_START';
export const ON_SOCKET_TYPING_STOP = 'lumahealth/App/ON_SOCKET_TYPING_STOP';
export const EMIT_SOCKET_TYPING = 'lumahealth/App/EMIT_SOCKET_TYPING';

export const LS_DO_NOT_SHOW_LANGUAGE_BANNER = 'lh:do_not_show_language_banner';
