/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */

// supporting browsers that do not have Intl.PluralRules (e.g IE11 & Safari 12-)
// https://github.com/formatjs/react-intl/blob/master/docs/Upgrade-Guide.md#migrate-to-using-native-intl-apis
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/es';

// supporting browsers that do not have Intl.RelativeTimeFormat (e.g IE11, Edge, Safari 13-)
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/es';

import enTranslationMessages from './locales/en.json';
import esTranslationMessages from './locales/es.json';

export const appLocales = ['en', 'es'];
const DEFAULT_LOCALE = 'en';

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages) : {};
  return Object.keys(messages).reduce((formattedMessages, key) => {
    let message = messages[key];
    if (!message && locale !== DEFAULT_LOCALE) {
      message = defaultFormattedMessages[key];
    }
    return Object.assign(formattedMessages, { [key]: message });
  }, {});
};

const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  es: formatTranslationMessages('es', esTranslationMessages),
};

export default translationMessages;
