/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */

import { fromJS } from 'immutable';
import { has, get } from 'lodash';

import { SIGNIN, SIGNIN_SUCCESS, SIGNOUT_USER } from 'containers/LoginPage/constants';
import { UPDATE_USER } from './constants';

// The initial state of the App
const initialState = fromJS({
  loading: false,
  error: false,
  currentUser: false,
});

function appReducer(s = initialState, action) {
  let state = s;

  if (
    action.error &&
    !action.dismissSnackbar &&
    !get(action, 'error.dismissSnackbar') &&
    action.type !== '@@redux-form/SET_SUBMIT_FAILED' &&
    !get(action, 'error.config.ignoreError')
  ) {
    state = state.set('error', {
      data: action.error,
      // add timestamp in order to refresh error in case of being the same message
      timestamp: new Date().getTime(),
    });
  }

  if (action.notification) state = state.set('notification', action.notification);

  // loading
  if (has(action, 'loading')) {
    state = state.set('loading', action.loading);
  } else if (/_SUCCESS|_ERROR/.test(action.type)) {
    state = state.set('loading', false);
  }

  switch (action.type) {
    case SIGNIN:
    case SIGNOUT_USER:
      return state.set('currentUser', false);
    case UPDATE_USER:
    case SIGNIN_SUCCESS: {
      const { user } = action;
      return state.set('currentUser', user).set('loading', false);
    }
    default:
      return state;
  }
}

export default appReducer;
