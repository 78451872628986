import { merge } from 'lodash';

import {
  SIGN_IN,
  VALIDATE_TOKEN,
  FETCH_CURRENT_USER,
  DISPATCH_CHANGE_LOCALE,
  DISPATCH_APP_READY,
} from '@lib/actions/actionTypes';

const initialState = {
  ready: false,
  user: undefined,
  master: undefined,
  locale: 'en',
};

export default function createAuthReducer() {
  return (state = initialState, action) => {
    switch (action.type) {
      // SIGN_IN / SIGN_OUT
      case SIGN_IN.REQUEST:
        return initialState;
      case SIGN_IN.SUCCESS: {
        return merge({}, state, {
          user: action.data._id,
        });
      }
      // VALIDATE_TOKEN
      case VALIDATE_TOKEN.SUCCESS:
        return merge({}, state, {
          user: action.normalized.result,
        });
      // FETCH_CURRENT_USER
      case FETCH_CURRENT_USER.SUCCESS:
        return merge({}, state, {
          user: action.data._id,
          master: action.data.user,
        });

      // DISPATCH_CHANGE_LOCALE
      case DISPATCH_CHANGE_LOCALE:
        return merge({}, state, {
          locale: action.locale,
        });
      // DISPATCH_APP_READY
      case DISPATCH_APP_READY:
        return merge({}, state, {
          ready: true,
        });
      default:
        return state;
    }
  };
}
