import React, { PureComponent } from 'react';
import { keyBy } from 'lodash';

import FilterList from './FilterList';

const items = [
  {
    id: 'confirmed',
    name: 'Confirmed',
  },
  {
    id: 'unconfirmed',
    name: 'Unconfirmed',
  },
  {
    id: 'cancelled',
    name: 'Cancelled',
    radioGroup: {
      id: 'cancelled-none',
      items: [
        {
          id: 'cancelled-noshow',
          name: 'Reason: No-show',
        },
        {
          id: 'cancelled-none',
          name: 'Reason: None',
        },
      ],
    },
  },
];

const allItems = keyBy(items, 'id');

class FilterAppointmentStatus extends PureComponent {
  render() {
    return <FilterList items={items} allItems={allItems} {...this.props} />;
  }
}

export default FilterAppointmentStatus;
