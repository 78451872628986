import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';

import appointmentTypesResource from '@lib/resources/appointmentTypesResource';
import { AppointmentType } from '@lib/resources/types';

import FilterList, { FilterListProps } from '../FilterList';
import { resultKey, selectAppointmentTypes } from './selectors';

interface Props extends FilterListProps {
  items: AppointmentType[];
  dispatch: Dispatch;
}

const FilterListAppointmentTypes: React.FC<Props> = ({ allItems, items, dispatch, idsExcluded, ...other }) => {
  useEffect(() => {
    dispatch(
      appointmentTypesResource.fetchBulk({
        ids: idsExcluded,
      }),
    );
  }, [dispatch, idsExcluded]);

  return (
    <FilterList
      itemsLabel="appointment types"
      allItems={allItems}
      items={items}
      idsExcluded={idsExcluded}
      onSearchChange={(page, limit, name) =>
        dispatch(
          appointmentTypesResource.fetchPage({
            params: { page, limit, name },
            meta: {
              resultKey,
            },
          }),
        )
      }
      {...other}
    />
  );
};

const withConnect = connect(
  createStructuredSelector({
    allItems: appointmentTypesResource.selectEntitiesObj,
    items: selectAppointmentTypes,
  }),
);

export default withConnect(FilterListAppointmentTypes);
