import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import Button from '@lib/core/Button';
import FormattedSimpleMessage from '@lib/core/FormattedSimpleMessage';

import languagesMessages from 'components/LanguageSwitch/messages';
import { LS_DO_NOT_SHOW_LANGUAGE_BANNER } from 'containers/App/constants';
import storage from '@lib/services/storageService';
import brandsService from '@lib/services/brandsService';

import messages from './messages';

const styles = ({ breakpoints: { down }, palette: { primary }, spacing: { unit } }) => ({
  buttons: {
    lineHeight: '44px',
    padding: `0px ${unit}px`,
    '& button:first-child': {
      marginRight: unit,
    },
    [down('sm')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  container: {
    display: 'inline-block',
    float: 'left',
    '&$right': {
      float: 'right',
    },
  },
  closeButton: {
    color: primary.main,
    outline: 'none !important',
    '&:hover': {
      outline: 'none !important',
    },
  },
  message: {
    color: primary.main,
    fontWeight: 500,
    padding: `${unit + 2}px ${unit}px`,
    [down('sm')]: {
      width: 'calc(100% - 48px)',
    },
  },
  right: {},
  root: {
    backgroundColor: '#bcd6de',
    display: 'inline-block',
    height: 'auto',
    padding: `0px ${unit * 3}px`,
    [down('sm')]: {
      padding: 0,
    },
  },
});

class TranslateBanner extends PureComponent {
  state = {
    show: false,
  };

  componentWillMount() {
    const { show } = this.props;

    this.setState({ show });
  }

  componentWillUpdate(nextProps) {
    const { show } = this.props;

    if (show !== nextProps.show) this.setState({ show: nextProps.show });
  }

  handleRequestClose = () => {
    this.setState({ show: false });
  };

  handleCancelClick = () => {
    this.handleRequestClose();

    storage.set(LS_DO_NOT_SHOW_LANGUAGE_BANNER, true);
  };

  render() {
    const { classes, language, onChange } = this.props;
    const { show } = this.state;

    if (!show) return false;

    return (
      <div className={classes.root}>
        <div className={classnames(classes.container, classes.right)}>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            color="primary"
            key="close"
            onClick={this.handleRequestClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classnames(classes.container, classes.message)}>
          <FormattedMessage
            {...messages.text}
            values={{
              language: <FormattedSimpleMessage {...languagesMessages[language]} />,
              brandName: brandsService.getBrandName(),
            }}
          />
        </div>
        <div className={classnames(classes.container, classes.buttons)}>
          <Button color="primary" onClick={() => onChange(language)} size="small" variant="contained">
            <FormattedSimpleMessage {...messages.confirmButton} />
          </Button>
          <Button color="error" onClick={this.handleCancelClick} size="small" variant="contained">
            <FormattedSimpleMessage {...messages.cancelButton} />
          </Button>
        </div>
      </div>
    );
  }
}

TranslateBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default withStyles(styles)(TranslateBanner);
