import { filter, includes, kebabCase, omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import facilitiesResource from '@lib/resources/facilitiesResource';

import { EntityID } from '../../../resources/types';
import FilterList, { FilterListProps } from '../FilterList';
import { resultKey, selectFacilitiesFromPage } from './selectors';

interface Props extends FilterListProps {
  itemsIds?: EntityID[];
}

const FilterListFacilities: React.FC<Props> = ({ itemsIds, idsExcluded, limit, ...other }) => {
  const facilities = useSelector(facilitiesResource.selectEntitiesObj);
  const dispatch = useDispatch();

  const shouldUseItemsFromProp = !!itemsIds;
  const itemsFromProp = filter(facilities, ({ id }) => includes(itemsIds, id));
  const [searchTerm, setSearchTerm] = useState();
  const filteredItemsFromProp = searchTerm
    ? filter(itemsFromProp, (item) => includes(kebabCase(item.name), kebabCase(searchTerm)))
    : itemsFromProp;

  const itemsFromPageRequest = useSelector(selectFacilitiesFromPage);

  useEffect(() => {
    dispatch(
      facilitiesResource.fetchBulk({
        ids: itemsIds || idsExcluded,
      }),
    );
  }, [dispatch, itemsIds, idsExcluded]);

  return (
    <FilterList
      itemsLabel="facilities"
      allItems={facilities}
      items={shouldUseItemsFromProp ? filteredItemsFromProp.slice(0, limit) : itemsFromPageRequest}
      idsExcluded={idsExcluded}
      onSearchChange={(page, limit, name) => {
        if (shouldUseItemsFromProp) {
          setSearchTerm(name);
        } else {
          dispatch(
            facilitiesResource.fetchPage({
              params: { page, limit, name },
              meta: {
                resultKey,
              },
            }),
          );
        }
      }}
      limit={limit}
      {...omit(other, 'allItems', 'items')}
    />
  );
};

export default FilterListFacilities;
