import { defaultsDeep, map } from 'lodash';

import { getDefaults } from '@lib/services/patientFormTemplatesService';

import createResource from './createResource';
import { PatientFormTemplate } from './types';

const patientFormTemplatesResource = createResource<PatientFormTemplate>({
  key: 'patientFormTemplates',
  url: '/patientFormTemplates',
  processStrategy: (data) => {
    const res = defaultsDeep({}, data, getDefaults()) as PatientFormTemplate;
    return {
      ...res,
      template: res.template && {
        ...res.template,
        items: map(res.template.items, (item) => ({
          ...item,
          // WORKAROUND: v0.6.0: item.squiggly
          squiggly: item.squiggly || item.id,
        })),
      },
      // extended
      $isIntake: res.type === 'intake',
      $isPreQualification: res.type === 'pre-qualification',
    };
  },
  removeMessageSuccess: 'Custom form deleted!',
  removeMessageError: 'Error deleting custom form',
  saveMessageSuccess: 'Custom form saved!',
  saveMessageError: 'Error saving custom form',
});

export default patientFormTemplatesResource;
