import resource, { getSchema } from './resource';

import api from '../utils/axiosClient';

const url = '/customWebStyles';
export const entitiesKey = 'customWebStyles';

// Schema
export const customWebStylesSchema = getSchema(entitiesKey);

// Methods
function fetch(args) {
  return resource.fetch({ url, schema: customWebStylesSchema, ...args });
}

function save(args) {
  return resource.save({ url, schema: customWebStylesSchema, ...args });
}

function del(args) {
  return resource.del({ url, entitiesKey, ...args });
}

function publish({ id }) {
  return api({
    method: 'put',
    url: `${url}/${id}/publish`,
    schema: customWebStylesSchema,
  });
}

export default {
  entitiesKey,
  fetch,
  save,
  del,
  publish,
};
