import { createSelector } from 'reselect';

import selectAuth from './selectAuth';

const selectLocale = createSelector(
  selectAuth,
  (auth) => auth.locale,
);

export default selectLocale;
