import api from '@lib/utils/axiosClient';
import { getSchema } from './resource';

const url = '/users';
const entitiesKey = 'usersList';

// Schema
const usersListSchema = getSchema(entitiesKey);

// Methods
async function fetch(args) {
  const response = await api.get(url, {
    schema: [usersListSchema],
    ...args,
  });
  return response;
}

async function fetchPage(args) {
  const response = await api.get(url, {
    schema: [usersListSchema],
    ...args,
  });
  return response;
}

export default {
  entitiesKey,
  fetch,
  fetchPage,
};
