import appSagas from 'containers/App/sagas';
import { History } from 'history';
import { fromJS } from 'immutable';
import { routerMiddleware } from 'react-router-redux';
import createReducer from 'reducers/createReducer';
import { combineReducers } from 'redux-immutable';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'sagas/rootSaga';

import StoreInstance, { StoreWithExtensions } from '@lib/utils/StoreInstance';

const sagaMiddleware = createSagaMiddleware();

function configureStore(history: History): StoreWithExtensions {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  const initialState = fromJS({});
  const reducers = createReducer();
  StoreInstance.combineReducers = combineReducers;
  const store = StoreInstance.configureStore(middlewares, initialState, reducers);

  // Make reducers hot reloadable
  if ((module as any).hot) {
    (module as any).hot.accept('./reducers/createReducer', () => {
      store.replaceReducer(StoreInstance.createReducer(reducers, store.injectedReducers));
    });
  }

  // Run sagas
  store.runSaga = sagaMiddleware.run;
  store.runSaga(rootSaga);

  // TODO: get rid of other sagas than `rootSaga`
  store.runSaga(appSagas);

  return store;
}

export default configureStore;
