import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = (theme) => ({
  panelRoot: {
    position: 'inherit',
  },
  panelExpanded: {
    margin: 0,
  },
  panelSummaryRoot: {
    minHeight: '0 !important',
    padding: `0 ${theme.spacing.unit * 2}px`,
  },
  panelSummaryContent: {
    margin: `${theme.spacing.unit}px 0 !important`,
  },
  panelSummaryExpandIcon: {
    right: theme.spacing.unit / 2,
    color: theme.palette.primary.main,
  },
  title: {
    fontWeight: 600,
  },
  panelDetails: {
    padding: `${theme.spacing.unit}px 0`,
  },
});

class FilterExpansionPanel extends PureComponent {
  render() {
    const { classes, title, children, ...other } = this.props;
    return (
      <React.Fragment>
        <ExpansionPanel
          classes={{
            root: classes.panelRoot,
            expanded: classes.panelExpanded,
          }}
          elevation={0}
          {...other}
        >
          <ExpansionPanelSummary
            classes={{
              root: classes.panelSummaryRoot,
              content: classes.panelSummaryContent,
              expandIcon: classes.panelSummaryExpandIcon,
            }}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography className={classes.title} variant="subtitle1">
              {title}
            </Typography>
          </ExpansionPanelSummary>
          <Divider />
          <ExpansionPanelDetails className={classes.panelDetails}>{children}</ExpansionPanelDetails>
        </ExpansionPanel>
        <Divider />
      </React.Fragment>
    );
  }
}

FilterExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(FilterExpansionPanel);
