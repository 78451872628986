/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `src/i18n`)
 */

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { IntlProvider } from 'react-intl';

import { selectLocale } from '@lib/selectors';

export class LanguageProvider extends React.PureComponent {
  render() {
    const { locale, messages, children } = this.props;
    return (
      <IntlProvider locale={locale} key={locale} messages={messages[locale]}>
        {React.Children.only(children)}
      </IntlProvider>
    );
  }
}

LanguageProvider.propTypes = {
  locale: PropTypes.string.isRequired,
  messages: PropTypes.object,
  children: PropTypes.element.isRequired,
};

const withConnect = connect(
  createStructuredSelector({
    locale: selectLocale,
  }),
);

export default compose(withConnect)(LanguageProvider);
