function getConfigString(key: string) {
  const value = process.env[key];
  return value && `${value}`;
}

function getConfigNumber(key: string) {
  const value = getConfigString(key);
  return value && parseInt(value, 10);
}

function getAppPrefix() {
  return getConfigString('REACT_APP_PREFIX');
}

function getApiBasePath() {
  return getConfigString('REACT_APP_API_BASE');
}

function getBrand() {
  return getConfigString('REACT_APP_BRAND') as 'luma' | 'ucsf';
}

function getSitePatient() {
  return getConfigString('REACT_APP_SITE_PATIENT');
}

function getGtmId() {
  return getConfigString('REACT_APP_GTM_ID');
}

function getGtmIdAdditional() {
  return getConfigString('REACT_APP_GTM_ID_ADDITIONAL');
}

function getCommitHash() {
  return getConfigString('REACT_APP_COMMIT_HASH');
}

function getKeyGooglMaps() {
  return getConfigString('REACT_APP_GOOGL_KEY_MAPS');
}

function getKeyRecaptcha() {
  return getConfigString('REACT_APP_RECAPTCHA_ID');
}

function getSessionExpireMinutes() {
  return getConfigNumber('REACT_APP_SESSION_EXPIRE_MINUTES') as number;
}

function isPatientApp() {
  return getAppPrefix() === 'lh_patient';
}

export default {
  getAppPrefix,
  getApiBasePath,
  getBrand,
  getSitePatient,
  getGtmId,
  getGtmIdAdditional,
  getCommitHash,
  getKeyGooglMaps,
  getKeyRecaptcha,
  getSessionExpireMinutes,
  isPatientApp,
};
