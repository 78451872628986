import { call } from 'redux-saga/effects';
import WebFont from 'webfontloader';

import { FETCH_FONT_FAMILIES } from '../actions/actionTypes';

import sagaFetch from './sagaFetch';

// bind resource generator
const fetchFontFamilies = sagaFetch.bind(null, FETCH_FONT_FAMILIES, WebFont.load);

export default function* sagaFetchFontFamilies(action) {
  const { families } = action;
  yield call(fetchFontFamilies, {
    google: {
      families,
    },
  });
}
